import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ContentContainer from "../../components/contentContainer";
import Input from "../../components/input";
import Tooltip from "../../components/tooltip";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useOpenAIResponse,
  useUpdateWebsiteFields,
} from "../../services/web-details/openaiResponse";
import RoundedButton from "../../components/roundedButton";

export interface IOpenAIFormData {
  id: string;
  value: string;
  websiteFieldName: string;
  websiteFieldValue: string;
}

const EditWebsite = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [requestData, setRequestData] = useState<
    | {
        id: number;
        value: string;
        websiteFieldName: string;
        websiteFieldValue: string;
      }[]
    | undefined
  >([]);

  const { data: openAIResponseData } = useOpenAIResponse(id);

  const {
    mutateAsync: updateWebsiteFields,
    isError: isUpdateFieldsError,
    isPending: isUpdateFieldsPending,
    isSuccess: isUpdateFieldsSuccess,
  } = useUpdateWebsiteFields(id);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.preventDefault();

    const reqData = requestData?.map((data) => {
      return { id: data.id, value: data.value };
    });

    if (reqData) {
      await updateWebsiteFields(reqData);
    }
  };

  const onChange = (id: number, newValue: string) => {
    const updatedData = requestData?.map((item) => {
      if (id === item.id) {
        return { ...item, value: newValue };
      }
      return item;
    });

    setRequestData(updatedData);
  };

  useEffect(() => {
    const newData = openAIResponseData?.map((data) => ({
      id: data.id,
      value: data.value,
      websiteFieldName: data.websiteFieldName,
      websiteFieldValue: data.websiteFieldValue,
    }));

    setRequestData(newData);
  }, [openAIResponseData]);

  useEffect(() => {
    if (isUpdateFieldsSuccess) {
      toast.success("Website fields saved successfully!");
    } else if (isUpdateFieldsError) {
      toast.error("Something went wrong!");
    }
  }, [isUpdateFieldsSuccess, isUpdateFieldsError]);

  return (
    <ContentContainer className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <div className=" flex flex-1 flex-col p-8">
        <div className=" flex flex-1 flex-col space-y-5 justify-between  items-center sm:items-stretch">
          <div className="flex items-center">
            <h1 className=" text-xl font-ManropeBold text-[#0e086a]">
              Edit Website Details
            </h1>
            <Tooltip bodyText="The details you share here help shape your campaign’s settings, ad copy, and creative design. The more you tell us, the better our system can work to help you reach your advertising goals." />
          </div>
          <form autoComplete="off" onSubmit={(e) => onSubmit(e)}>
            <div className="grid flex-1 xs:gap-x-0 sm:gap-x-3 md:gap-x-6 xs:grid-cols-1 sm:grid-cols-1  gap-y-4">
              {requestData?.map((data) => {
                if (data.websiteFieldName === "Overview") {
                  return (
                    <div key={data.id} className="flex flex-col order-1">
                      <label className="font-ManropeSemiBold pl-2 text-base text-[#434343]">
                        Overview
                        {/* <span className="required-field ml-1"></span> */}
                      </label>
                      <textarea
                        value={data.value}
                        onChange={(e) => onChange(data.id, e.target.value)}
                        className=" outline-blue-200 font-ManropeRegular xs:w-[18.75rem] sm:w-[28.125rem] text-sm text-[#434343]   h-[12rem] hide-scrollbar resize-none border rounded py-1 px-3 "
                      />
                    </div>
                  );
                }
                return (
                  <Input
                    key={data.id}
                    label={data.websiteFieldName}
                    name={data.websiteFieldValue}
                    value={data.value}
                    labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                    className="outline-blue-200  font-ManropeRegular text-sm text-[#434343]"
                    onChange={(e) => onChange(data.id, e.target.value)}
                    isRequiredField={true}
                    type="text"
                  />
                );
              })}
            </div>
            <div className="flex mt-10 ">
              <RoundedButton
                text={"Cancel"}
                onClick={() => navigate(-1)}
                bgColor="bg-gray-400"
                type="button"
                className=" mr-auto cursor-pointer w-32 h-10"
              />
              <RoundedButton
                text={isUpdateFieldsPending ? "Saving..." : "Save"}
                type="submit"
                className=" cursor-pointer w-32 h-10"
              />
            </div>
          </form>
          {isUpdateFieldsError && (
            <p className="mt-5 pl-2 font-ManropeRegular text-base text-red-500">
              Failed to update fields.Please try again!
            </p>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default EditWebsite;
