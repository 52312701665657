import { createContext, useContext, ReactNode, FC } from "react";

import { IWebDetailsRequest, useAddLink } from "../../services/web-details";

import { UseMutateAsyncFunction } from "@tanstack/react-query";

interface AddSiteContextType {
  addNewSite: UseMutateAsyncFunction<any, Error, IWebDetailsRequest, unknown>;
  isWebsiteError: boolean;
  isWebsitePending: boolean;
  isWebsiteSuccess: boolean;
  status: "error" | "idle" | "pending" | "success";
}

const AddSiteContext = createContext<AddSiteContextType | undefined>(undefined);

interface AddSiteProviderProps {
  children: ReactNode;
  selectedAdvertiser: any;
}

export const AddSiteProvider: FC<AddSiteProviderProps> = ({
  children,
  selectedAdvertiser,
}) => {
  const {
    mutateAsync: addNewSite,
    isError: isWebsiteError,
    isPending: isWebsitePending,
    isSuccess: isWebsiteSuccess,
    status,
  } = useAddLink(selectedAdvertiser);

  return (
    <AddSiteContext.Provider
      value={{
        addNewSite,
        isWebsiteError,
        isWebsitePending,
        isWebsiteSuccess,
        status,
      }}
    >
      {children}
    </AddSiteContext.Provider>
  );
};

export const useAddSite = (): AddSiteContextType => {
  const context = useContext(AddSiteContext);
  if (!context) {
    throw new Error("useAddSite must be used within an AddSiteProvider");
  }
  return context;
};
