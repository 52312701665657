import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

/** GET All Industries */
const getAllIndustries = () => {
  return request({
    url: `industry/`,
    method: "get",
  });
};

export const useIndustries = () => {
  return useQuery({
    queryKey: ["industries"],
    queryFn: getAllIndustries,
  });
};

/** GET Advertiser industry info */
const getUserIndustry = (industryId: string | undefined) => {
  if (industryId) {
    return request({
      url: `industry/${industryId}`,
      method: "get",
    });
  }
  return;
};
export const useUserIndustry = (
  industryId: string | undefined,
  userId: number | undefined
) => {
  return useQuery({
    queryKey: ["userIndustry", userId],
    queryFn: () => getUserIndustry(industryId),
    enabled: !!industryId || !!userId,
  });
};

/** GET All industry fields linked to advertiser industry */
const getAllIndustryFields = () => {
  return request({
    url: `user-additional-information/fields`,
    method: "get",
  });
};
export const useIndustryFields = () => {
  return useQuery({
    queryKey: ["industryFields"],
    queryFn: getAllIndustryFields,
  });
};
