import React, { useEffect, useState } from "react";

import { AllocationItem } from "./allocationItem";
import { SectionItem } from "./sectionItem";

import EditSectionButton from "../../../../../components/editSectionButton";
import PieChart from "../../../../../components/pieChart";
import Button from "../../../../../components/button";

import {
  ICreateBudgetResponse,
  useUpdateCampaignBudget,
} from "../../../../../services/wizard/budget";

const BudgetAllocation = ({
  campaignId,
  campaignBudget,
}: {
  campaignId: number | undefined;
  campaignBudget: ICreateBudgetResponse;
}) => {
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);
  const [budgetFields, setBudgetFields] = useState({
    ...campaignBudget,
    searchAllocation:
      (campaignBudget.searchAllocation / campaignBudget.budget) * 100,
    socialAllocation:
      (campaignBudget.socialAllocation / campaignBudget.budget) * 100,
    displayAllocation:
      (campaignBudget.displayAllocation / campaignBudget.budget) * 100,
  });
  const [errorBudgetMessage, setErrorBudgetMessage] = useState<string>("");

  const {
    mutateAsync: updateBudget,
    isPending: isBudgetUpdating,
    // isSuccess:isBudgetUpdateSuccess
  } = useUpdateCampaignBudget(campaignId);

  const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBudgetFields((prev) => ({
      ...prev,
      [e.target.name]: parseInt(e.target.value),
    }));
  };

  const handleAllocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newPercentage = Math.min(Math.max(0, parseFloat(value)), 100);

    setBudgetFields((prev) => ({
      ...prev,
      [name]: isNaN(newPercentage) ? 0 : newPercentage,
    }));
  };

  const handleResetBudget = () => {
    setBudgetFields({
      ...campaignBudget,
      searchAllocation:
        (campaignBudget.searchAllocation / campaignBudget.budget) * 100,
      socialAllocation:
        (campaignBudget.socialAllocation / campaignBudget.budget) * 100,
      displayAllocation:
        (campaignBudget.displayAllocation / campaignBudget.budget) * 100,
    });
  };

  const handleSave = async () => {
    const budget = budgetFields.budget;
    const searchAllocation = (budgetFields.searchAllocation / 100) * budget;
    const socialAllocation = (budgetFields.socialAllocation / 100) * budget;
    const displayAllocation = (budgetFields.displayAllocation / 100) * budget;

    if (budget !== displayAllocation + socialAllocation + searchAllocation) {
      setErrorBudgetMessage("Allocation total percentages must equal 100.");
      return;
    }

    setErrorBudgetMessage("");

    const requestData = {
      id: budgetFields.id,
      budget,
      goal: budgetFields.goal,
      searchAllocation,
      socialAllocation,
      displayAllocation,
    };

    await updateBudget(requestData);
    setIsEditEnabled(false);
  };

  useEffect(() => {
    if (!isEditEnabled) {
      setErrorBudgetMessage("");
    }
  }, [isEditEnabled, errorBudgetMessage]);

  return (
    <div className="flex flex-col bg-white rounded-lg px-6  pt-4 pb-10 ">
      <div className="flex self-end h-12 z-10">
        <EditSectionButton
          isHidden={isEditEnabled}
          onClick={() => setIsEditEnabled(true)}
        />
      </div>
      <div className=" flex">
        <div className="flex flex-col -mt-2">
          <h2 className="text-xl pb-3 border-b border-solid -mt-1 font-bold text-[#0e086a] mb-5 ml-1.5">
            Campaign Plan
          </h2>
          <SectionItem
            isEditEnabled={isEditEnabled}
            inputName="budget"
            title="Budget"
            tooltipBodyText="The budget we suggest here is tailored to your industry, goals, and business type. For small and local businesses, we recommend a Google Ads budget of $1,500 to $8,000 per month."
            type="number"
            value={budgetFields.budget}
            onChange={handleBudgetChange}
          />
          <SectionItem
            isEditEnabled={isEditEnabled}
            inputName="goal"
            type="text"
            title="Goals"
            tooltipBodyText="The default goal here is all about bringing more potential customers to your website. More traffic means more opportunities to showcase what you offer, connect with people, and turn visitors into loyal customers. For small businesses, where every interaction counts, focusing on traffic helps you reach more people and set the stage for future growth."
            value={budgetFields.goal}
            onChange={() => {}}
          />
          {/* allocation  */}
          <div className="flex border-t border-gray-200 mt-6 pt-2">
            <div className="flex flex-col">
              <AllocationItem
                budget={budgetFields.budget}
                isEditEnabled={isEditEnabled}
                inputName="searchAllocation"
                title="Allocation"
                allocation={budgetFields.searchAllocation}
                label="Search (Google)"
                onChange={handleAllocation}
                toolTipBodyText="Our suggested allocation is tailored to your industry and budget, drawing from our experience with similar campaigns. It’s designed to maximize impact, ensuring your budget is spent wisely to reach the right audience at the right time. We aim to balance quick wins with long-term growth, making sure every dollar works hard to drive results across all channels."
              />
              <AllocationItem
                budget={budgetFields.budget}
                isEditEnabled={isEditEnabled}
                inputName="socialAllocation"
                title=""
                allocation={budgetFields.socialAllocation}
                label="Social (Facebook/Instagram)"
                onChange={handleAllocation}
              />
              <AllocationItem
                budget={budgetFields.budget}
                isEditEnabled={isEditEnabled}
                inputName="displayAllocation"
                title=""
                allocation={budgetFields.displayAllocation}
                label="Display"
                onChange={handleAllocation}
              />
            </div>
            {/* pie chart */}
            <div className="ml-12 -mb-5  self-end">
              <PieChart
                data={[
                  {
                    value: budgetFields.displayAllocation,
                    color: "#5e88b6",
                    title: "Display",
                  },
                  {
                    value: budgetFields.socialAllocation,
                    color: "#ff914d",
                    title: "Social",
                  },
                  {
                    value: budgetFields.searchAllocation,
                    color: "#5ce1e6",
                    title: "Search",
                  },
                ].map((d, index) => ({
                  key: `pie-${index}`,
                  value: d.value,
                  color: d.color,
                  title: d.title,
                }))}
              />
            </div>
          </div>
        </div>
      </div>

      {isEditEnabled && (
        <div className="flex mt-14 items-center">
          <div className="flex mr-auto">
            {errorBudgetMessage && (
              <div className="">
                <p className="font-ManropeSemiBold text-sm text-red-400">
                  {errorBudgetMessage}
                </p>
              </div>
            )}
          </div>
          <div className="flex ">
            <Button
              onClick={() => {
                handleResetBudget();
                setIsEditEnabled(false);
              }}
              isScaledOnHover={true}
              btnText="Cancel"
              fontFamily="font-ManropeBold"
              fontSize="text-sm"
              bgColor="bg-black"
              marginValues="mr-2"
            />
            <Button
              onClick={handleSave}
              isScaledOnHover={true}
              btnText={isBudgetUpdating ? "Processing..." : "Save"}
              fontFamily="font-ManropeBold"
              fontSize="text-sm"
              bgColor="bg-black"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BudgetAllocation;
