import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { isValidPhoneNumber } from "libphonenumber-js";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAuth } from "../../contexts/auth";

import { CountryCodePicker } from "../../components/countryCodePicker";
import RoundedButton from "../../components/roundedButton";
import Input from "../../components/input/floatinLabelInput";

import KolayAdsIcon from "../../assets/icons/veloxi-navbar-logo.png";
import WarningIcon from "../../assets/icons/icon_alert-warning.png";

import { SignUpFormData, TCountry } from "../../interfaces";
import { PasswordStrengthItem } from "../../containers/profile/changePassword";
import { evaluatePasswordStrength } from "../../utils/passwordStrength";

export const SignUpPage: React.FC = () => {
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const [selectedCountry, setSelectedCountry] = useState<TCountry>({
    label: "United States",
    flag: "US",
    value: 1,
  });
  const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState<
    string | undefined
  >();

  const {
    register,
    handleSubmit,
    setError,
    // setValue,
    formState: { errors },
    watch,
  } = useForm<SignUpFormData>({
    defaultValues: {
      displayName: "",
      password: "",
      phoneNumber: "",
      email: "",
    },
  });

  const formData: SignUpFormData = watch();

  const isSubmitDisabled =
    !formData.displayName ||
    !formData.password ||
    !formData.phoneNumber ||
    !formData.email;

  const {
    signUp,
    isError: isSignUpError,
    errorMessage: signUperrorMessage,
    isLoading: isSignUpProcessing,
  } = useAuth();

  const onSubmit: SubmitHandler<SignUpFormData> = async (data) => {
    if (isSubmitDisabled) {
      return;
    }

    let { phoneNumber, ...rest } = data;

    const phoneNumberWithCode = `+${selectedCountry.value}${phoneNumber}`;

    if (!isValidPhoneNumber(phoneNumberWithCode)) {
      setPhoneNumberValidMessage(
        "Please check to see if you have entered a valid phone number."
      );

      return;
    }

    if (Object.values(passwordStrength).includes(false)) {
      setError("password", { message: "Password does not meet requirements!" });
      return;
    }

    await signUp({
      phoneNumber: phoneNumberWithCode,
      ...rest,
    });
  };

  const handleCountryCodeChange = (country: TCountry) => {
    setSelectedCountry(country);
  };

  const passwordStrength = useMemo(
    () => evaluatePasswordStrength(formData.password),
    [formData.password]
  );

  useEffect(() => {
    if (phoneNumberValidMessage) {
      setTimeout(() => {
        setPhoneNumberValidMessage(undefined);
      }, 3000);
    }
    //eslint-disable-next-line
  }, [signUperrorMessage, phoneNumberValidMessage]);

  return (
    <div className="flex mt-14 justify-center">
      <div className="border relative border-solid border-gray-200 rounded-lg  pb-4 my-10 w-[30.5rem]">
        <div className="px-5 pt-3">
          <div className="w-full h-[4.5rem]   mb-6">
            <img
              src={KolayAdsIcon}
              className="w-full h-full object-contain "
              alt="kolay-ads.png"
            />
          </div>

          <h3 className=" mt-2 mb-6 font-interSemiBold text-3xl text-[#0e086a] text-center  ">
            Sign Up
          </h3>

          {isSignUpError && (
            <div className="flex bg-[#fef7f7] border border-red-600 px-3 py-4 rounded-md">
              <div className="w-5 flex-shrink-0 h-5 mr-5">
                <img
                  className="w-full h-full"
                  src={WarningIcon}
                  alt="warning-text"
                />
              </div>

              <p className="font-ManropeSemiBold text-sm text-black">
                {signUperrorMessage}
              </p>
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-x-3 gap-y-2"
          >
            <Input
              id="displayName"
              name="displayName"
              label="Display Name"
              reactHooksFormEnabled={true}
              register={register}
              value={formData["displayName"]}
              focusedField={focusedField}
              setFocusedField={setFocusedField}
            />

            <Input
              id="email"
              name="email"
              label="Email"
              reactHooksFormEnabled={true}
              register={register}
              requiredMessage="Email is required"
              value={formData["email"]}
              focusedField={focusedField}
              setFocusedField={setFocusedField}
            />
            <Input
              id="password"
              name="password"
              type="password"
              label="Password"
              reactHooksFormEnabled={true}
              register={register}
              value={formData["password"]}
              errorMessage={errors?.password?.message}
              focusedField={focusedField}
              setFocusedField={setFocusedField}
            />

            <div className="flex flex-row space-x-4 mb-6">
              <div className="flex flex-col">
                <PasswordStrengthItem
                  fontSize="11px"
                  isChecked={passwordStrength.lowercase}
                  message="One lowercase character"
                />
                <PasswordStrengthItem
                  isChecked={passwordStrength.uppercase}
                  message="One uppercase character"
                />
                <PasswordStrengthItem
                  isChecked={passwordStrength.number}
                  message="One number"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <PasswordStrengthItem
                  isChecked={passwordStrength.specialChar}
                  message="One special character"
                />
                <PasswordStrengthItem
                  isChecked={passwordStrength.length}
                  message="Minimum 8 characters"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className=" flex space-x-1">
                <div className="flex pt-5  flex-[0.25]">
                  <CountryCodePicker
                    onChange={handleCountryCodeChange}
                    value={selectedCountry}
                    customSingleValueStyles={{ height: "48px" }}
                    customDropdownMenuStyles={{ width: "250%" }}
                  />
                </div>
                <div className="flex flex-[0.75] ">
                  <Input
                    containerDivClassName="w-full"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    label="Phone Number"
                    reactHooksFormEnabled={true}
                    register={register}
                    requiredMessage="Phone Number is required"
                    value={formData["phoneNumber"]}
                    focusedField={focusedField}
                    setFocusedField={setFocusedField}
                  />
                </div>
              </div>
              {phoneNumberValidMessage && (
                <div className="mt-2 -mb-2">
                  <p className="text-red-400 text-sm">
                    {phoneNumberValidMessage}
                  </p>
                </div>
              )}
            </div>
            <RoundedButton
              type="submit"
              text={(isSignUpProcessing && "Processing...") || "Next"}
              disabled={isSubmitDisabled}
              className="py-3 px-4 mt-5"
            />

            {/* {isSignUpError && (
              <div className="my-2">
                <p className="text-red-400 text-sm">
                  {signUperrorMessage ?? "An error occurred while signing up"}
                </p>
              </div>
            )} */}
          </form>
        </div>

        <div className="absolute -right-24 top-0">
          <Link
            to="/"
            className="text-[#45678b] cursor-pointer font-ManropeBold text-right"
          >
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
