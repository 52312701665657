import Dashboard from "../assets/icons/home-icon.svg";
import Campaign from "../assets/icons/target-icon.svg";
import Insights from "../assets/icons/analytics-icon.svg";
import Websites from "../assets/icons/image-icon.svg";
import Help from "../assets/icons/question-icon.svg";
import Notifications from "../assets/icons/notification-icon.svg";

interface ITopNavLinks {
  id: string;
  icon: string;
  activeIcon: string;
  text: string | null;
  link: string;
  subNav: JSX.Element | null;
}

export const topNavLinks: ITopNavLinks[] = [
  {
    id: "tn-1",
    icon: Dashboard,
    activeIcon: "",
    text: "Home",
    link: "/",
    subNav: null,
  },
  {
    id: "tn-2",
    icon: Campaign,
    activeIcon: "",
    text: "Campaigns",
    link: "/campaigns",
    subNav: null,
  },
  {
    id: "tn-3",
    icon: Websites,
    activeIcon: "",
    text: "Websites",
    link: "/websites",
    subNav: null,
  },
  {
    id: "tn-4",
    icon: Insights,
    activeIcon: "",
    text: "Insights",
    link: "/insights",
    subNav: null,
  },
  {
    id: "tn-5",
    icon: Help,
    activeIcon: "",
    text: null,
    link: "https://veloxiai.freshdesk.com/support/home",
    subNav: null,
  },
  {
    id: "tn-6",
    icon: Notifications,
    activeIcon: "",
    text: null,
    link: "/notifications",
    subNav: null,
  },
];
