interface ContentContainerProps {
  children: React.ReactNode;
  className?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ children, className }) => {
  const style = `bg-white  rounded-lg flex flex-col px-6 py-5 ${className}`;
  return (
    <div className={style}>
      <div className="flex flex-col border-[#F9F3F0] ">{children}</div>
    </div>
  );
};

export default ContentContainer;
