import { createRef, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns";

import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";

import Input from "../../../../components/input";
import { Selection } from "../../../../components/select";
import Tooltip from "../../../../components/tooltip";

import { categorizeFields } from "./categorizeFields";

import CalendarIcon from "../../../../assets/icons/calendar.svg";

import { ICampaignFieldsResponse } from "../../../../services/industry-organization-campaign-fields";

import { CampaignFieldData } from "../../../../interfaces";

import {
  categoryOrder,
  categoryTitle,
  categoryTooltipTexts,
} from "../../../../constants/wizard/campaign";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

const CampaignFieldsRenderer = ({
  multiSelectFieldIds,
  isEditEnabled,
  campaignFields,
  campaignFieldVals,
  setCampaignFieldVals,
  control,
  setValue,
  errors,
  getValues,
}: {
  multiSelectFieldIds: {
    id: number;
    campaignFieldName: string;
    campaignFieldId: number;
  }[];
  isEditEnabled: boolean;
  campaignFieldVals: CampaignFieldData;
  setCampaignFieldVals: React.Dispatch<React.SetStateAction<CampaignFieldData>>;
  campaignFields: ICampaignFieldsResponse[] | undefined;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  errors: Record<string, any>;
  getValues: UseFormGetValues<FieldValues>;
}) => {
  const [datePickerRefs, setDatePickerRefs] = useState<{
    [key: string]: React.RefObject<DatePicker>;
  }>({});

  // console.log("error:", errors);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleFieldChange = (name: string, value: any) => {
    let formattedValue = value;

    const isComponentDateType =
      campaignFields?.find((d) => d.campaignFieldName === name)?.type ===
      "DATE";

    if (isComponentDateType) {
      if (value && !isNaN(Date.parse(value))) {
        const localDate = setHours(
          setMinutes(setSeconds(setMilliseconds(new Date(value), 0), 0), 0),
          0
        );
        formattedValue = localDate.toISOString();
      } else {
        formattedValue = "";
      }
    }

    if (Array.isArray(value)) {
      const item = multiSelectFieldIds.find(
        (item) => item.campaignFieldName === name
      );

      const id = item?.id;
      const campaignFieldId = item?.campaignFieldId;

      if (value.length === 0) {
        formattedValue = [];

        setCampaignFieldVals((prevValues) => ({
          ...prevValues,
          [name]: [
            {
              id,
              campaignFieldId,
              value: [],
              label: "",
            },
          ],
        }));

        setValue(name, [
          {
            id,
            campaignFieldId,
            value: [],
            label: "",
          },
        ]);
        return;
      }

      formattedValue = value.map((item) => ({
        ...item,
        id,
        campaignFieldId,
        label: item.label,
        value: item.value,
      }));
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: [...formattedValue],
      }));
      setValue(name, [...formattedValue]);
      return;
    }

    if (!formattedValue.value && !isComponentDateType) {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          value: "",
          label: "",
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: "",
        label: "",
      });
    } else if (formattedValue?.value && !isComponentDateType) {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          value: formattedValue.value,
          label: formattedValue.label,
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: formattedValue.value,
        label: formattedValue.label,
      });
    } else {
      setCampaignFieldVals((prevValues) => ({
        ...prevValues,
        [name]: {
          ...prevValues[name],
          label: formattedValue,
          value: formattedValue,
        },
      }));
      setValue(name, {
        ...getValues(name),
        value: formattedValue,
        label: formattedValue,
      });
    }
  };

  const categorizedFields =
    campaignFields && categorizeFields([...campaignFields]);

  const renderFields = (
    field: ICampaignFieldsResponse,
    campaignFieldVals: CampaignFieldData,
    control: any
  ) => {
    const handleIconClick = (fieldName: string) => {
      if (datePickerRefs[fieldName]?.current) {
        datePickerRefs[fieldName].current?.setOpen(true);
      }
    };

    const getDatePickerRef = (fieldName: string) => {
      if (!datePickerRefs[fieldName]) {
        datePickerRefs[fieldName] = createRef<DatePicker>();
        setDatePickerRefs({ ...datePickerRefs });
      }
      return datePickerRefs[fieldName];
    };

    return isEditEnabled ? (
      (() => {
        switch (field.type) {
          case "STRING":
            return (
              <>
                <Controller
                  name={field.campaignFieldName}
                  control={control}
                  defaultValue={
                    campaignFieldVals[field.campaignFieldName] || ""
                  }
                  rules={{
                    validate: {
                      required: (f) => {
                        if (field.isRequired && !f.value) {
                          return `${
                            field.campaignFieldName || "This field"
                          } is required`;
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field: cfield }) => (
                    <div className="w-full max-w-[25.4rem] pl-2">
                      <Input
                        {...cfield}
                        value={cfield.value?.value || ""}
                        label={""}
                        className={`${
                          errors[cfield.name]?.message &&
                          "border-red-400 border-1"
                        } "ml-2 font-ManropeRegular"`}
                        type="text"
                        onChange={(e) => {
                          const updatedValue = {
                            ...cfield.value,
                            label: e.target.value,
                            value: e.target.value,
                          };
                          handleFieldChange(cfield.name, updatedValue);
                        }}
                      />

                      {errors[cfield.name]?.message && (
                        <p className="text-red-400 pl-3 pt-1 font-ManropeRegular text-sm">
                          {errors[cfield.name]?.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </>
            );
          case "DROPDOWN":
            const dropdownData =
              field.options?.map((option) => ({
                value: option,
                label: option,
              })) || [];
            return (
              <Controller
                name={field.campaignFieldName}
                control={control}
                defaultValue={campaignFieldVals[field.campaignFieldName]}
                rules={{
                  validate: {
                    required: (f) => {
                      if (field.isRequired && !f.value) {
                        return `${
                          field.campaignFieldName || "This field"
                        } is required`;
                      }
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <div className="w-full max-w-[25rem] pl-2">
                    <Selection
                      isMulti={undefined}
                      data={dropdownData}
                      value={campaignFieldVals[field.name]}
                      onChange={(selectedOption: any) =>
                        handleFieldChange(field.name, selectedOption)
                      }
                      customStyle={{
                        selectContainer: {
                          display: "flex",
                          paddingInline: 10,
                          paddingBlock: 3,
                          marginLeft: 7,
                          borderColor: `${
                            errors[field.name]?.message && "red"
                          }`,
                          width: "100%",
                        },
                        color: "#d3d3d3",
                        optionisSelectedBgColor: "transparent",
                        optionisFocusedBgColor: "#335498",
                        optionisFocusedTextColor: "white",
                        singleValStyles: {
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  </div>
                )}
              />
            );
          case "MULTISELECT":
            const multiDropdownData =
              field.options?.map((option) => ({
                value: option,
                label: option,
              })) || [];

            const multiVals = campaignFieldVals[field.campaignFieldName];
            return (
              <Controller
                name={field.campaignFieldName}
                control={control}
                defaultValue={campaignFieldVals[field.campaignFieldName]}
                rules={{
                  validate: {
                    required: (f) => {
                      if (
                        (field.isRequired && !f.value) ||
                        (field.isRequired && f.value.length === 0) ||
                        (field.isRequired && f.value.length === undefined)
                      ) {
                        return `${
                          field.campaignFieldName || "This field"
                        } is required`;
                      }
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <div className="w-full max-w-[25rem] pl-2">
                    <Selection
                      key={field.name}
                      isMulti={true}
                      data={multiDropdownData}
                      value={multiVals[0].value.length > 0 ? multiVals : null}
                      onChange={(selectedOption: any) =>
                        handleFieldChange(field.name, selectedOption)
                      }
                      customStyle={{
                        selectContainer: {
                          display: "flex",
                          paddingInline: 10,
                          paddingBlock: 3,
                          marginLeft: 7,
                          width: "100%",
                          borderColor: `${
                            errors[field.name]?.message && "red"
                          }`,
                        },
                        color: "#d3d3d3",
                        optionisSelectedBgColor: "transparent",
                        optionisFocusedBgColor: "#335498",
                        optionisFocusedTextColor: "white",
                        singleValStyles: {
                          backgroundColor: "transparent",
                        },
                        multiValStyles: {
                          backgroundColor: "#231e76",
                          color: "white",
                        },
                        multiValLabelStyles: {
                          color: "white",
                        },
                      }}
                    />
                    {errors[field.name]?.message && (
                      <p className="text-red-400 pl-3 pt-1 font-ManropeRegular text-sm">
                        {errors[field.name]?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            );
          case "DATE":
            return field.campaignFieldName === "Campaign Start Date" ? (
              <Controller
                name={field.campaignFieldName}
                control={control}
                defaultValue={campaignFieldVals[field.campaignFieldName]}
                rules={{
                  validate: {
                    required: (f) => {
                      if (field.isRequired && !f.value) {
                        return `${
                          field.campaignFieldName || "This field"
                        } is required`;
                      }
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      key={field.name}
                      id="startDate"
                      ref={getDatePickerRef(field.name)}
                      showIcon
                      icon={
                        <img
                          className="absolute cursor-pointer right-1.5 top-0.5"
                          onClick={() => handleIconClick(field.name)}
                          src={CalendarIcon}
                          alt="calendar"
                        />
                      }
                      selected={
                        campaignFieldVals[field.name].value
                          ? new Date(campaignFieldVals[field.name].value)
                          : null
                      }
                      onChange={(date) => {
                        handleFieldChange(field.name, date);
                      }}
                      dateFormat="yyyy-MM-dd"
                      className={`${
                        errors[field.name]?.message && "border-red-400"
                      } py-2 px-3  w-[25.2rem] border border-solid  font-ManropeRegular  rounded-sm ml-2`}
                      placeholderText=""
                      minDate={today} // Ensure the start date is not before today
                      maxDate={
                        campaignFieldVals["Campaign End Date"]
                          ? new Date(
                              campaignFieldVals["Campaign End Date"].value
                            )
                          : null
                      }
                      // showTimeSelect
                      // dateFormat="Pp"
                    />

                    {errors[field.name]?.message && (
                      <p className="text-red-400 pl-3 pt-1 font-ManropeRegular text-sm">
                        {errors[field.name]?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            ) : field.campaignFieldName === "Campaign End Date" ? (
              <Controller
                name={field.campaignFieldName}
                control={control}
                defaultValue={campaignFieldVals[field.campaignFieldName]}
                rules={{
                  validate: {
                    required: (f) => {
                      if (field.isRequired && !f.value) {
                        return `${field.campaignFieldName} is required`;
                      }
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      key={field.name}
                      showIcon
                      ref={getDatePickerRef(field.name)}
                      icon={
                        <img
                          className="absolute cursor-pointer right-1.5 top-0.5"
                          onClick={() => handleIconClick(field.name)}
                          src={CalendarIcon}
                          alt="calendar"
                        />
                      }
                      id="endDate"
                      selected={
                        campaignFieldVals[field.name].value
                          ? new Date(campaignFieldVals[field.name].value)
                          : null
                      }
                      onChange={(date) => handleFieldChange(field.name, date)}
                      // showTimeSelect

                      className={`${
                        errors[field.name]?.message && "border-red-400"
                      } py-2 px-3  w-[25.2rem] border border-solid  font-ManropeRegular  rounded-sm ml-2`}
                      dateFormat="yyyy-MM-dd"
                      placeholderText=""
                      minDate={
                        campaignFieldVals["Campaign Start Date"]
                          ? new Date(
                              campaignFieldVals["Campaign Start Date"].value
                            )
                          : today
                      } // Ensure the end date is not before the start date or today
                    />
                    {errors[field.name]?.message && (
                      <p className="text-red-400 pl-3 pt-1 font-ManropeRegular text-sm">
                        {errors[field.name]?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            ) : (
              <Controller
                name={field.campaignFieldName}
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      key={field.name}
                      showIcon
                      ref={getDatePickerRef(field.name)}
                      icon={
                        <img
                          className="absolute cursor-pointer right-1.5 top-0.5"
                          onClick={() => handleIconClick(field.name)}
                          src={CalendarIcon}
                          alt="calendar"
                        />
                      }
                      selected={
                        campaignFieldVals[field.name]
                          ? new Date(campaignFieldVals[field.name])
                          : null
                      }
                      onChange={(date) => handleFieldChange(field.name, date)}
                      showTimeSelect
                      className={`${
                        errors[field.name]?.message && "border-red-400"
                      } py-2 px-3  w-[25.2rem] border border-solid  font-ManropeRegular  rounded-sm ml-2`}
                      dateFormat="yyyy-MM-dd"
                      placeholderText=""
                      minDate={today}
                    />
                    {errors[field.name]?.message && (
                      <p className="text-red-400 pl-3 pt-1 font-ManropeRegular text-sm">
                        {errors[field.name]?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            );
          default:
            return null;
        }
      })()
    ) : (
      <div className="ml-4 font-interRegular">
        <p>
          {Array.isArray(campaignFieldVals[field.campaignFieldName])
            ? parseDefaultVal(campaignFieldVals[field.campaignFieldName])
            : parseDefaultVal(
                campaignFieldVals[field.campaignFieldName]?.value
              )}
        </p>
      </div>
    );
  };

  return (
    <>
      {categorizedFields &&
        Object.keys(categorizedFields).map((category) => (
          <div
            key={categoryOrder[category]}
            className="px-12 "
            style={{ order: categoryOrder[category] }}
          >
            <div className="flex items-center w-full pb-3 border-b border-solid mb-5 ml-1.5">
              <h2 className="text-xl font-bold text-[#0e086a] ">
                {categoryTitle[category]}
              </h2>
              <Tooltip bodyText={categoryTooltipTexts[category]} />
            </div>
            <div
              key={category}
              className={`mb-8  last-of-type:mb-0 
            ${
              category === "GEO_TARGETING"
                ? "grid grid-cols-2 gap-x-0"
                : "grid grid-cols-[450px]"
            }
            `}
            >
              {categorizedFields[category]?.map((field) => (
                <div
                  key={field.id}
                  className="flex  flex-col  items-start mb-6"
                >
                  <div>
                    <p className="text-md ml-3 mb-2 font-ManropeSemiBold w-[170px]">
                      {field.campaignFieldName}
                    </p>
                  </div>

                  {renderFields(field, campaignFieldVals, control)}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default CampaignFieldsRenderer;
