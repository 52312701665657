import Tooltip from "../../../../../components/tooltip";

export const AllocationItem = ({
  title,
  allocation,
  label,
  isEditEnabled,
  budget,
  inputName,
  onChange,
  toolTipBodyText,
}: {
  title: string;
  allocation: number;
  label: string;
  isEditEnabled: boolean;
  budget: number;
  inputName: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toolTipBodyText?: string;
}) => (
  <div className="flex items-center mt-4">
    <div className="w-[7.5rem] mr-4 relative flex items-center">
      <h3 className="text-lg font-ManropeBold">{title}</h3>
      {toolTipBodyText && <Tooltip bodyText={toolTipBodyText} />}
    </div>
    {/* <h3 className="mr-6 w-[7rem] text-lg font-ManropeBold">{title}</h3> */}
    <div className=" border-2 border-solid  border-[#F9F3F0] flex items-center rounded-3xl py-3  w-[15rem]">
      {isEditEnabled ? (
        <input
          className="outline-none w-full px-2 rounded-lg"
          type="text"
          name={inputName}
          value={allocation}
          onChange={onChange}
        />
      ) : (
        <p className="text-base pl-5 font-ManropeSemiBold">{allocation}%</p>
      )}
    </div>
    <p className="ml-5 text-md font-ManropeBold">{label}</p>
  </div>
);
