import React, { useState } from "react";

import { UseFormRegister } from "react-hook-form";

import HideIcon from "../../assets/icons/hide.png";
import HiddenIcon from "../../assets/icons/hidden.png";

interface InputWithToggleProps {
  className?: string;
  type: string;
  name: string;
  label?: string;
  value?: any;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  reactHooksFormEnabled?: boolean;
  register?: UseFormRegister<any>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  requiredMessage?: string;
  validation?: any;
  placeholder?: string;
  labelClassName?: string;
  validationClassName?: string;
  isRequiredField?: boolean;
  customWidth?: string;
}

const InputWithToggle: React.FC<InputWithToggleProps> = ({
  className,
  type,
  name,
  label,
  value,
  onChange,
  disabled,
  reactHooksFormEnabled,
  register,
  requiredMessage,
  pattern,
  validation,
  defaultValue,
  placeholder,
  labelClassName,
  validationClassName,
  isRequiredField,
  customWidth,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col ">
      {label && (
        <label
          className={`${
            labelClassName ? labelClassName : "text-[#6B7276]"
          } mb-1 pl-2 `}
        >
          {label}
          {isRequiredField && <span className="required-field ml-1"></span>}
        </label>
      )}
      <div className="relative xs:w-[18.75rem] sm:w-[28.125rem] ">
        {reactHooksFormEnabled && register ? (
          <input
            type={
              type === "password"
                ? isPasswordVisible
                  ? "text"
                  : "password"
                : type
            }
            className={`${className} border-[1px] rounded py-1 px-3 ${
              customWidth ? customWidth : "xs:w-[18.75rem] sm:w-[28.125rem]"
            }`}
            {...register(name, { required: requiredMessage, pattern: pattern })}
            disabled={disabled}
          />
        ) : (
          <input
            type={
              type === "password"
                ? isPasswordVisible
                  ? "text"
                  : "password"
                : type
            }
            name={name}
            placeholder={placeholder}
            className={`${className} border-[1px] rounded py-1 px-3 w-full `}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {type === "password" && (
          <img
            src={isPasswordVisible ? HideIcon : HiddenIcon}
            alt="password visibility"
            className="absolute right-2 top-1.5 cursor-pointer h-4 w-4 "
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
      {validation && (
        <p
          className={`${
            validationClassName ? validationClassName : "mt-3 text-sm"
          }  font-ManropeBold text-[#DD0612]`}
        >
          {validation}
        </p>
      )}
    </div>
  );
};

export default InputWithToggle;
