export const allowedExtensions = [
  "jpeg",
  "svg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "webp",
  "avif",
];
