import React from "react";
import Input from "../input";
import { UseFormRegister } from "react-hook-form";

const SearchBar = ({
  label,
  urlBarValue,
  setUrlBarValue,
  urlErrMesg,
  register,
  reactHooksFormEnabled,
  requiredMessage,
  pattern,
  name,
}: {
  urlBarValue?: string;
  setUrlBarValue?: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  urlErrMesg: string | undefined;
  register?: UseFormRegister<{
    shortName: string;
    urlBarValue: string;
  }>;
  reactHooksFormEnabled?: boolean;
  requiredMessage?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  name: string;
}) => {
  return (
    <>
      <Input
        type="text"
        labelClassName="font-ManropeSemiBold text-base text-[#434343]"
        className="outline-blue-200  font-ManropeRegular text-sm text-[#434343] mb-4"
        label={label}
        register={register}
        reactHooksFormEnabled={true}
        validation={urlErrMesg}
        requiredMessage={requiredMessage}
        pattern={pattern}
        name={name}
        placeholder="https://..."
        // value={urlBarValue}
        // className="bg-[#FAFAF5] text-base   border-2 border-solid focus:border-b-[#E6E6E6] active:border-b-[#E6E6E6] outline-none  w-[51.313rem] h-16 my-10  pl-4  rounded-3xl"
        validationClassName="text-red-400 -mt-3 text-sm ml-2"
      />
    </>
  );
};

export default SearchBar;
