import Select, { StylesConfig } from "react-select";
import { CustomStyleProps, ILabel } from "../../interfaces";

const customStyles = (
  customStyle: CustomStyleProps
): StylesConfig<any, true> => ({
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    ...customStyle.selectContainer,
    outline: "none",
    boxShadow: "none",
    fontFamily: "ManropeRegular",
  }),
  valueContainer: (base) => ({
    ...base,
    ...customStyle.valueContainer,
  }),
  option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    fontFamily: "ManropeRegular",
    padding: 10,
    backgroundColor: isSelected
      ? customStyle.optionisSelectedBgColor
      : isFocused
      ? customStyle.optionisFocusedBgColor
      : undefined,
    color: isSelected
      ? customStyle.color
      : "black"
      ? isFocused
        ? customStyle.optionisFocusedTextColor
        : "black"
      : "",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    ...customStyle.multiValLabelStyles,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    ...customStyle.multiValStyles,
  }),
  singleValue: (styles) => ({
    display: "flex",
    alignItems: "center",
    ...styles,
    ...customStyle.singleValStyles,
  }),
});

const formatOptionLabel = (data: ILabel) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {data.icon && (
      <img
        src={data.icon}
        alt={data.label}
        style={{ marginRight: 10, width: 20, height: 20 }}
      />
    )}

    {data.val ?? data.label}
  </div>
);

export const Selection = ({
  data,
  isMulti,
  placeholder,
  customStyle,
  enableDefaultValue,
  defaultSelectedValues,
  onChange,
  closeMenuOnSelect,
  isClearableEnabled,
  value,
  isPending,
}: {
  data: any;
  isMulti: true | undefined;
  placeholder?: string;
  customStyle: CustomStyleProps;
  enableDefaultValue?: boolean;
  defaultSelectedValues?: any[];
  onChange?: (selectedOption: any, actionMeta: any) => void;
  closeMenuOnSelect?: boolean | undefined;
  isClearableEnabled?: boolean | undefined;
  value?: any;
  isPending?: boolean;
}) => (
  <Select
    closeMenuOnSelect={closeMenuOnSelect}
    options={
      isPending
        ? [{ value: "loading", label: "Loading...", isDisabled: true }]
        : data
    }
    onChange={onChange}
    isMulti={isMulti}
    isClearable={isClearableEnabled}
    placeholder={placeholder ?? ""}
    value={value}
    defaultValue={
      isMulti && enableDefaultValue
        ? defaultSelectedValues
        : enableDefaultValue && data[0]
    }
    styles={customStyles({ ...customStyle })}
    formatOptionLabel={formatOptionLabel}
  />
);
