import React from "react";

const ActivityIndicator: React.FC = () => {
  return (
    <div className="fixed inset-0 z-30 bg-white/30 backdrop-blur-sm flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

export default ActivityIndicator;
