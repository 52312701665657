import Advertisers from "../../containers/advertisers";
import MainLayout from "../../layouts/main";

const Index = () => {
  return (
    <MainLayout showSidebar>
      <Advertisers />
    </MainLayout>
  );
};

export default Index;
