import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AuthProvider } from "./contexts/auth";
import { SelectedAdvertiserProvider } from "./contexts/selectedAdvertiser";
import { RootNavigator } from "./navigation";

import "./App.css";

export const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SelectedAdvertiserProvider>
        <AuthProvider>
          <RootNavigator />
        </AuthProvider>
      </SelectedAdvertiserProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
