import React from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";

import Adalee from "../../../../components/adaleeAsistant";
import { Selection as Select } from "../../../../components/select";
import RoundedButton from "../../../../components/roundedButton";
import Tooltip from "../../../../components/tooltip";

import {
  IWebDetailsResponse,
  useAdvertiserSites,
} from "../../../../services/web-details";

const Website = ({
  selectedListing,
  selectedSite,
  isSiteLoading,
  setSelectedListing,
  setAdvertiseFlowId,
}: {
  selectedListing: any;
  selectedSite: IWebDetailsResponse | undefined;
  isSiteLoading: boolean;
  setSelectedListing: React.Dispatch<React.SetStateAction<any>>;
  setAdvertiseFlowId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const navigate = useNavigate();

  const { selectedAdvertiser } = useSelectedAdvertiser();

  const { data: usersAllSites, isPending: isLoadingSites } =
    useAdvertiserSites(selectedAdvertiser);

  const selectionData = usersAllSites?.map((item: any) => ({
    id: item.id,
    value: item.shortName,
    label: item.shortName,
  }));

  return (
    <div>
      <div className="flex flex-col ">
        <Adalee
          isSeperatorVisible={false}
          bodyText={
            selectedSite && !selectedSite.isFieldsFilled
              ? `
            Some fields in the selected website's info are
            missing. It might be a good idea to double-check and
            update that to continue on your campaign steps.
            `
              : selectedSite && !selectedSite.isEditable
              ? "Bear with me, I am still processing your newly added site"
              : selectedSite && selectedSite.numberOfAssets === 0
              ? `I could not find any assets on the selected website.
                  Once you add at least one, you are good to go.
                `
              : "What website are you looking to advertise for ?"
          }
          children={
            <div className="ml-[4rem] flex items-center ">
              <Select
                data={selectionData}
                value={selectedListing}
                closeMenuOnSelect={true}
                isMulti={undefined}
                isPending={isLoadingSites}
                placeholder={"Select a website"}
                customStyle={{
                  selectContainer: {
                    paddingInline: 7,
                    paddingBlock: 5,
                    borderRadius: "24px",
                    width: "25rem",
                    marginRight: ".7rem",
                  },
                  color: "#d3d3d3",
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#335498",
                  optionisFocusedTextColor: "white",
                  singleValStyles: {
                    backgroundColor: "transparent",
                  },
                }}
                onChange={(selectedOption: any, actionMeta: any) => {
                  setAdvertiseFlowId(selectedOption.id);
                  setSelectedListing(selectedOption);
                }}
              />
              <Tooltip bodyText="If your website isn't listed, just click on 'Websites' in the top menu and add the one you want to create a ad campaign for." />
            </div>
          }
        />

        {selectedSite && !selectedSite.isFieldsFilled ? (
          <div className="max-w-fit mx-auto">
            <RoundedButton
              className="px-5 mt-10 py-2"
              onClick={() => {
                navigate(`/websites/metadata/${selectedSite.id}`);
              }}
              text="Edit Website Information"
            />
          </div>
        ) : selectedSite && selectedSite.numberOfAssets === 0 ? (
          <div className="max-w-fit mx-auto">
            <RoundedButton
              className="px-5 mt-10 py-2"
              onClick={() => {
                navigate(`/websites/assets/${selectedSite.id}`);
              }}
              text="Edit Website Assets"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Website;
