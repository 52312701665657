import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TopContent from "../topContent";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import CampaignCard from "../../components/campaignsCard";

import RoundedButton from "../../components/roundedButton";
import Modal from "../../components/modal";

import {
  IAllCampaignsDataResponse,
  useAllCampaigns,
  useRemoveCampaign,
} from "../../services/campaigns";

import {
  TCampaignListFilter,
  campaignsListFilterData,
} from "../../constants/wizard/campaign";

const Index = () => {
  const navigate = useNavigate();
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const [, setIsModalOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<TCampaignListFilter | null>(
    campaignsListFilterData[0]
  );
  const [deletionModal, setDeletionModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | undefined>(
    undefined
  );

  const { data: campaignsList } = useAllCampaigns(selectedAdvertiser);
  const {
    isSuccess: isCampaignRemoved,
    isError: isCampaignFailedToRemove,
    mutateAsync: removeCampaign,
  } = useRemoveCampaign(selectedAdvertiser);

  const handleDelete = async (id: number) => {
    await removeCampaign(id);
    setDeletionModal(false);
  };

  const handleDeletionModal = (id: number) => {
    setItemIdToDelete(id);
    setDeletionModal(true);
  };

  const handleView = (id: number) => {
    navigate(`/campaigns/campaignDetails/${id}`);
  };

  useEffect(() => {
    if (isCampaignRemoved) {
      toast.success("Campaign deleted successfully!");
    } else if (isCampaignFailedToRemove) {
      toast.error("Failed to remove the campaign");
    }
  }, [isCampaignRemoved, isCampaignFailedToRemove]);

  return (
    <div>
      <TopContent
        filterText="Status"
        websiteFilter={statusFilter}
        setWebsiteFilter={setStatusFilter}
        filterOptions={campaignsListFilterData}
        setIsModalOpen={setIsModalOpen}
        onFilterClick={() => {}}
        addNewText="+ Campaign"
        onClick={() => navigate("/wizard")}
      />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />

      {deletionModal && (
        <Modal
          setIsModalOpen={setDeletionModal}
          modalBodyContent={
            <div className="flex flex-col p-1">
              <p className="font-ManropeSemiBold">
                Are you sure you want to delete this campaign ?
              </p>
              <div className="flex mt-5 justify-center">
                <RoundedButton
                  text="yes"
                  className="py-2 px-6 ManropeRegular mr-2"
                  onClick={() => {
                    if (itemIdToDelete !== undefined) {
                      handleDelete(itemIdToDelete);
                      setItemIdToDelete(undefined);
                    }
                  }}
                />
                <RoundedButton
                  text="no"
                  className="py-2 px-6 font-ManropeRegular bg-gray-300"
                  onClick={() => setDeletionModal(false)}
                />
              </div>
            </div>
          }
        />
      )}

      {campaignsList
        ?.filter((campaign) => {
          if (statusFilter?.text === "all" || !statusFilter) {
            return campaign;
          }
          return campaign.status === statusFilter.text;
        })
        .map((campaign: IAllCampaignsDataResponse) => (
          <CampaignCard
            key={`c-${campaign.id}`}
            campaign={campaign}
            onView={handleView}
            onDelete={handleDeletionModal}
          />
        ))}
    </div>
  );
};

export default Index;
