import React, { useEffect, useState } from "react";

import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";

import CampaignFieldsRenderer from "./campaignFieldsRenderer";

import WebsiteCard from "../../../../components/websiteCard";
import EditSectionButton from "../../../../components/editSectionButton";
import Button from "../../../../components/button";
import Tooltip from "../../../../components/tooltip";

import {
  useKeywords,
  useUpdateKeywords,
} from "../../../../services/wizard/campaign";
import { useCampaignFields } from "../../../../services/industry-organization-campaign-fields";

import { CampaignFieldData } from "../../../../interfaces";
import { useCampaign } from "../../../../contexts/campaign";
import { useUpdateCampaignFieldsValues } from "../../../../services/campaigns";

const Settings = ({
  multiSelectFieldIds,
  selectedSite,
  campaignFieldVals,
  setCampaignFieldVals,
  initialCampaignFieldVals,
  setInitialCampaignFieldVals,
}: {
  multiSelectFieldIds: {
    id: number;
    campaignFieldName: string;
    campaignFieldId: number;
  }[];
  selectedSite: any;
  campaignFieldVals: CampaignFieldData;
  setCampaignFieldVals: React.Dispatch<React.SetStateAction<CampaignFieldData>>;
  initialCampaignFieldVals: CampaignFieldData;
  setInitialCampaignFieldVals: React.Dispatch<
    React.SetStateAction<CampaignFieldData>
  >;
}) => {
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { campaignId } = useCampaign();

  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  const {
    mutateAsync: updateKeywords,
    isPending: isKeywordsUpdatingNow,
    isError: isUpdatingKeywordsFailed,
  } = useUpdateKeywords(campaignId);
  const { data: keywordsData } = useKeywords(campaignId);
  const { data: campaignFields } = useCampaignFields(
    selectedAdvertiser,
    campaignId
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState,
    reset,
  } = useForm({
    defaultValues: campaignFieldVals,
  });
  const campaignFieldsFormData = watch();

  const {
    handleSubmit: handleSubmitKeywords,
    setValue: setValueKeywords,
    watch: watchKeywords,
    register: registerKeywords,
    formState: formStateKeywords,
  } = useForm({
    defaultValues: {
      keywords: "",
    },
  });

  const keywordsFormData = watchKeywords("keywords");

  const keywordsWithComma =
    keywordsData && keywordsData[0]?.keywords?.join(",");

  const onSubmit = (data: any) => {
    handleSave();
    reset({ ...campaignFieldVals });
    setInitialCampaignFieldVals({ ...campaignFieldVals });
  };

  const onSubmitKeywords = (data: any) => {};

  const {
    mutateAsync: updateCampaignFieldsValues,
    isError: cannotUpdateCampaignFields,
    isSuccess: isFieldsUpdateSuccess,
  } = useUpdateCampaignFieldsValues({
    advertiserId: selectedAdvertiser,
    campaignId,
  });

  async function updateCampaignFields() {
    const reqDataFormat = Object.entries(campaignFieldsFormData).map(
      ([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 1 && value[0].value.length === 0) {
            const { id, campaignFieldId, value: fieldValue } = value[0];

            return {
              id,
              campaignFieldId,
              value: fieldValue,
            };
          }
          const accumulatedValues = value.reduce((acc, cFieldObject) => {
            const { id, campaignFieldId, value } = cFieldObject;
            if (!acc[campaignFieldId]) {
              acc[campaignFieldId] = {
                id,
                campaignFieldId,
                value: new Set(),
              };
            }
            acc[campaignFieldId].value.add(value);
            return acc;
          }, {});

          const arrayObj = Object.values(accumulatedValues).map((obj: any) => ({
            id: obj.id,
            campaignFieldId: obj.campaignFieldId,
            value: `{"${Array.from(obj.value).join('","')}"}`,
          }));
          return arrayObj[0];
        } else {
          return {
            id: value.id,
            campaignFieldId: value.campaignFieldId,
            value: value.value,
          };
        }
      }
    );

    const reqData = [...reqDataFormat];
    // console.log("req data: ", reqData);
    await updateCampaignFieldsValues({ data: reqData });
  }

  const handleSave = async () => {
    if (formStateKeywords?.errors?.keywords?.message) {
      return;
    }

    const reqData = { keywords: keywordsFormData.split("\n") };
    await updateKeywords(reqData);
    await updateCampaignFields();
    setIsEditEnabled(false);
  };

  const setInitialKeywords = () => {
    if (keywordsData && keywordsData.length !== 0) {
      const initialKeywords = keywordsData[0].keywords.join("\n");
      // setKeywordsVal(initialKeywords);
      setValueKeywords("keywords", initialKeywords);
      return;
    }

    setValueKeywords("keywords", "");
    // setKeywordsVal("");
  };

  useEffect(() => {
    setInitialKeywords();
    //eslint-disable-next-line
  }, [keywordsData?.length]);

  useEffect(() => {
    if (cannotUpdateCampaignFields) {
      toast.error("Unable to be update fields!");
    } else if (isFieldsUpdateSuccess) {
      toast.success("Fields successfully updated!");
    }
    // eslint-disable-next-line
  }, [cannotUpdateCampaignFields, isFieldsUpdateSuccess]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <div>{selectedSite && <WebsiteCard site={selectedSite} />}</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)();
          handleSubmitKeywords(onSubmitKeywords)();
        }}
      >
        <div className="flex flex-col rounded-lg py-2 bg-white">
          <div className="flex self-end my-2 mr-4 ">
            {
              <EditSectionButton
                isHidden={isEditEnabled}
                onClick={() => setIsEditEnabled(true)}
              />
            }
          </div>
          <div className="grid  rounded-lg  grid-cols-1  ">
            <CampaignFieldsRenderer
              control={control}
              setValue={setValue}
              getValues={getValues}
              multiSelectFieldIds={multiSelectFieldIds}
              campaignFieldVals={campaignFieldsFormData}
              setCampaignFieldVals={setCampaignFieldVals}
              isEditEnabled={isEditEnabled}
              errors={formState.errors}
              campaignFields={campaignFields}
            />

            <div className="flex px-6 flex-col ml-8 mb-8 ">
              <div className="flex items-center mb-5 -ml-1 pb-3 border-b border-solid">
                <h2 className="text-xl  font-bold text-[#0e086a]  ">
                  Search Keywords That Your Ads Will Appear For
                </h2>
                <Tooltip bodyText="A search keyword is what people type into search engines. The best keywords are based on relevance, volume of searches, how competitive it is to bid on it, alignment to your goals and rank potential. We have selected these keywords for you based on the website you provided." />
              </div>
              <div className="text-md max-w-fit ml-1 mb-2 tracking-tighter inline-flex   font-ManropeSemiBold  ">
                Search keywords{" "}
                {/* <Tooltip
                  bodyText=" These keywords are selected based on your business
                      profile. Keywords determine when and where your ads will
                      appear. When someone searches on Google, your ad may show
                      up if your keywords match their search terms."
                /> */}
              </div>
              <form onSubmit={handleSubmitKeywords(onSubmitKeywords)}>
                <div className="ml-3">
                  {isEditEnabled ? (
                    <div className="flex flex-col max-w-[25rem]">
                      <textarea
                        className={`${
                          formStateKeywords?.errors?.keywords?.message &&
                          "border-red-400"
                        } "w-[25rem] h-48 font-ManropeRegular resize-none border rounded  focus:outline-none p-2 pl-3"`}
                        {...registerKeywords("keywords", {
                          required: "Search keywords is required",
                        })}
                        placeholder="Enter keywords"
                      />
                      {formStateKeywords?.errors && (
                        <p className="text-red-400 font-ManropeRegular pl-3 pt-1">
                          {formStateKeywords.errors?.keywords?.message}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p className="line-clamp-3  font-ManropeRegular">
                        {keywordsWithComma}
                      </p>
                      {isUpdatingKeywordsFailed && (
                        <p className="mt-5 text-sm font-ManropeSemiBold text-red-400">
                          Failed to update keywords!
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          {isEditEnabled && (
            <div className="flex ml-auto mr-2 my-4">
              <Button
                onClick={() => {
                  setInitialKeywords();
                  reset({ ...initialCampaignFieldVals });
                  setCampaignFieldVals({ ...initialCampaignFieldVals });
                  setIsEditEnabled(false);
                }}
                isScaledOnHover={true}
                btnText="Cancel"
                fontFamily="font-ManropeBold"
                fontSize="text-sm"
                bgColor="bg-black"
                marginValues="mr-2"
              />
              <Button
                type="submit"
                isScaledOnHover={true}
                btnText={isKeywordsUpdatingNow ? "Saving..." : "Save"}
                fontFamily="font-ManropeBold"
                fontSize="text-sm"
                bgColor="bg-black"
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Settings;
