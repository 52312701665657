import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import VeloxiLogo from "../../../../assets/icons/veloxi-logo-no-bg.png";
import { formatDateWithTime } from "../../../../utils/formatDateWithTime";
import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

interface CampaignProps {
  generalCampaignInfoArr: any[];
  advanceTargetingCampaignInfoArr: any[];
  geoTargetingCampaignInfoArr: any[];
  campaignName: string | undefined;
  campaignOwnerData: any;
  campaignData: any;
  campaignKeywordsFormatted: any;
  campaignDisplayImage: any;
  campaignDisplayImage2: any;
  campaignSocialImages: any;
  campaignSearchImages: any;
}
Font.register({
  family: "Manrope",
  fonts: [
    {
      src: "/fonts/Manrope/static/Manrope-Light.ttf",
      fontWeight: 300,
    },
    {
      src: "/fonts/Manrope/static/Manrope-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Manrope/static/Manrope-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/Manrope/static/Manrope-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

export const savePDF = async ({
  generalCampaignInfoArr,
  advanceTargetingCampaignInfoArr,
  geoTargetingCampaignInfoArr,
  campaignName,
  campaignOwnerData,
  campaignData,
  campaignKeywordsFormatted,
  campaignDisplayImage,
  campaignDisplayImage2,
  campaignSocialImages,
  campaignSearchImages,
}: CampaignProps) => {
  const Navbar = (
    <View
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#0e086a",
        height: 56,
        marginBottom: 15,
        paddingHorizontal: 16,
      }}
    >
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Image
            src={VeloxiLogo}
            style={{ width: 80, height: 80, objectFit: "contain" }}
          />
        </View>
      </View>
    </View>
  );

  const PDFDoc = (
    <Document>
      <Page size="A5" style={styles.page}>
        {Navbar}

        <View style={styles.section}>
          <Text style={styles.title}>Campaign Info</Text>
          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Campaign for: </Text>
            <Text style={styles.text}>{campaignOwnerData?.displayName}</Text>
          </View>
          {generalCampaignInfoArr.map((campaignFieldsObj) => {
            const fieldTitle = campaignFieldsObj.campaignFieldName;
            const fieldVal = campaignFieldsObj.campaignFieldName
              .toLocaleLowerCase()
              .includes("date")
              ? formatDateWithTime(campaignFieldsObj.value)
              : campaignFieldsObj.value;

            return (
              <View style={styles.textWrapper}>
                <Text style={styles.subTitle}>{fieldTitle}: </Text>
                <Text style={styles.text}>{fieldVal}</Text>
              </View>
            );
          })}

          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Total Budget: </Text>
            <Text style={styles.text}>
              ${campaignData.campaignBudget.budget}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Allocation</Text>
          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Search: </Text>
            <Text style={styles.text}>
              ${campaignData.campaignBudget.searchAllocation}
            </Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Social: </Text>
            <Text style={styles.text}>
              ${campaignData.campaignBudget.socialAllocation}
            </Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Display: </Text>
            <Text style={styles.text}>
              ${campaignData.campaignBudget.displayAllocation}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Targeting</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {geoTargetingCampaignInfoArr.map((campaignFieldsObj) => {
              const fieldTitle = campaignFieldsObj.campaignFieldName;
              const fieldVal = campaignFieldsObj.campaignFieldName
                .toLocaleLowerCase()
                .includes("date")
                ? formatDateWithTime(campaignFieldsObj.value)
                : campaignFieldsObj.value;

              return (
                <View
                  key={`${fieldTitle}-${fieldVal}`}
                  style={{
                    display: "flex",
                    marginBottom: 3,
                    marginRight: 20,
                  }}
                >
                  <Text
                    style={{ fontSize: 10, fontWeight: 600, marginRight: 2 }}
                  >
                    {fieldTitle === "Address"
                      ? "Where your ads will appear:"
                      : fieldTitle}
                  </Text>
                  <Text style={{ fontSize: 10, fontWeight: 300 }}>
                    {parseDefaultVal(fieldVal)}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.textWrapperCol}>
            <Text style={[styles.subTitle, { marginVertical: 4 }]}>
              Search keywords:{" "}
            </Text>
            <Text style={styles.text}>{campaignKeywordsFormatted}</Text>
          </View>
          {advanceTargetingCampaignInfoArr.map((campaignFieldsObj) => {
            const fieldTitle = campaignFieldsObj.campaignFieldName;
            const fieldVal = campaignFieldsObj.campaignFieldName
              .toLocaleLowerCase()
              .includes("date")
              ? formatDateWithTime(campaignFieldsObj.value)
              : campaignFieldsObj.value;

            return (
              <View style={[styles.textWrapper, { marginTop: 5 }]}>
                <Text style={styles.subTitle}>{fieldTitle}: </Text>
                <Text style={styles.text}>{parseDefaultVal(fieldVal)}</Text>
              </View>
            );
          })}
        </View>

        <View break style={styles.section}>
          <Text style={[styles.title, { marginTop: 10 }]}>Sample Creative</Text>
          <Text style={[styles.text, { marginBottom: 5 }]}>
            The following creative concepts are designed to showcase some
            samples of how your brand would appear to a ad audience across
            different media channels. These are tailored to suit your unique
            brand identity and objectives and are optimized based on our
            industry experience to ensure it resonates with audiences. The
            following creative concepts are designed to showcase some samples of
            how your brand would appear to a ad audience across different media
            channels . These are tailored to suit your unique brand identity and
            objectives and are optimized based on our industry experience to
            ensure it resonates with audiences .
          </Text>
          <View style={styles.imageContainer}>
            <View style={styles.column}>
              {campaignDisplayImage && (
                <Image src={campaignDisplayImage} style={styles.image} />
              )}
              {campaignSocialImages && (
                <Image src={campaignSocialImages} style={styles.image} />
              )}
            </View>
            <View style={styles.column}>
              {campaignDisplayImage2 && (
                <Image src={campaignDisplayImage2} style={styles.image} />
              )}
              {campaignSearchImages && (
                <Image src={campaignSearchImages} style={styles.image} />
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  const pdfBlob = await pdf(PDFDoc).toBlob();
  saveAs(pdfBlob, `${campaignName}-${campaignOwnerData?.displayName}.pdf`);
};

const styles = StyleSheet.create({
  page: {
    paddingBottom: 12,
    fontFamily: "Manrope",
  },
  textWrapper: { display: "flex", flexDirection: "row", marginBottom: 5 },
  textWrapperCol: { display: "flex", marginBottom: 5 },
  section: {
    paddingHorizontal: 15,
    marginBottom: 0,
  },
  title: {
    fontWeight: 700,
    color: "#0e086a",
    paddingBottom: 12,
    borderBottom: 1,
    borderColor: "#e5e7eb",
    fontSize: 16,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 10,
    fontWeight: 600,
    marginRight: 2,
  },
  text: {
    fontWeight: 300,
    fontSize: 10,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  column: {
    flexBasis: "48%", // Each column takes up 48% of the container width
    display: "flex",
    flexDirection: "column",
    marginBottom: "2%", // Margin between rows
  },
  image: {
    marginBottom: "2%", // Margin between images in the same column
  },
});
