import Tooltip from "../../../../../components/tooltip";

export const SectionItem = ({
  title,
  value,
  inputName,
  isEditEnabled,
  onChange,
  type,
  tooltipBodyText,
}: {
  title: string;
  value: string | number;
  inputName: string;
  isEditEnabled: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: "text" | "number";
  tooltipBodyText: string;
}) => (
  <div className="flex items-center mt-4">
    <div className="w-[7rem] relative flex items-center">
      <h3 className="  text-lg font-ManropeBold">{title}</h3>
      <Tooltip bodyText={tooltipBodyText} />
    </div>
    <div className="ml-6 border-2 border-solid  border-[#F9F3F0] flex items-center rounded-3xl py-3  w-[15rem]">
      {isEditEnabled ? (
        <input
          className="outline-none w-full px-2 rounded-lg"
          type={type === "number" ? "number" : "text"}
          name={inputName}
          value={value}
          onChange={onChange}
        />
      ) : (
        <p className="text-base pl-5 font-ManropeSemiBold">
          {type === "number" ? `$${value}` : value}
        </p>
      )}
    </div>
  </div>
);
