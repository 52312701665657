import React, { useEffect, useMemo } from "react";

import { isValidPhoneNumber } from "libphonenumber-js";

import { useForm } from "react-hook-form";

import { useAuth } from "../../../contexts/auth";
import { useNotifier } from "../../../contexts/notifier";

import ContentContainer from "../../../components/contentContainer";
import Input from "../../../components/input/inputWithToggle";
import RoundedButton from "../../../components/roundedButton";

import { useUpdateSelf } from "../../../services/user";

import AlertIcon from "../../../assets/icons/icon_alert-warning-yellow.png";
import SuccessIcon from "../../../assets/icons/check.png";

import { ProfileContent } from "../../../interfaces";

interface EditProfileProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
  };
  onClickGoBack: (content: ProfileContent) => void;
}

type TUserData = EditProfileProps["data"];

const EditProfile: React.FC<EditProfileProps> = ({ data, onClickGoBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TUserData>({
    defaultValues: data,
  });

  const userData = watch();

  const { clearAuthStates } = useAuth();
  const { showNotifier, hideNotifier } = useNotifier();

  const {
    mutateAsync: updateUserProfile,
    isPending: isUpdateUserPending,
    isError: isUpdateUserError,
    isSuccess: isUpdateUserSuccess,
  } = useUpdateSelf();

  useEffect(() => {
    hideNotifier();
    if (isUpdateUserError) {
      showNotifier({
        message: "An error occured while updating the user.",
        icon: AlertIcon,
      });
    }
    if (isUpdateUserSuccess) {
      showNotifier({ message: "User updated successfully", icon: SuccessIcon });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserSuccess, isUpdateUserError]);

  const handleUpdateUserProfile = () => {
    updateUserProfile({
      displayName: userData.displayName,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
    });
  };

  const handleClickGoBack = () => {
    onClickGoBack("displayProfile");
    // we need to clearAuthStates manually here as there is no route change happening between displayProfile and editProfile interfaces
    clearAuthStates();
    hideNotifier();
  };

  const phoneNumberValidationMsg = useMemo(
    () =>
      !userData.phoneNumber
        ? undefined
        : isValidPhoneNumber(userData.phoneNumber)
        ? undefined
        : "Phone number is not valid",
    [userData.phoneNumber]
  );

  function onSubmit() {
    handleUpdateUserProfile();
  }

  return (
    <ContentContainer>
      <div className=" flex flex-1 flex-col p-8 ">
        <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
          <h1 className=" text-xl font-ManropeBold text-[#0e086a]">
            Edit User Profile
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-1 flex-col w-[600px] space-y-4">
              <Input
                type="text"
                name="displayName"
                label="Name"
                register={register}
                requiredMessage="Name is required"
                validation={errors.displayName?.message}
                validationClassName="text-red-400 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`${
                  errors.displayName?.message && "border-red-400"
                } focus:outline-blue-200 font-ManropeRegular text-sm text-[#434343]`}
              />

              <Input
                type="text"
                name="email"
                label="Email"
                register={register}
                pattern={{
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Entered value does not match email format",
                }}
                requiredMessage="Email is required"
                validation={errors.email?.message}
                validationClassName="text-red-400 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`${
                  errors.email?.message && "border-red-400"
                }  focus:outline-blue-200  font-ManropeRegular text-sm text-[#434343]`}
              />
              <Input
                type="text"
                name="phoneNumber"
                label="Phone Number"
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`${
                  (errors.phoneNumber?.message || phoneNumberValidationMsg) &&
                  "border-red-400"
                }
                 focus:outline-blue-200  font-ManropeRegular text-sm text-[#434343]`}
                register={register}
                requiredMessage="Phone number is required"
                validation={
                  errors.phoneNumber?.message || phoneNumberValidationMsg
                }
                validationClassName="text-red-400 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
              />
            </div>
            <div className=" flex justify-between pt-10">
              <RoundedButton
                text="BACK"
                className=" w-32 h-10 bg-[#9BA3B0]"
                onClick={handleClickGoBack}
              />
              <RoundedButton
                className=" w-32 h-10"
                type="submit"
                text={isUpdateUserPending ? "Saving..." : "SAVE"}
              />
            </div>
          </form>
        </div>
      </div>
    </ContentContainer>
  );
};

export default EditProfile;
