import CampaignDetails from "../../../containers/campaigns/campaignDetails";

import CampaignDetailsLayout from "../../../layouts/campaignDetails/index";

const CampaingDetails = () => {
  return (
    <CampaignDetailsLayout>
      <CampaignDetails />
    </CampaignDetailsLayout>
  );
};

export default CampaingDetails;
