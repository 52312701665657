import MainLayout from "../../../layouts/main";
// import WebsiteDetails from "../../containers/websiteDetails";
import EditWebsite from "../../../containers/websites/editWebsiteMetaData";

const Index = () => {
  return (
    <MainLayout showSidebar>
      <EditWebsite />
    </MainLayout>
  );
};

export default Index;
