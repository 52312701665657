import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { AddListing } from "./addWebsite";
import TopContent from "../topContent";

import Modal from "../../components/modal";
import WebsiteCard from "../../components/websiteCard";
import RoundedButton from "../../components/roundedButton";

import { useAdvertiserSites, useRemoveLink } from "../../services/web-details";
import { useAdvertiser, useAllAdvertisers } from "../../services/advertiser";

// import { websiteFilterOptions } from "../../constants/websiteFilterOptions";
import { useAddSite } from "../../contexts/addWebsite";
import { isOlderThanSpecifiedMinutes } from "../../utils/isOlderThanSpecifiedMinutes";

const Index = () => {
  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();
  const { data: advertiser } = useAdvertiser(selectedAdvertiser);

  const navigate = useNavigate();
  const [, setIsModalOpen] = useState<boolean>(false);
  // const [websiteFilter, setWebsiteFilter] = useState<"all" | "industry">("all");

  const [deletionModal, setDeletionModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);

  const { isWebsiteError, isWebsitePending, isWebsiteSuccess } = useAddSite();

  const { data: usersAllSites } = useAdvertiserSites(selectedAdvertiser);
  const {
    mutateAsync: removeLink,
    isError: isRemovingLinkError,
    isSuccess: isRemovingLinkSuccess,
  } = useRemoveLink(selectedAdvertiser);

  const { data: allAdvertisers } = useAllAdvertisers();

  const filterOptions = allAdvertisers?.reduce((acc: any, advertiser) => {
    const data = { key: advertiser.id, text: advertiser.displayName };
    acc.push(data);
    return acc;
  }, []);

  const [advertiserFilter, setAdvertiserFilter] = useState<{
    key: number | null;
    text: string;
  } | null>(null);

  const isAllMetaResponseReady = usersAllSites?.every(
    (site) => site.isEditable === true
  );

  const notEditableItem = usersAllSites?.find(
    (site) => site.isEditable === false
  );

  const handleEditMetaData = async (id: number) => {
    navigate(`/websites/metadata/${id}`);
  };

  const handleViewAssets = async (id: number) => {
    navigate(`/websites/assets/${id}`);
  };

  const handleDelete = async (id: number) => {
    await removeLink(id.toString());
    setDeletionModal(false);
  };

  const handleDeletionModal = (id: number) => {
    setItemIdToDelete(id);
    setDeletionModal(true);
  };

  const isSelectedAdvertiserFieldsFilled = advertiser?.isFieldsFilled;

  useEffect(() => {
    if (isWebsiteError) {
      toast.error(`An error occured while processing request`);
    } else if (isWebsiteSuccess) {
      toast.success("Site added successfully!");
    }
  }, [isWebsiteError, isWebsiteSuccess]);

  useEffect(() => {
    if (isRemovingLinkSuccess) {
      toast.success("Site deleted successfully!");
    } else if (isRemovingLinkError) {
      toast.error("Failed to remove the site");
    }
  }, [isRemovingLinkSuccess, isRemovingLinkError]);

  useEffect(() => {
    if (isWebsitePending) {
      const timeoutId = setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["advertiserSites", selectedAdvertiser],
        });
      }, 2500);

      return () => clearTimeout(timeoutId);
    }
  }, [isWebsitePending, selectedAdvertiser]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isAllMetaResponseReady) {
      queryClient.cancelQueries({
        queryKey: ["addedLink"],
      });
    }

    if (
      !isAllMetaResponseReady &&
      !isOlderThanSpecifiedMinutes(notEditableItem?.updatedAt)
    ) {
      intervalId = setInterval(() => {
        queryClient.invalidateQueries({
          queryKey: ["advertiserSites", selectedAdvertiser],
        });
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    //eslint-disable-next-line
  }, [isAllMetaResponseReady, selectedAdvertiser]);

  useEffect(() => {
    if (allAdvertisers?.length !== 0 && selectedAdvertiser) {
      const advertiser = filterOptions?.find(
        (f: any) => f.key === selectedAdvertiser
      );
      setAdvertiserFilter(advertiser);
    }
    // eslint-disable-next-line
  }, [allAdvertisers?.length, selectedAdvertiser]);

  return (
    <>
      <TopContent
        filterText="Advertiser"
        filterOptions={filterOptions}
        websiteFilter={advertiserFilter}
        setWebsiteFilter={setAdvertiserFilter}
        onFilterClick={(id: number) => setSelectedAdvertiser(id)}
        setIsModalOpen={setIsModalOpen}
        addNewText="+ Website"
        onClick={() => navigate("/addWebsite")}
      />

      {advertiser && selectedAdvertiser && isSelectedAdvertiserFieldsFilled && (
        <>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            newestOnTop={true}
            closeOnClick
            hideProgressBar={false}
            theme="light"
            transition={Bounce}
          />

          {deletionModal && (
            <Modal
              setIsModalOpen={setDeletionModal}
              modalBodyContent={
                <div className="flex flex-col p-1">
                  <p className="font-ManropeSemiBold">
                    Are you sure you want to delete this website ?
                  </p>
                  <div className="flex mt-5 justify-center">
                    <RoundedButton
                      text="yes"
                      className="py-2 px-6 ManropeRegular mr-2"
                      onClick={() => {
                        if (itemIdToDelete !== null) {
                          handleDelete(itemIdToDelete);
                          setItemIdToDelete(null);
                        }
                      }}
                    />
                    <RoundedButton
                      text="no"
                      className="py-2 px-6 font-ManropeRegular bg-gray-300"
                      onClick={() => setDeletionModal(false)}
                    />
                  </div>
                </div>
              }
            />
          )}

          {usersAllSites?.map((site) => (
            <WebsiteCard
              onEditMetaData={handleEditMetaData}
              onEditAssets={handleViewAssets}
              onDelete={handleDeletionModal}
              onPause={() => {}}
              site={site}
              key={site.id}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Index;
