import React, { useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "libphonenumber-js";

import { Selection } from "../../components/select";
import FileUpload from "../../components/imageUpload";
import { CountryCodePicker } from "../../components/countryCodePicker";

// import AdeleeIcon from "../../assets/icons/adalee-icon.png";

import { useUploadPhoto } from "../../services/user";

import { handleFileType } from "../../utils/handleFileType";

import { IAddAdvertiserData, TCountry } from "../../interfaces";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { useIndustries } from "../../services/industry";
import { formatFileName } from "../../utils/formatUploadedFileName";
import { IAddAdvertiserDataResponse } from "../../services/advertiser";
import { useNavigate } from "react-router-dom";

interface IAddAdvertiserComponent {
  createAdvertiser: UseMutateAsyncFunction<
    any,
    Error,
    IAddAdvertiserData,
    unknown
  >;
  isNewAdvertiserError: boolean;
  isNewAdvertiserPending: boolean;
  isNewAdvertiserSuccess: boolean;
}

const AddAdvertiser = ({
  createAdvertiser,
  isNewAdvertiserError,
  isNewAdvertiserPending,
  isNewAdvertiserSuccess,
}: IAddAdvertiserComponent) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<IAddAdvertiserData>();

  const registeredFormData: IAddAdvertiserData = watch();

  const isSubmitDisabled =
    !registeredFormData.displayName ||
    !registeredFormData.email ||
    !registeredFormData.phoneNumber ||
    !registeredFormData.photoUrl ||
    !registeredFormData.industryId;

  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<FormData | null>(null);
  const [, setSelectedFileName] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<TCountry>({
    label: "United States",
    flag: "US",
    value: 1,
  });
  const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState<
    string | undefined
  >();

  const {
    mutateAsync: uploadPhotoToGCP,
    // isError: isPhotoUploadError,
    isPending: isPhotoUploadPending,
    isSuccess: isPhotoUploadSuccess,
    data: photoData,
  } = useUploadPhoto();

  const { data: INDUSTRIES, isPending: isFetchingIndustries } = useIndustries();

  const handleSelectChange =
    (key: keyof IAddAdvertiserData, isMulti: boolean) =>
    async (selectedOption: any) => {
      if (isMulti) {
        setValue(
          key,
          selectedOption
            ? selectedOption.map((option: any) => option.value)
            : []
        );
        return;
      }
      setValue(key, selectedOption.value);
    };

  const processFile = async (file: File) => {
    const newFile = formatFileName(file);
    const formData = new FormData();
    formData.append("file", newFile);
    const fileTypeIsValid = await handleFileType(formData, setFile, "file");
    if (fileTypeIsValid) {
      await uploadPhotoToGCP(formData);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      await processFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFileName(e.target.files[0].name);
      await processFile(e.target.files[0]);
    }
  };

  const handleCountryCodeChange = (country: TCountry) => {
    setSelectedCountry(country);
  };

  const onSubmit: SubmitHandler<IAddAdvertiserData> = async (data) => {
    if (isSubmitDisabled) {
      return;
    }

    let { phoneNumber, ...rest } = data;
    const phoneNumberWithCode = `+${selectedCountry.value}${phoneNumber}`;

    if (!isValidPhoneNumber(phoneNumberWithCode)) {
      setPhoneNumberValidMessage(
        "Make sure you have entered a valid phone number."
      );
      return;
    }

    const reqData: IAddAdvertiserData = {
      phoneNumber: phoneNumberWithCode,
      ...rest,
    };
    const advertiser: IAddAdvertiserDataResponse = await createAdvertiser(
      reqData
    );

    if (!advertiser.isFieldsFilled) {
      navigate(`/advertiser/${advertiser.createdAdvertiser.id}`);
    }
  };

  useEffect(() => {
    if (isPhotoUploadSuccess) {
      setValue("photoUrl", photoData.url);
    }
    //eslint-disable-next-line
  }, [isPhotoUploadSuccess]);

  useEffect(() => {
    if (registeredFormData.industryId) {
      clearErrors("industryId");
    }
    if (registeredFormData.photoUrl) {
      clearErrors("photoUrl");
    }
  }, [registeredFormData.industryId, registeredFormData.photoUrl, clearErrors]);

  return (
    <div className="flex  flex-col -mt-6">
      <div className="flex items-center mb-6">
        {/* <div className=" flex-shrink-0  w-11  mr-3">
          <img className="h-8 w-full " src={AdeleeIcon} alt="adelee.png" />
        </div> */}
        <div>
          <h3 className=" font-ManropeBold text-[#292B2B]">Advertiser Info</h3>
        </div>
      </div>

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          <div className="flex flex-col">
            <label
              className="text-sm font-ManropeSemiBold inline-flex items-center   mb-1"
              htmlFor="name"
            >
              Name
              <span className="required-field ml-1"></span>
            </label>
            <input
              type="text"
              className="border outline-none border-solid w-[15rem] border-[#E5EBEE] text-[#292B2B] text-sm font-ManropeRegular   rounded  px-3 py-2"
              {...register("displayName", {
                required: "Name is required",
              })}
            />
            {errors.displayName && (
              <span
                role="alert"
                className="font-ManropeRegular   text-red-400 text-sm mt-1 "
              >
                {errors.displayName.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              className="text-sm font-ManropeSemiBold  mb-1"
              htmlFor="phoneNumber"
            >
              Phone Number
              <span className="required-field ml-1"></span>
            </label>
            <div className=" flex flex-row space-x-1">
              <div className="flex flex-[0.25]">
                <CountryCodePicker
                  customDropdownMenuStyles={{ width: "320%" }}
                  dropdownMenuSpan={{ display: "none" }}
                  onChange={handleCountryCodeChange}
                  value={selectedCountry}
                />
              </div>
              <div className="flex flex-[0.75]">
                <input
                  type="tel"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                  })}
                  className="border outline-none bg-transparent focus:bg-transparent text-sm border-gray-300  font-ManropeRegular rounded p-2 w-full"
                />
              </div>
            </div>
            {phoneNumberValidMessage && (
              <div className="mt-2 -mb-2 w-[15rem]">
                <p className="text-red-400 text-sm font-ManropeRegular">
                  {phoneNumberValidMessage}
                </p>
              </div>
            )}
            {errors.phoneNumber && (
              <span
                role="alert"
                className="font-ManropeRegular  mt-1 text-red-400 text-sm "
              >
                {errors.phoneNumber.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              className="text-sm  font-ManropeSemiBold mb-1"
              htmlFor="email"
            >
              Email
              <span className="required-field ml-1"></span>
            </label>
            <input
              type="email"
              className="border outline-none font-ManropeRegular border-solid w-[15rem] border-[#E5EBEE] text-[#292B2B] text-sm   rounded  px-3 py-2"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Entered value does not match email format",
                },
              })}
            />
            {errors.email && (
              <span
                role="alert"
                className="font-ManropeRegular  mt-1 text-red-400 text-sm "
              >
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              className="text-sm  font-ManropeSemiBold mb-1"
              htmlFor="industryId"
            >
              Industry
              <span className="required-field ml-1"></span>
            </label>
            <Selection
              data={INDUSTRIES}
              isPending={isFetchingIndustries}
              {...register("industryId", {
                required: "Industry is required",
              })}
              closeMenuOnSelect={true}
              isMulti={undefined}
              customStyle={{
                selectContainer: {
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#E5EBEE",
                  borderRadius: "0.25rem",
                  width: "17.2rem",
                  fontSize: 14,
                },
                valueContainer: {
                  paddingInline: "0.75rem",
                  paddingBlock: "0",
                },
                color: "#d3d3d3",
                optionisSelectedBgColor: "transparent",
                optionisFocusedBgColor: "#335498",
                optionisFocusedTextColor: "white",
                singleValStyles: {
                  backgroundColor: "transparent",
                },
              }}
              onChange={handleSelectChange("industryId", false)}
            />
            {errors.industryId && (
              <span
                role="alert"
                className="font-ManropeRegular  mt-1 text-red-400 text-sm "
              >
                {errors.industryId.message}
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-col w-[35.9rem] mt-4">
          <label
            className="text-sm  font-ManropeSemiBold mb-1"
            htmlFor="photoUrl"
          >
            Advertiser image/logo
            <span className="required-field ml-1"></span>
          </label>
          <FileUpload
            handleDrop={handleDrop}
            {...register("photoUrl", {
              required: "Advertiser image/logo is required",
            })}
            defaultHeight="h-[10rem]"
            mainText="Click to upload or drag and drop an image"
            subText="SVG, PNG, JPG or GIF..."
            file={file}
            dragging={dragging}
            setDragging={setDragging}
            handleChange={handleFileChange}
            renderedOnFileSelection={
              <>
                {isPhotoUploadPending && <div className="">Loading...</div>}
                {isPhotoUploadSuccess && (
                  <div className="flex relative  w-full h-full my-2   items-center justify-center">
                    <img
                      src={photoData.url}
                      alt="Uploaded File"
                      className="w-full max-h-[90%] object-contain"
                    />{" "}
                    <button
                      onClick={() => {
                        setFile(null);
                        setValue("photoUrl", null);
                      }}
                      className="absolute top-0 right-0 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-700 transition duration-150 ease-in-out"
                    >
                      X
                    </button>
                  </div>
                )}
              </>
            }
          />
          {errors.photoUrl && (
            <span
              role="alert"
              className="font-ManropeRegular   mt-1 text-red-400 text-sm"
            >
              {errors.photoUrl.message}
            </span>
          )}
        </div>
        {isNewAdvertiserError && (
          <p className="font-ManropeRegular my-5 text-red-400 text-sm">
            Failed to add new advertiser!
          </p>
        )}
        <div className="ml-auto mt-5 flex justify-end">
          <button
            type="submit"
            className={` py-2 px-8  ${"bg-[#5972A5] hover:bg-[#657ba8]"} text-white rounded-3xl border border-[#F1EBDF] p-2  focus:outline-none focus:ring-0 transition duration-150 ease-in-out`}
          >
            <p className="text-sm font-ManropeBold">
              {isNewAdvertiserPending ? "Processing..." : "Add"}
            </p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAdvertiser;
