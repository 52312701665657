import BudgetAllocation from "./budget";

import WebsiteCard from "../../../../components/websiteCard";

import { ICreateBudgetResponse } from "../../../../services/wizard/budget";

const Plan = ({
  campaignId,
  selectedListing,
  selectedSite,
  campaignBudgetData,
}: {
  campaignId: number | undefined;
  selectedListing: any;
  selectedSite: any;
  campaignBudgetData: ICreateBudgetResponse | undefined;
}) => {
  return (
    <div className="pb-6">
      <div className="">
        {selectedListing && <WebsiteCard site={selectedSite} />}
      </div>
      <div className="mt-10">
        {selectedListing && campaignBudgetData && (
          <BudgetAllocation
            campaignId={campaignId}
            campaignBudget={campaignBudgetData}
          />
        )}
      </div>
    </div>
  );
};

export default Plan;
