import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../contexts/auth";

import ActivitySpinner from "../../components/activitySpinner";

import { useGetSelf } from "../../services/user";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { user, sessionToken } = useAuth();
  const { data: userBEData } = useGetSelf(sessionToken);

  if (!user) {
    return <Navigate replace to="/signin" />;
  }

  const renderUserContent = () => {
    if (!userBEData) {
      return <ActivitySpinner />;
      // return <p className="ml-2 mt-2">Loading...</p>;
    }

    return (
      <>
        {user.emailVerified === false && (
          <div className="flex bg-red-300 fixed w-full">
            <p className="text-white py-1 pl-2 text-xl w-full">
              Please verify your mail address via link sent to your account, it will be restricted otherwise!
            </p>
          </div>
        )}
        {children}
      </>
    );
  };

  return <div>{renderUserContent()}</div>;
};

export default PrivateRoute;
