import { IWebDetailsResponse } from "../../services/web-details";
import { useWebsiteType } from "../../services/website-types";

import { extractDomainName } from "../../utils/extractDomainName";

import EditIcon from "../../assets/icons/edit-icon.png";
// import PauseIcon from "../../assets/icons/pause-icon.png";
import DeleteIcon from "../../assets/icons/delete-icon.png";
import AssetIcon from "../../assets/icons/photo-icon.svg";
import { isOlderThanSpecifiedMinutes } from "../../utils/isOlderThanSpecifiedMinutes";

const WebsiteCard = ({
  site,
  onEditMetaData,
  onEditAssets,
  onDelete,
  onPause,
}: {
  site: IWebDetailsResponse;
  onEditMetaData?: (id: number) => any;
  onEditAssets?: (id: number) => any;
  onDelete?: (id: number) => any;
  onPause?: (id: number) => any;
}) => {
  const { data } = useWebsiteType(site.id, site.websiteTypeId);

  return (
    <div className="bg-white shadow-md rounded-lg px-6 py-8 mb-5">
      <div className="flex">
        <div className="mr-6 ">
          {site.isEditable && site.image && (
            <img
              className="w-[11rem] h-[9.2rem] object-contain rounded-lg"
              src={site.image}
              alt="row.png"
            />
          )}
        </div>

        <div className="flex flex-col flex-1">
          <div className="flex">
            <p
              className={`${
                !isOlderThanSpecifiedMinutes(site.updatedAt) && !site.isEditable
                  ? "text-gray-300"
                  : "text-black"
              } text-[1.375rem]  font-ManropeBold`}
            >
              {site.shortName}
            </p>

            {!isOlderThanSpecifiedMinutes(site.updatedAt) &&
              !site.isEditable && (
                <div className="flex justify-center ml-2 items-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-[2.5px] border-b-[2.5px] border-gray-300"></div>
                </div>
              )}
          </div>

          <div className="py-4 flex justify-between pl-4 xs:pr-4 lg:pr-16 my-2 border-y border-[#F9F3F0]">
            <p
              className={`${
                !isOlderThanSpecifiedMinutes(site.updatedAt) && !site.isEditable
                  ? "text-gray-300"
                  : "text-black"
              } text-base font-ManropeRegular truncate max-w-[16rem]`}
            >
              • <span className="ml-2.5">{extractDomainName(site.url)}</span>
            </p>
            {site.isEditable && (
              <p className="text-base font-ManropeRegular">
                • <span className="ml-2.5">{site.numberOfAssets} assets</span>
              </p>
            )}

            <p
              className={`text-base ${
                !isOlderThanSpecifiedMinutes(site.updatedAt) && !site.isEditable
                  ? "text-gray-300"
                  : "text-black"
              } font-ManropeRegular`}
            >
              • <span className="ml-2.5">{data?.name}</span>
            </p>
          </div>
          <div className="flex ">
            <div className="self-end">
              {site.isEditable &&
              (site.numberOfAssets === 0 || site.isFieldsFilled === false) ? (
                <p className="text-sm pb-1 text-red-400 max-w-[85%] font-ManropeSemiBold">
                  Oops, looks like we missed some information. Please edit the
                  website details manually
                </p>
              ) : !isOlderThanSpecifiedMinutes(site.updatedAt) &&
                !site.isEditable ? (
                <p className="text-sm pb-1 text-red-400 max-w-[90%] font-ManropeSemiBold">
                  Hang tight! Give us a min to collect the assets and
                  information on the website for you!
                </p>
              ) : (
                isOlderThanSpecifiedMinutes(site.updatedAt) &&
                !site.isEditable && (
                  <div>
                    <p className="text-sm pb-1 text-red-400 font-ManropeSemiBold">
                      Oops something went wrong. Please try again
                    </p>
                  </div>
                )
              )}
            </div>
            <div className="flex ml-auto space-x-12 mt-3 ">
              {onEditAssets && (
                <div
                  onClick={() => {
                    if (site.isEditable) {
                      onEditAssets(site.id);
                    }
                    return;
                  }}
                  className="flex cursor-pointer items-center"
                >
                  <img
                    src={AssetIcon}
                    className={`${
                      !site.isEditable ? "opacity-20" : "opacity-100"
                    } w-[0.95rem]  h-4`}
                    alt="assets-icon.svg"
                  />
                  <p
                    className={`${
                      !site.isEditable ? "text-gray-300" : "text-black"
                    } uppercase text-sm font-ManropeMedium ml-2`}
                  >
                    View Assets
                  </p>
                </div>
              )}
              {onEditMetaData && (
                <div
                  onClick={() => {
                    if (site.isEditable) {
                      onEditMetaData(site.id);
                    }
                    return;
                  }}
                  className="flex cursor-pointer items-center"
                >
                  <img
                    src={EditIcon}
                    className={`${
                      !site.isEditable ? "opacity-20" : "opacity-100"
                    } w-[0.95rem]  h-4 `}
                    alt="edit-icon.png"
                  />
                  <p
                    className={`${
                      !site.isEditable ? "text-gray-300" : "text-black"
                    } uppercase text-sm font-ManropeMedium ml-2`}
                  >
                    Edit
                  </p>
                </div>
              )}
              {/* {onPause && (
              <div className="flex cursor-pointer items-center">
                <img
                  src={PauseIcon}
                  className={`${
                    !site.isEditable ? "opacity-20" : "opacity-100"
                  } w-[0.95rem]  h-4`}
                  alt="pause-icon.png"
                />
                <p
                  className={`${
                    !site.isEditable ? "text-gray-300" : "text-black"
                  } uppercase text-sm font-ManropeMedium ml-2`}
                >
                  Pause
                </p>
              </div>
            )} */}
              {onDelete && (
                <div
                  onClick={() => {
                    if (
                      !isOlderThanSpecifiedMinutes(site.updatedAt) &&
                      !site.isEditable
                    ) {
                      return;
                    }
                    onDelete(site.id);
                  }}
                  className="flex cursor-pointer items-center"
                >
                  <img
                    src={DeleteIcon}
                    className={`${
                      !isOlderThanSpecifiedMinutes(site.updatedAt) &&
                      !site.isEditable
                        ? "opacity-20"
                        : "opacity-100"
                    } w-[0.95rem]  h-4`}
                    alt="delete-icon.png"
                  />
                  <p
                    className={`${
                      !isOlderThanSpecifiedMinutes(site.updatedAt) &&
                      !site.isEditable
                        ? "text-gray-300"
                        : "text-black"
                    } uppercase text-sm font-ManropeMedium ml-2`}
                  >
                    Delete
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteCard;
