import Campaigns from "../../containers/campaigns";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import MainLayout from "../../layouts/main";

const Index = () => {
  const { selectedAdvertiser } = useSelectedAdvertiser();

  return (
    <MainLayout showSidebar={!selectedAdvertiser} showSidebarLogo>
      <Campaigns />
    </MainLayout>
  );
};

export default Index;
