import MainLayout from "../../layouts/main";
import Websites from "../../containers/websites";

const Index = () => {
  return (
    <MainLayout showSidebar>
      <Websites />
    </MainLayout>
  );
};

export default Index;
