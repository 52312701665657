import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAtPq8NjxUhTYylUi-ObiEWVYCPLGuKw2w",
  authDomain: "veloxi-ai.firebaseapp.com",
  projectId: "veloxi-ai",
  storageBucket: "veloxi-ai.appspot.com",
  messagingSenderId: "637627692254",
  appId: "1:637627692254:web:38ac1b2ae32b97369a7e4f",
  measurementId: "G-9MT70HX58Y",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
