import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddAdvertiser from "./addAdvertiser";
import TopContent from "../topContent";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import AdvertiserCard from "../../components/advertiserCard";

import {
  useAllAdvertisers,
  useCreateAdvertiser,
  useRemoveAdvertiser,
} from "../../services/advertiser";

// import { websiteFilterOptions } from "../../constants/websiteFilterOptions";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

const Index = () => {
  const navigate = useNavigate();

  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [websiteFilter, setWebsiteFilter] = useState<"all" | "industry">("all");

  const {
    mutateAsync: createAdvertiser,
    isPending: isNewAdvertiserPending,
    isError: isNewAdvertiserError,
    isSuccess: isNewAdvertiserSuccess,
  } = useCreateAdvertiser();

  const { data: allAdvertisers, isPending: isAdvertisersPending } =
    useAllAdvertisers();

  const {
    mutateAsync: deleteAdvertiser,
    isSuccess: isDeletedSuccess,
    isError: isDeletedError,
  } = useRemoveAdvertiser();

  const handleEdit = async (id: number) => {
    navigate(`/advertiser/${id}`);
  };

  const handleDelete = async (id: number) => {
    await deleteAdvertiser(id);
    if (id === selectedAdvertiser) {
      setSelectedAdvertiser(null);
    }
  };

  useEffect(() => {
    if (isNewAdvertiserSuccess) {
      setIsModalOpen(false);
    }
  }, [isNewAdvertiserSuccess]);

  useEffect(() => {
    if (isNewAdvertiserSuccess) {
      toast.success("Advertiser added successfully!");
    }
  }, [isNewAdvertiserSuccess]);

  useEffect(() => {
    if (isDeletedError) {
      toast.error("Failed to delete the advertiser");
    } else if (isDeletedSuccess) {
      toast.success("The advertiser deleted successfully!");
    }
  }, [isDeletedError, isDeletedSuccess]);

  const filterOptions = allAdvertisers?.reduce((acc: any, advertiser) => {
    const data = { key: advertiser.id, text: advertiser.displayName };
    acc.push(data);
    return acc;
  }, []);

  const [advertiserFilter, setAdvertiserFilter] = useState<{
    key: number | null;
    text: string;
  } | null>(null);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {isModalOpen && (
        <Modal
          setIsModalOpen={setIsModalOpen}
          modalBodyContent={
            <AddAdvertiser
              createAdvertiser={createAdvertiser}
              isNewAdvertiserError={isNewAdvertiserError}
              isNewAdvertiserPending={isNewAdvertiserPending}
              isNewAdvertiserSuccess={isNewAdvertiserSuccess}
            />
          }
        />
      )}

      <div className="flex flex-col">
        <TopContent
          filterText="Advertiser"
          filterOptions={filterOptions}
          websiteFilter={advertiserFilter}
          setWebsiteFilter={setAdvertiserFilter}
          onFilterClick={(id: number) => setSelectedAdvertiser(id)}
          setIsModalOpen={setIsModalOpen}
          addNewText="+ Add Advertiser"
          isAddBtnDisabled={isNewAdvertiserPending}
        />
      </div>

      {isAdvertisersPending && (
        <div className="mt-10 relative">
          <Loader />
        </div>
      )}

      {allAdvertisers
        ?.filter((advertiser) => {
          if (advertiserFilter?.key) {
            return advertiser.id === advertiserFilter.key;
          }
          return advertiser;
        })
        .map((advertiser) => (
          <AdvertiserCard
            key={advertiser.id}
            advertiser={advertiser}
            onEdit={() => handleEdit(advertiser.id)}
            onDelete={() => handleDelete(advertiser.id)}
          />
        ))}
    </>
  );
};

export default Index;
