import { FC, useState, useContext, createContext, PropsWithChildren } from "react";

import { Notifier } from "../../components/notifier";

export interface NotifierOptions {
  message: string;
  icon?: string;
}

export interface NotifierInterface {
  showNotifier: (options: NotifierOptions) => void;
  showLoading: (isLoading: boolean) => void;
  hideNotifier: () => void;
}

const NotifierContext = createContext({} as NotifierInterface);

export const NotifierProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useNotifierProvider();

  return (
    <NotifierContext.Provider value={value}>
      <Notifier {...value} closeNotifier={value.hideNotifier} />
      {children}
    </NotifierContext.Provider>
  );
};

export const useNotifier = () => {
  return useContext(NotifierContext);
};

const useNotifierProvider = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [icon, setIcon] = useState<string>();

  const showNotifier = (options: NotifierOptions) => {
    setMessage(options.message);
    setIcon(options.icon);
    setVisible(true);
  };

  const showLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  const hideNotifier = () => {
    setVisible(false);
    setMessage("");
    setIcon(undefined);
    setIsLoading(false);
  };

  return {
    visible,
    message,
    icon,
    isLoading,
    showNotifier,
    showLoading,
    hideNotifier,
  };
};
