import React from "react";

import ContentContainer from "../../../components/contentContainer";
// import RoundedButton from "../../../components/roundedButton";

import EditBtnIcon from "../../../assets/icons/edit-icon.png";
import ChevronRightIcon from "../../../assets/icons/chevron-right-white.png";

import { ProfileContent } from "../../../interfaces";

interface DisplayProfileProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
    role: string;
  };
  onClickEdit: (content: ProfileContent) => void;
}

const DisplayProfile: React.FC<DisplayProfileProps> = ({
  data,
  onClickEdit,
}) => {
  const TopContentContainer: React.FC = () => {
    const [showRoles, setShowRoles] = React.useState(false);
    const onClickChevron = () => {
      setShowRoles(!showRoles);
    };
    return (
      <ContentContainer className="mb-4 ">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row space-x-3">
            <div className=" flex border-[1px] rounded-full border-gray w-12 h-10 justify-center items-center cursor-pointer">
              <p>All</p>
            </div>
            <div className="flex border-[1px] rounded-full border-gray w-32 h-10  items-center justify-between pl-5 pr-3 bg-[#00754f] text-white">
              <p>Roles</p>
              <img
                src={ChevronRightIcon}
                alt="list_roles_button"
                className={`w-4 h-3 cursor-pointer ${
                  showRoles ? "-rotate-90" : "rotate-90"
                }`}
                onClick={onClickChevron}
              />
            </div>
          </div>
          {/* <RoundedButton text="+ User" className=" w-24 h-10" /> */}
        </div>
      </ContentContainer>
    );
  };
  return (
    <>
      <TopContentContainer />
      <ContentContainer>
        <div className=" flex flex-1 space-x-5 p-8">
          <div className=" flex flex-1 flex-col space-y-2">
            <p className=" text-2xl font-ManropeBold text-[#5972A5] mb-5">
              {data.displayName}
            </p>
            <hr />

            <div className="flex flex-row space-x-16 py-2">
              <p>• {data.role}</p>
              <p>• {data.email}</p>
              <p>• {data.phoneNumber}</p>
            </div>
            <hr />
          </div>
        </div>

        <div className=" flex justify-end items-end space-x-16 mb-4">
          <div
            className="flex flex-row items-center space-x-1 cursor-pointer"
            onClick={() => onClickEdit("changePassword")}
          >
            <img src={EditBtnIcon} className=" h-3 w-3" alt="sp-icon" />
            <span className=" text-sm">CHANGE PASSWORD</span>
          </div>
          <div
            className="flex flex-row items-center space-x-1 cursor-pointer"
            onClick={() => onClickEdit("editProfile")}
          >
            <img src={EditBtnIcon} className=" h-3 w-3" alt="sp-icon" />
            <span className=" text-sm">EDIT</span>
          </div>
          {/*
            <div
              className="flex flex-row items-center space-x-1 cursor-pointer"
              onClick={() => {}}
            >
              <img src={DeleteBtnIcon} className=" h-3 w-3" alt="sp-icon" />
              <span className=" text-sm">DELETE</span>
            </div>
            */}
        </div>
      </ContentContainer>
    </>
  );
};
export default DisplayProfile;
