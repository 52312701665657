import React from "react";

import { useGetSelf } from "../../services/user";

import MainLayout from "../../layouts/main";

import EditProfile from "../../containers/profile/editProfile";
import DisplayProfile from "../../containers/profile/displayProfile";
import ChangePassword from "../../containers/profile/changePassword";

import { ProfileContent } from "../../interfaces";

const Profile = () => {
  const [content, setContent] = React.useState<ProfileContent>("displayProfile");

  const { data: getSelfData, isLoading: getSelfLoading, isError: getSelfError } = useGetSelf();

  const switchContent = (content: ProfileContent) => {
    setContent(content);
  };

  return (
    <MainLayout showSidebar>
      {getSelfLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Loading...</div>
      )}
      {getSelfError && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Something went wrong...
        </div>
      )}
      <div className=" space-y-5">
        {content === "editProfile" ? (
          <EditProfile data={getSelfData} onClickGoBack={switchContent} />
        ) : content === "changePassword" ? (
          <ChangePassword data={getSelfData} onClickGoBack={switchContent} />
        ) : (
          <DisplayProfile data={getSelfData} onClickEdit={switchContent} />
        )}
      </div>
    </MainLayout>
  );
};

export default Profile;
