import React from "react";

import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from "react-select";

import { COUNTRIES } from "../../constants/countries";
import { flags } from "../../assets/flags";
import { TCountry } from "../../interfaces";

interface CountryCodePickerProps {
  onChange: (selectedOption?: any) => void;
  value?: TCountry;
  customSingleValueStyles?: React.CSSProperties;
  customDropdownMenuStyles?: React.CSSProperties;
  dropdownMenuSpan?: React.CSSProperties;
}

interface StyleProps {
  customStyles?: React.CSSProperties;
  dropdownMenuSpan?: React.CSSProperties;
}

export const CountryCodePicker: React.FC<CountryCodePickerProps> = ({
  onChange,
  value,
  customSingleValueStyles,
  customDropdownMenuStyles,
  dropdownMenuSpan,
}) => {
  const CustomSingleValue: React.FC<
    SingleValueProps<TCountry> & StyleProps
  > = ({ children, customStyles, dropdownMenuSpan, ...props }) => (
    <components.SingleValue {...props}>
      <div
        style={customSingleValueStyles}
        className={`flex   space-x-1 items-center`}
      >
        <img
          className=" w-8 h-4 mr-3 "
          src={flags[props.data.flag]}
          alt="country_code_image"
        />
        <span style={dropdownMenuSpan} className={` font-ManropeBold`}>
          {" "}
          +{props.data.value}
        </span>
        {/* <span className="font-ManropeBold"> +{props.data.value}</span> */}
      </div>
    </components.SingleValue>
  );

  const CustomOption: React.FC<OptionProps<TCountry>> = (props) => (
    <components.Option {...props}>
      <img
        className=" w-8 h-4 mr-3 "
        src={flags[props.data.flag]}
        alt="country_code_image"
      />
      {`${props.data.label}`}
      <span className="text-xs text-gray-500 ml-2 font-ManropeSemiBold">
        +{props.data.value}
      </span>
    </components.Option>
  );

  return (
    <Select
      className="w-full h-full"
      options={COUNTRIES}
      components={{
        SingleValue: (props) => (
          <CustomSingleValue
            {...props}
            customStyles={customSingleValueStyles}
            dropdownMenuSpan={dropdownMenuSpan}
          />
        ),
        Option: CustomOption,
      }}
      value={value}
      onChange={onChange}
      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
        dropdownIndicator: () => ({ display: "none" }),
        control: (styles, { isFocused }) => ({
          ...styles,
          outline: "none",
          fontFamily: "font-ManropeRegular",
          borderColor: isFocused ? "black" : "none",
          borderWidth: isFocused ? "2px" : "1px",
          boxShadow: "none",
          ":focus-within": {
            borderColor: "black",
            borderWidth: "2px",
          },
        }),
        menu: (base, props) => ({
          ...base,
          fontSize: 14,
          ...customDropdownMenuStyles,
        }),
        valueContainer: (base) => ({
          ...base,
          fontSize: 14,
          paddingBlock: "0",
          fontFamily: "font-ManropeRegular",
        }),
        option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
          ...styles,
          display: "flex",
          alignItems: "center",
          padding: 10,
          backgroundColor: isSelected
            ? "transparent"
            : isFocused
            ? "#335498"
            : undefined,
          color: isFocused ? "white" : isSelected ? "white" : "black",
        }),
      }}
    />
  );
};
