// industry-organization-campaign-fields/advertiserId

import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

export interface ICampaignFieldsResponse {
  id: number;
  campaignId: number;
  campaignFieldId: number;
  value: string;
  isRequired: boolean;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  campaignFieldName: string;
  categoryType: "GEO_TARGETING" | "GENERAL" | "ADVANCE_TARGETING";
  type: "DROPDOWN" | "STRING" | "DATE" | "MULTISELECT";
  options: Array<string>;
  metaMappedTo: string | null;
  googleMappedTo: string | null;
  isEditible: boolean;
  isVisible: boolean;
  defaultValue: string;
}

function getCampaignFields(
  campaignId: number | undefined
): Promise<ICampaignFieldsResponse[]> {
  return request({
    url: `campaign-field-values/${campaignId}`,
    method: "get",
  });
}

export function useCampaignFields(
  advertiserId: number | null,
  campaignId: number | undefined
) {
  return useQuery({
    queryKey: ["campaignFields", advertiserId, campaignId],
    queryFn: () => getCampaignFields(campaignId),
    enabled: !!campaignId && !!advertiserId,
  });
}
