import { IAdsFromSameAdType } from "../../interfaces";

export function Modal({
  isOpen,
  isClosing,
  handleOpen,
  handleClose,
  modalData,
}: {
  isOpen: boolean;
  isClosing: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  modalData: IAdsFromSameAdType[] | null;
}) {
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="fixed inset-0 z-40">
            <div
              className={`absolute inset-x-0 top-0 h-1/2 bg-white ${
                isClosing ? "animate-slideUpReverse" : "animate-slideDown"
              }`}
            ></div>
            <div
              className={`absolute inset-x-0 bottom-0 h-1/2 bg-white ${
                isClosing ? "animate-slideDownReverse" : "animate-slideUp"
              }`}
            ></div>
          </div>
          <div
            // className={`${
            //   isClosing && "hidden"
            // } absolute flex flex-col w-[90%]  z-50 p-5 bg-white rounded-lg shadow-lg`}
            className={`${isClosing && "hidden"} absolute inset-0 z-50 `}
          >
            <div
              className="absolute rounded-full py-2 px-4 bg-black text-white top-0 right-0 mt-2  mr-4 font-bold text-xl cursor-pointer"
              onClick={handleClose}
            >
              X
            </div>
            <div className="w-full h-full flex flex-col justify-start items-start p-5">
              <h2 className="text-xl font-bold"> Title</h2>
              <p>content</p>
              {modalData?.map((data: IAdsFromSameAdType) =>
                data.adType === "VIDEO_AD" && data.createdAdUrl ? (
                  <div className="relative h-72 overflow-hidden pb-6">
                    <p> {data.createdAdUrl} </p>
                    {/* <video
                      className="absolute top-0 left-0 w-full h-full"
                      controls
                    >
                      <source src={data.createdAdUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}
                  </div>
                ) : (
                  data.adType === "DISPLAY_AD" &&
                  data.createdAdUrl && (
                    <img
                      alt="ad.png"
                      src={data.createdAdUrl}
                      className="h-[17.5rem] w-[30rem] "
                    />
                  )
                )
              )}
              {/* {modalData?.map((data: IAdsFromSameAdType) => {
                data.adType === "VIDEO_AD" ? (
                 
                ) ;
              })} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
