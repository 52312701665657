import React from "react";

interface StepperProps {
  currentStep: number;
  title: string;
  index: number;
  totalSteps: number;
}

const SidebarStep: React.FC<StepperProps> = ({
  currentStep,
  title,
  index,
  totalSteps,
}) => {
  return (
    <div className="flex justify-center mb-14  last-of-type:mb-0 relative">
      <div className="flex w-[9rem] h-7 items-center">
        <div
          className={` relative rounded-full flex ${
            currentStep === index + 1
              ? "bg-blue-500 w-[1.2rem] -ml-[2px]  h-[1.1rem]"
              : currentStep > index + 1
              ? "bg-blue-400 h-4 w-4"
              : "bg-gray-200 h-4 w-4"
          }`}
        >
          {currentStep === index + 1 && (
            <div className="bg-white absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 rounded-full w-2 h-2"></div>
          )}
          {currentStep > index + 1 && (
            <svg
              className="w-4 h-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M5 13l4 4L19 7"></path>
            </svg>
          )}

          {index < totalSteps - 1 && (
            <div
              className={`absolute   h-12  mx-2 top-[150%]  ${
                currentStep > index + 1
                  ? "bg-blue-500 w-[2.5px] -left-0.5"
                  : currentStep === index + 1
                  ? "bg-gray-300 w-0.5 left-0"
                  : "bg-gray-300 w-0.5 -left-0.5 "
              }`}
            ></div>
          )}
        </div>

        <div
          className={`
        ${
          currentStep > index + 1
            ? "text-blue-500 font-ManropeSemiBold"
            : currentStep === index + 1
            ? "text-black font-ManropeSemiBold"
            : "text-gray-300 font-ManropeRegular"
        }
        ml-3 text-sm text-center
        `}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default SidebarStep;
