import MainLayout from "../../layouts/main";

const Index = () => {
  return (
    <MainLayout showSidebar>
      {/* <Adalee bodyText="Hi, I am Adalee" /> */}
      <div className="flex items-center justify-center h-20">
        <p>Help page is under maintenance.</p>
      </div>
    </MainLayout>
  );
};

export default Index;
