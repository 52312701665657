export const COUNTRIES = [
  {
    label: "Afghanistan",
    flag: "AF",
    value: 93,
  },
  {
    label: "Albania (Shqipëri)",
    flag: "AL",
    value: 355,
  },
  {
    label: "Algeria",
    flag: "DZ",
    value: 213,
  },
  {
    label: "American Samoa",
    flag: "AS",
    value: 1684,
  },
  {
    label: "Andorra",
    flag: "AD",
    value: 376,
  },
  {
    label: "Angola",
    flag: "AO",
    value: 244,
  },
  {
    label: "Anguilla",
    flag: "AI",
    value: 1264,
  },
  {
    label: "Antigua and Barbuda",
    flag: "AG",
    value: 1268,
  },
  {
    label: "Argentina",
    flag: "AR",
    value: 54,
  },
  {
    label: "Armenia (Հայաստան)",
    flag: "AM",
    value: 374,
  },
  {
    label: "Aruba",
    flag: "AW",
    value: 297,
  },
  {
    label: "Australia",
    flag: "AU",
    value: 61,
  },
  {
    label: "Austria (Österreich)",
    flag: "AT",
    value: 43,
  },
  {
    label: "Azerbaijan (Azərbaycan)",
    flag: "AZ",
    value: 994,
  },
  {
    label: "Bahamas",
    flag: "BS",
    value: 1242,
  },
  {
    label: "Bahrain",
    flag: "BH",
    value: 973,
  },
  {
    label: "Bangladesh (বাংলাদেশ)",
    flag: "BD",
    value: 880,
  },
  {
    label: "Barbados",
    flag: "BB",
    value: 1246,
  },
  {
    label: "Belarus (Беларусь)",
    flag: "BY",
    value: 375,
  },
  {
    label: "Belgium (België)",
    flag: "BE",
    value: 32,
  },
  {
    label: "Belize",
    flag: "BZ",
    value: 501,
  },
  {
    label: "Benin (Bénin)",
    flag: "BJ",
    value: 229,
  },
  {
    label: "Bermuda",
    flag: "BM",
    value: 1441,
  },
  {
    label: "Bhutan (འབྲུག)",
    flag: "BT",
    value: 975,
  },
  {
    label: "Bolivia",
    flag: "BO",
    value: 591,
  },
  {
    label: "Bosnia and Herzegovina (Босна и Херцеговина)",
    flag: "BA",
    value: 387,
  },
  {
    label: "Botswana",
    flag: "BW",
    value: 267,
  },
  {
    label: "Brazil (Brasil)",
    flag: "BR",
    value: 55,
  },
  {
    label: "British Indian Ocean Territory",
    flag: "IO",
    value: 246,
  },
  {
    label: "British Virgin Islands",
    flag: "VG",
    value: 1284,
  },
  {
    label: "Brunei",
    flag: "BN",
    value: 673,
  },
  {
    label: "Bulgaria (България)",
    flag: "BG",
    value: 359,
  },
  {
    label: "Burkina Faso",
    flag: "BF",
    value: 226,
  },
  {
    label: "Burundi (Uburundi)",
    flag: "BI",
    value: 257,
  },
  {
    label: "Cambodia (កម្ពុជា)",
    flag: "KH",
    value: 855,
  },
  {
    label: "Cameroon (Cameroun)",
    flag: "CM",
    value: 237,
  },
  {
    label: "Canada",
    flag: "CA",
    value: 1,
  },
  {
    label: "Cape Verde (Kabu Verdi)",
    flag: "CV",
    value: 238,
  },
  {
    label: "Caribbean Netherlands",
    flag: "BQ",
    value: 599,
  },
  {
    label: "Cayman Islands",
    flag: "KY",
    value: 1345,
  },
  {
    label: "Central African Republic (République centrafricaine)",
    flag: "CF",
    value: 236,
  },
  {
    label: "Chad (Tchad)",
    flag: "TD",
    value: 235,
  },
  {
    label: "Chile",
    flag: "CL",
    value: 56,
  },
  {
    label: "China (中国)",
    flag: "CN",
    value: 86,
  },
  {
    label: "Christmas Island",
    flag: "CX",
    value: 61,
  },
  {
    label: "Cocos (Keeling) Islands",
    flag: "CC",
    value: 61,
  },
  {
    label: "Colombia",
    flag: "CO",
    value: 57,
  },
  {
    label: "Comoros",
    flag: "KM",
    value: 269,
  },
  {
    label: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    flag: "CD",
    value: 243,
  },
  {
    label: "Congo (Republic) (Congo-Brazzaville)",
    flag: "CG",
    value: 242,
  },
  {
    label: "Cook Islands",
    flag: "CK",
    value: 682,
  },
  {
    label: "Costa Rica",
    flag: "CR",
    value: 506,
  },
  {
    label: "Côte d’Ivoire",
    flag: "CI",
    value: 225,
  },
  {
    label: "Croatia (Hrvatska)",
    flag: "HR",
    value: 385,
  },
  {
    label: "Cuba",
    flag: "CU",
    value: 53,
  },
  {
    label: "Curaçao",
    flag: "CW",
    value: 599,
  },
  {
    label: "Cyprus (Κύπρος)",
    flag: "CY",
    value: 357,
  },
  {
    label: "Czech Republic (Česká republika)",
    flag: "CZ",
    value: 420,
  },
  {
    label: "Denmark (Danmark)",
    flag: "DK",
    value: 45,
  },
  {
    label: "Djibouti",
    flag: "DJ",
    value: 253,
  },
  {
    label: "Dominica",
    flag: "DM",
    value: 1767,
  },
  {
    label: "Dominican Republic (República Dominicana)",
    flag: "DO",
    value: 1,
  },
  {
    label: "Ecuador",
    flag: "EC",
    value: 593,
  },
  {
    label: "Egypt",
    flag: "EG",
    value: 20,
  },
  {
    label: "El Salvador",
    flag: "SV",
    value: 503,
  },
  {
    label: "Equatorial Guinea (Guinea Ecuatorial)",
    flag: "GQ",
    value: 240,
  },
  {
    label: "Eritrea",
    flag: "ER",
    value: 291,
  },
  {
    label: "Estonia (Eesti)",
    flag: "EE",
    value: 372,
  },
  {
    label: "Ethiopia",
    flag: "ET",
    value: 251,
  },
  {
    label: "Falkland Islands (Islas Malvinas)",
    flag: "FK",
    value: 500,
  },
  {
    label: "Faroe Islands (Føroyar)",
    flag: "FO",
    value: 298,
  },
  {
    label: "Fiji",
    flag: "FJ",
    value: 679,
  },
  {
    label: "Finland (Suomi)",
    flag: "FI",
    value: 358,
  },
  {
    label: "France",
    flag: "FR",
    value: 33,
  },
  {
    label: "French Guiana (Guyane française)",
    flag: "GF",
    value: 594,
  },
  {
    label: "French Polynesia (Polynésie française)",
    flag: "PF",
    value: 689,
  },
  {
    label: "Gabon",
    flag: "GA",
    value: 241,
  },
  {
    label: "Gambia",
    flag: "GM",
    value: 220,
  },
  {
    label: "Georgia (საქართველო)",
    flag: "GE",
    value: 995,
  },
  {
    label: "Germany (Deutschland)",
    flag: "DE",
    value: 49,
  },
  {
    label: "Ghana (Gaana)",
    flag: "GH",
    value: 233,
  },
  {
    label: "Gibraltar",
    flag: "GI",
    value: 350,
  },
  {
    label: "Greece (Ελλάδα)",
    flag: "GR",
    value: 30,
  },
  {
    label: "Greenland (Kalaallit Nunaat)",
    flag: "GL",
    value: 299,
  },
  {
    label: "Grenada",
    flag: "GD",
    value: 1473,
  },
  {
    label: "Guadeloupe",
    flag: "GP",
    value: 590,
  },
  {
    label: "Guam",
    flag: "GU",
    value: 1671,
  },
  {
    label: "Guatemala",
    flag: "GT",
    value: 502,
  },
  {
    label: "Guernsey",
    flag: "GG",
    value: 44,
  },
  {
    label: "Guinea (Guinée)",
    flag: "GN",
    value: 224,
  },
  {
    label: "Guinea-Bissau (Guiné Bissau)",
    flag: "GW",
    value: 245,
  },
  {
    label: "Guyana",
    flag: "GY",
    value: 592,
  },
  {
    label: "Haiti",
    flag: "HT",
    value: 509,
  },
  {
    label: "Honduras",
    flag: "HN",
    value: 504,
  },
  {
    label: "Hong Kong (香港)",
    flag: "HK",
    value: 852,
  },
  {
    label: "Hungary (Magyarország)",
    flag: "HU",
    value: 36,
  },
  {
    label: "Iceland (Ísland)",
    flag: "IS",
    value: 354,
  },
  {
    label: "India (भारत)",
    flag: "IN",
    value: 91,
  },
  {
    label: "Indonesia",
    flag: "ID",
    value: 62,
  },
  {
    label: "Iran",
    flag: "IR",
    value: 98,
  },
  {
    label: "Iraq",
    flag: "IQ",
    value: 964,
  },
  {
    label: "Ireland",
    flag: "IE",
    value: 353,
  },
  {
    label: "Isle of Man",
    flag: "IM",
    value: 44,
  },
  {
    label: "Israel",
    flag: "IL",
    value: 972,
  },
  {
    label: "Italy (Italia)",
    flag: "IT",
    value: 39,
  },
  {
    label: "Jamaica",
    flag: "JM",
    value: 1876,
  },
  {
    label: "Japan (日本)",
    flag: "JP",
    value: 81,
  },
  {
    label: "Jersey",
    flag: "JE",
    value: 44,
  },
  {
    label: "Jordan",
    flag: "JO",
    value: 962,
  },
  {
    label: "Kazakhstan (Казахстан)",
    flag: "KZ",
    value: 7,
  },
  {
    label: "Kenya",
    flag: "KE",
    value: 254,
  },
  {
    label: "Kiribati",
    flag: "KI",
    value: 686,
  },
  {
    label: "Kosovo",
    flag: "XK",
    value: 383,
  },
  {
    label: "Kuwait",
    flag: "KW",
    value: 965,
  },
  {
    label: "Kyrgyzstan (Кыргызстан)",
    flag: "KG",
    value: 996,
  },
  {
    label: "Laos (ລາວ)",
    flag: "LA",
    value: 856,
  },
  {
    label: "Latvia (Latvija)",
    flag: "LV",
    value: 371,
  },
  {
    label: "Lebanon",
    flag: "LB",
    value: 961,
  },
  {
    label: "Lesotho",
    flag: "LS",
    value: 266,
  },
  {
    label: "Liberia",
    flag: "LR",
    value: 231,
  },
  {
    label: "Libya",
    flag: "LY",
    value: 218,
  },
  {
    label: "Liechtenstein",
    flag: "LI",
    value: 423,
  },
  {
    label: "Lithuania (Lietuva)",
    flag: "LT",
    value: 370,
  },
  {
    label: "Luxembourg",
    flag: "LU",
    value: 352,
  },
  {
    label: "Macau (澳門)",
    flag: "MO",
    value: 853,
  },
  {
    label: "Macedonia (FYROM) (Македонија)",
    flag: "MK",
    value: 389,
  },
  {
    label: "Madagascar (Madagasikara)",
    flag: "MG",
    value: 261,
  },
  {
    label: "Malawi",
    flag: "MW",
    value: 265,
  },
  {
    label: "Malaysia",
    flag: "MY",
    value: 60,
  },
  {
    label: "Maldives",
    flag: "MV",
    value: 960,
  },
  {
    label: "Mali",
    flag: "ML",
    value: 223,
  },
  {
    label: "Malta",
    flag: "MT",
    value: 356,
  },
  {
    label: "Marshall Islands",
    flag: "MH",
    value: 692,
  },
  {
    label: "Martinique",
    flag: "MQ",
    value: 596,
  },
  {
    label: "Mauritania",
    flag: "MR",
    value: 222,
  },
  {
    label: "Mauritius (Moris)",
    flag: "MU",
    value: 230,
  },
  {
    label: "Mayotte",
    flag: "YT",
    value: 262,
  },
  {
    label: "Mexico (México)",
    flag: "MX",
    value: 52,
  },
  {
    label: "Micronesia",
    flag: "FM",
    value: 691,
  },
  {
    label: "Moldova (Republica Moldova)",
    flag: "MD",
    value: 373,
  },
  {
    label: "Monaco",
    flag: "MC",
    value: 377,
  },
  {
    label: "Mongolia (Монгол)",
    flag: "MN",
    value: 976,
  },
  {
    label: "Montenegro (Crna Gora)",
    flag: "ME",
    value: 382,
  },
  {
    label: "Montserrat",
    flag: "MS",
    value: 1664,
  },
  {
    label: "Morocco",
    flag: "MA",
    value: 212,
  },
  {
    label: "Mozambique (Moçambique)",
    flag: "MZ",
    value: 258,
  },
  {
    label: "Myanmar (Burma)",
    flag: "MM",
    value: 95,
  },
  {
    label: "Namibia (Namibië)",
    flag: "NA",
    value: 264,
  },
  {
    label: "Nauru",
    flag: "NR",
    value: 674,
  },
  {
    label: "Nepal (नेपाल)",
    flag: "NP",
    value: 977,
  },
  {
    label: "Netherlands (Nederland)",
    flag: "NL",
    value: 31,
  },
  {
    label: "New Caledonia (Nouvelle-Calédonie)",
    flag: "NC",
    value: 687,
  },
  {
    label: "New Zealand",
    flag: "NZ",
    value: 64,
  },
  {
    label: "Nicaragua",
    flag: "NI",
    value: 505,
  },
  {
    label: "Niger (Nijar)",
    flag: "NE",
    value: 227,
  },
  {
    label: "Nigeria",
    flag: "NG",
    value: 234,
  },
  {
    label: "Niue",
    flag: "NU",
    value: 683,
  },
  {
    label: "Norfolk Island",
    flag: "NF",
    value: 672,
  },
  {
    label: "North Korea (조선 민주주의 인민 공화국)",
    flag: "KP",
    value: 850,
  },
  {
    label: "Northern Mariana Islands",
    flag: "MP",
    value: 1670,
  },
  {
    label: "Norway (Norge)",
    flag: "NO",
    value: 47,
  },
  {
    label: "Oman",
    flag: "OM",
    value: 968,
  },
  {
    label: "Pakistan",
    flag: "PK",
    value: 92,
  },
  {
    label: "Palau",
    flag: "PW",
    value: 680,
  },
  {
    label: "Palestine",
    flag: "PS",
    value: 970,
  },
  {
    label: "Panama (Panamá)",
    flag: "PA",
    value: 507,
  },
  {
    label: "Papua New Guinea",
    flag: "PG",
    value: 675,
  },
  {
    label: "Paraguay",
    flag: "PY",
    value: 595,
  },
  {
    label: "Peru (Perú)",
    flag: "PE",
    value: 51,
  },
  {
    label: "Philippines",
    flag: "PH",
    value: 63,
  },
  {
    label: "Poland (Polska)",
    flag: "PL",
    value: 48,
  },
  {
    label: "Portugal",
    flag: "PT",
    value: 351,
  },
  {
    label: "Puerto Rico",
    flag: "PR",
    value: 1,
  },
  {
    label: "Qatar",
    flag: "QA",
    value: 974,
  },
  {
    label: "Réunion (La Réunion)",
    flag: "RE",
    value: 262,
  },
  {
    label: "Romania (România)",
    flag: "RO",
    value: 40,
  },
  {
    label: "Russia (Россия)",
    flag: "RU",
    value: 7,
  },
  {
    label: "Rwanda",
    flag: "RW",
    value: 250,
  },
  {
    label: "Saint Barthélemy (Saint-Barthélemy)",
    flag: "BL",
    value: 590,
  },
  {
    label: "Saint Helena",
    flag: "SH",
    value: 290,
  },
  {
    label: "Saint Kitts and Nevis",
    flag: "KN",
    value: 1869,
  },
  {
    label: "Saint Lucia",
    flag: "LC",
    value: 1758,
  },
  {
    label: "Saint Martin (Saint-Martin (partie française))",
    flag: "MF",
    value: 590,
  },
  {
    label: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    flag: "PM",
    value: 508,
  },
  {
    label: "Saint Vincent and the Grenadines",
    flag: "VC",
    value: 1784,
  },
  {
    label: "Samoa",
    flag: "WS",
    value: 685,
  },
  {
    label: "San Marino",
    flag: "SM",
    value: 378,
  },
  {
    label: "São Tomé and Príncipe (São Tomé e Príncipe)",
    flag: "ST",
    value: 239,
  },
  {
    label: "Saudi Arabia",
    flag: "SA",
    value: 966,
  },
  {
    label: "Senegal (Sénégal)",
    flag: "SN",
    value: 221,
  },
  {
    label: "Serbia (Србија)",
    flag: "RS",
    value: 381,
  },
  {
    label: "Seychelles",
    flag: "SC",
    value: 248,
  },
  {
    label: "Sierra Leone",
    flag: "SL",
    value: 232,
  },
  {
    label: "Singapore",
    flag: "SG",
    value: 65,
  },
  {
    label: "Sint Maarten",
    flag: "SX",
    value: 1721,
  },
  {
    label: "Slovakia (Slovensko)",
    flag: "SK",
    value: 421,
  },
  {
    label: "Slovenia (Slovenija)",
    flag: "SI",
    value: 386,
  },
  {
    label: "Solomon Islands",
    flag: "SB",
    value: 677,
  },
  {
    label: "Somalia (Soomaaliya)",
    flag: "SO",
    value: 252,
  },
  {
    label: "South Africa",
    flag: "ZA",
    value: 27,
  },
  {
    label: "South Korea (대한민국)",
    flag: "KR",
    value: 82,
  },
  {
    label: "South Sudan",
    flag: "SS",
    value: 211,
  },
  {
    label: "Spain (España)",
    flag: "ES",
    value: 34,
  },
  {
    label: "Sri Lanka (ශ්‍රී ලංකාව)",
    flag: "LK",
    value: 94,
  },
  {
    label: "Sudan",
    flag: "SD",
    value: 249,
  },
  {
    label: "Surilabel",
    flag: "SR",
    value: 597,
  },
  {
    label: "Svalbard and Jan Mayen",
    flag: "SJ",
    value: 47,
  },
  {
    label: "Swaziland",
    flag: "SZ",
    value: 268,
  },
  {
    label: "Sweden (Sverige)",
    flag: "SE",
    value: 46,
  },
  {
    label: "Switzerland (Schweiz)",
    flag: "CH",
    value: 41,
  },
  {
    label: "Syria",
    flag: "SY",
    value: 963,
  },
  {
    label: "Taiwan (台灣)",
    flag: "TW",
    value: 886,
  },
  {
    label: "Tajikistan",
    flag: "TJ",
    value: 992,
  },
  {
    label: "Tanzania",
    flag: "TZ",
    value: 255,
  },
  {
    label: "Thailand (ไทย)",
    flag: "TH",
    value: 66,
  },
  {
    label: "Timor-Leste",
    flag: "TL",
    value: 670,
  },
  {
    label: "Togo",
    flag: "TG",
    value: 228,
  },
  {
    label: "Tokelau",
    flag: "TK",
    value: 690,
  },
  {
    label: "Tonga",
    flag: "TO",
    value: 676,
  },
  {
    label: "Trinidad and Tobago",
    flag: "TT",
    value: 1868,
  },
  {
    label: "Tunisia",
    flag: "TN",
    value: 216,
  },
  {
    label: "Turkey (Türkiye)",
    flag: "TR",
    value: 90,
  },
  {
    label: "Turkmenistan",
    flag: "TM",
    value: 993,
  },
  {
    label: "Turks and Caicos Islands",
    flag: "TC",
    value: 1649,
  },
  {
    label: "Tuvalu",
    flag: "TV",
    value: 688,
  },
  {
    label: "U.S. Virgin Islands",
    flag: "VI",
    value: 1340,
  },
  {
    label: "Uganda",
    flag: "UG",
    value: 256,
  },
  {
    label: "Ukraine (Україна)",
    flag: "UA",
    value: 380,
  },
  {
    label: "United Arab Emirates",
    flag: "AE",
    value: 971,
  },
  {
    label: "United Kingdom",
    flag: "GB",
    value: 44,
  },
  {
    label: "United States",
    flag: "US",
    value: 1,
  },
  {
    label: "Uruguay",
    flag: "UY",
    value: 598,
  },
  {
    label: "Uzbekistan (Oʻzbekiston)",
    flag: "UZ",
    value: 998,
  },
  {
    label: "Vanuatu",
    flag: "VU",
    value: 678,
  },
  {
    label: "Vatican City (Città del Vaticano)",
    flag: "VA",
    value: 39,
  },
  {
    label: "Venezuela",
    flag: "VE",
    value: 58,
  },
  {
    label: "Vietnam (Việt Nam)",
    flag: "VN",
    value: 84,
  },
  {
    label: "Wallis and Futuna",
    flag: "WF",
    value: 681,
  },
  {
    label: "Western Sahara",
    flag: "EH",
    value: 212,
  },
  {
    label: "Yemen",
    flag: "YE",
    value: 967,
  },
  {
    label: "Zambia",
    flag: "ZM",
    value: 260,
  },
  {
    label: "Zimbabwe",
    flag: "ZW",
    value: 263,
  },
  {
    label: "Åland Islands",
    flag: "AX",
    value: 358,
  },
] as const;
