import React from "react";
// import AdeleeIcon from "../../assets/icons/adalee-icon.png";

const Adalee = ({
  bodyText,
  children,
  isSeperatorVisible = true,
}: {
  bodyText: string;
  children?: React.ReactNode;
  isSeperatorVisible?: boolean;
}) => {
  return (
    <div className="bg-white  rounded-lg flex flex-col  py-8">
      <div
        className={`flex ${
          isSeperatorVisible && "border-b border-[#F9F3F0]"
        }   pb-4 `}
      >
        <div className="self-center flex-shrink-0  w-11  mr-3">
          {/* <img className="h-8 w-full " src={AdeleeIcon} alt="adelee.png" /> */}
        </div>
        <div className="px-8 py-3  self-end rounded-3xl mb-4 bg-[#F4F2EE] border border-[#F9F3F0]">
          <p className="text-base font-ManropeRegular ">{bodyText}</p>
        </div>
      </div>
      {children && children}
    </div>
  );
};

export default Adalee;
