import React from "react";

import { ITable } from "../../interfaces";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const Table: React.FC<ITable> = ({
  tableId,
  tableStyle,
  tableTheme,
  gridRef,
  assetRowData,
  columnData,
  onGridReady,
  onCellValueChanged,
  onRowClicked,
  rowHeight,
  pagination,
  paginationPageSize,
}) => {
  return (
    <div
      id={tableId}
      style={tableStyle ?? { width: "100%", height: "550px" }}
      className={tableTheme ?? "ag-theme-quartz "}
    >
      <AgGridReact
        ref={gridRef}
        rowData={assetRowData}
        onCellValueChanged={onCellValueChanged}
        onGridReady={onGridReady}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        columnDefs={columnData}
        rowHeight={rowHeight ?? 70}
        pagination={pagination ?? true}
        paginationPageSize={paginationPageSize ?? 20}
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default Table;

// const onGridReady = useCallback((params: any) => {
//     setTimeout(() => {
//       params.api.forEachNode((node: any) => {
//         if (node.data.is_excluded) {
//           node.setSelected(true);
//         }
//       });
//     });
//   }, []);

//   cellRenderer: (params: any) => {
//     return (
//       <input
//         type="checkbox"
//         onClick={(e: any) => {
//           e.stopPropagation();
//           const isSelected = e.target.checked;
//           params.node.setSelected(isSelected);
//           params.data.is_excluded = isSelected;
//         }}
//         checked={params.value}
//       />
//     );
//   },
