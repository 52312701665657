interface TableImageModalProps {
  isOpen: string | null;
  src: string | null;
  onClose: () => void;
}

const TableImageModal: React.FC<TableImageModalProps> = ({
  isOpen,
  src,
  onClose,
}) => {
  if (!isOpen || !src) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-[999]  flex justify-center items-center "
      onClick={onClose}
    >
      <img
        src={src}
        alt="enlarged"
        className="max-w-screen-lg max-h-[75vh]"
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
};

export default TableImageModal;
