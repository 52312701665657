import { ChangeEvent, useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ColDef } from "ag-grid-community";

import Button from "../../components/button";
import ContentContainer from "../../components/contentContainer";
import Table from "../../components/table";
import RoundedButton from "../../components/roundedButton";
import TableImageModal from "../../components/modal/tableImageModal";
import Tooltip from "../../components/tooltip";

import {
  useUpdateAsset,
  useWebsiteAssets,
} from "../../services/website-assets";

import { formatDate } from "../../utils/formatDate";

import { IAssetData, IClickedAssetData } from "../../interfaces";

const Assets = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const idToNumber = id ? parseInt(id) : null;

  const { data: assetData, isPending: isLoadingAssetData } =
    useWebsiteAssets(idToNumber);

  const { mutateAsync: updateAssets, isSuccess: isUpdatingNewAssetSuccess } =
    useUpdateAsset(idToNumber);

  const [isTableEdited, setIsTableEdited] = useState<boolean>(false);
  const [clickedRows, setClickedRows] = useState<any[]>([]);

  const [assetRowData, setAssetRowData] = useState<IAssetData[] | []>([]);
  const [initialRowData, setInitialRowData] = useState<IAssetData[] | []>([]);

  const [modalImage, setModalImage] = useState<string | null>(null);

  const [assetErrorMessage, setAssetErrorMessage] = useState<null | string>(
    null
  );

  // const isExcludedComparator = (valueA: boolean, valueB: boolean) => {
  //   if (valueA === valueB) return 0;
  //   return valueA ? 1 : -1;
  // };

  const columnData: ColDef[] = [
    {
      headerName: "",
      field: "imageUrl",
      cellRenderer: (params: any) => (
        <img
          className="object-contain"
          src={params.value}
          alt="row.png"
          onClick={() => setModalImage(params.value)}
        />
      ),
      width: 110,
      suppressSpanHeaderHeight: true,
      resizable: false,
    },
    {
      headerName: "File Name",
      field: "name",
      flex: 3,
      width: 440,
      sortable: true,
      filter: true,
      resizable: false,
    },
    {
      headerName: "Added By",
      field: "method",
      sortable: true,
      width: 305,
      flex: 2,
      filter: true,
      cellRenderer: (params: any) => (
        <h2 className="font-bold">
          {" "}
          {params.value === "SYSTEM" ? "AI" : "STOCK_LIBRARY" ? "Stock Library" :"User"}{" "}
        </h2>
      ),
      resizable: false,
    },
    {
      headerName: "Date Added",
      field: "createdAt",
      sortable: true,
      flex: 2,
      filter: true,
      cellRenderer: (params: any) => {
        return <p>{formatDate(params.value)}</p>;
      },
      resizable: false,
    },
    {
      headerName: "Excluded from creative",
      field: "isExcluded",
      sort: "asc",
      // flex: 2,
      width: 210,
      cellStyle: { justifyContent: "center" },
      cellRenderer: (params: any) => {
        return (
          <input
            className="h-5 w-5 accent-green-700 border-2 border-solid"
            type="checkbox"
            checked={params.value}
            onChange={(e) => {
              handleCheckBox(e, params.node);
            }}
          />
        );
      },
      resizable: false,
    },
  ];
  const [gridApi, setGridApi] = useState<any>(null);

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  function handleCheckBox(e: ChangeEvent<HTMLInputElement>, params: any) {
    params.setDataValue("isExcluded", e.target.checked);
    params.setSelected(e.target.checked);

    if (!isTableEdited) {
      setIsTableEdited(true);
    }

    setClickedRows((prev: IClickedAssetData[]): IClickedAssetData[] => {
      const { id: paramsId, isExcluded: paramsExcluded } =
        params.data as IClickedAssetData;

      const isExistingItem = prev.findIndex(
        (data) => data.id === params.data.id
      );

      if (isExistingItem !== -1) {
        const updatedArray = prev.map((item): IClickedAssetData => {
          if (item.id === paramsId) {
            return { ...item, id: paramsId, isExcluded: paramsExcluded };
          } else {
            return item;
          }
        });

        return updatedArray;
      } else {
        return [...prev, { id: paramsId, isExcluded: paramsExcluded }];
      }
    });
  }

  const handleUpdateAssets = async () => {
    try {
      // setIsTableEdited(false);
      const requestData = { assets: [...clickedRows] };
      const hasAtleastOneItemNotExcluded = assetRowData.some(
        (rowData) => rowData.isExcluded === false
      );

      if (!hasAtleastOneItemNotExcluded) {
        setAssetErrorMessage("At least 1 asset must be available");
        return;
      }

      if (hasAtleastOneItemNotExcluded) {
        setIsTableEdited(false);
        setAssetErrorMessage(null);
        await updateAssets(requestData);
        setClickedRows([]);
      }
    } catch (error) {
      console.error("can't update the assets: ", error);
    }
  };

  useEffect(() => {
    if (assetData) {
      setAssetRowData([...assetData?.map((item: any) => ({ ...item }))]);
      setInitialRowData(assetData.map((item: any) => ({ ...item })));
    }
  }, [assetData]);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.forEachNode((node: any) => {
          if (node.data.isExcluded) {
            node.setSelected(true);
          }
        });
      }, 500);
    }
  }, [gridApi, isUpdatingNewAssetSuccess]);

  useEffect(() => {
    if (isLoadingAssetData && gridApi) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [isLoadingAssetData, gridApi]);

  return (
    <ContentContainer>
      {modalImage && (
        <TableImageModal
          isOpen={modalImage}
          src={modalImage}
          onClose={() => setModalImage(null)}
        />
      )}
      <p
        className="font-ManropeBold cursor-pointer text-[#5972A5] text-sm inline-flex items-center"
        onClick={() => navigate(-1)}
      >
        Go Back
      </p>

      <div>
        <div className="flex items-center pt-4">
          <h3 className="text-xl font-ManropeBold text-[#0e086a]">
            Edit Website Assets
          </h3>
          <Tooltip bodyText="We’ve downloaded the images from your website that are perfect for your ads. These will be used to create your campaigns. If there are any images you’d like to exclude, feel free to do so!" />
        </div>

        <div className="flex items-center mt-5">
          {assetErrorMessage && (
            <p className="text-red-400 font-ManropeSemiBold  text-sm">
              {assetErrorMessage}
            </p>
          )}
          {isTableEdited && (
            <>
              <Button
                onClick={() => {
                  setAssetRowData([
                    ...initialRowData.map((item) => ({ ...item })),
                  ]);

                  setClickedRows([]);
                  setIsTableEdited(false);
                  setTimeout(() => {
                    gridApi.forEachNode((node: any) => {
                      if (node.data.isExcluded) {
                        node.setSelected(true);
                      }
                    });
                  }, 100);
                }}
                fontSize="text-sm"
                paddingValues="px-6 py-3"
                bgColor="bg-slate-400"
                marginValues="ml-auto mb-3"
                btnText="Cancel"
              />
              <Button
                onClick={handleUpdateAssets}
                fontSize="text-sm"
                paddingValues="px-6 py-3"
                marginValues="ml-4 mb-3"
                btnText="Save"
              />
            </>
          )}

          <RoundedButton
            onClick={() => navigate(`/websites/assets/${id}/addAsset`)}
            text="Add Asset"
            className={`${
              isTableEdited ? "ml-4 " : "ml-auto"
            } px-6 py-3 text-sm font-ManropeBold mb-3`}
          />
        </div>
        <Table
          tableId="assetsGrid"
          onGridReady={onGridReady}
          columnData={columnData}
          assetRowData={assetRowData}
        />
      </div>
    </ContentContainer>
  );
};

export default Assets;
