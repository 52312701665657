import React, { useRef, useState } from "react";

import RoundedButton from "../../components/roundedButton";

import ChevronRightIcon from "../../assets/icons/chevron-right-white.png";
import { capitalizeFirstWord } from "../../utils/capitalizeFirstWord";
import useClickOutside from "../../hooks/useClickOutside";

type FilterKey = any;

// interface WebsiteFilterOption<T extends FilterKey> {
//   key: T;
//   text: string;
// }

type SetWebsiteFilter<T extends FilterKey> = React.Dispatch<
  React.SetStateAction<T>
>;

interface ITopContent<T extends FilterKey> {
  filterText?: string;
  filterOptions?: any[];
  websiteFilter?: T | null;
  setWebsiteFilter?: SetWebsiteFilter<T> | null;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addNewText?: string;
  isAddBtnDisabled?: boolean;
  onClick?: () => void;
  onFilterClick?: (id: number) => void;
  warningMessage?: boolean;
  children?: React.ReactNode;
}

const Index: React.FC<ITopContent<FilterKey>> = ({
  filterText,
  filterOptions,
  websiteFilter,
  setWebsiteFilter,
  setIsModalOpen,
  addNewText,
  isAddBtnDisabled,
  onClick,
  onFilterClick,
  warningMessage,
  children,
}) => {
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onClickChevron = () => {
    setIsOptionsShown(!isOptionsShown);
  };

  useClickOutside(dropdownRef, () => {
    setIsOptionsShown(false);
  });

  return (
    <>
      {warningMessage ? (
        children
      ) : (
        <div className="bg-[#FFFDFA] mb-5  flex rounded-lg px-6 py-8">
          <div className="flex space-x-3">
            <div
              onClick={() => setWebsiteFilter && setWebsiteFilter(null)}
              className=" flex border-[1px] rounded-full border-gray w-12 h-10 justify-center items-center cursor-pointer"
            >
              <p>All</p>
            </div>
            <div
              onClick={onClickChevron}
              className="flex relative cursor-pointer border-[1px] rounded-full border-gray w-32 h-10  items-center justify-between pl-5 pr-3 bg-[#00754f] text-white"
            >
              <p>{filterText}</p>
              <img
                src={ChevronRightIcon}
                alt="list_roles_button"
                className={`w-4 h-3 transition-transform ease-linear cursor-pointer ${
                  isOptionsShown ? "-rotate-90" : "rotate-90"
                }`}
              />

              {filterOptions && (
                <div
                  ref={dropdownRef}
                  className={`bg-white absolute top-[110%] left-2 ${
                    isOptionsShown ? "flex" : "hidden"
                  }   border shadow-sm px-7 w-[13.5rem] max-h-[240px] overflow-y-auto pt-2 py-12 transition-all ease-in rounded-lg duration-300 right-0 flex-col`}
                >
                  <div className="absolute left-0 top-8 w-0.5 h-12  bg-green-600"></div>
                  <p className="text-sm mb-2 font-ManropeBold text-black ">
                    Filter by {filterText}
                  </p>
                  {filterOptions?.map((f) => {
                    // const advertiserSelect = filterOptions
                    return (
                      <div
                        key={f.key}
                        // onClick={() => setWebsiteFilter && setWebsiteFilter(f)}
                        onClick={() => {
                          onFilterClick && onFilterClick(f.key);
                          setWebsiteFilter && setWebsiteFilter(f);
                        }}
                        className="last-of-type:mb-0 mb-3"
                      >
                        <p
                          className={`${
                            websiteFilter?.key === f.key
                              ? "text-green-600  font-ManropeBold "
                              : "text-[#292B2B]  font-ManropeRegular"
                          } text-sm inline-flex items-center`}
                        >
                          {capitalizeFirstWord(f.text)}
                          {websiteFilter?.key === f.key && (
                            <span className="ml-1">
                              <svg
                                className="w-4 h-4 text-green-500"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M5 13l4 4L19 7"></path>
                              </svg>
                            </span>
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <RoundedButton
            text={addNewText ?? ""}
            disabled={isAddBtnDisabled}
            onClick={() => {
              setIsModalOpen && setIsModalOpen(true);
              onClick && onClick();
            }}
            className="flex ml-auto px-6 py-3"
          />
        </div>
      )}
    </>
  );
};

export default Index;
