import MainLayout from "../../layouts/main";

const Index = () => {
  return (
    <MainLayout showSidebar>
      <div className="flex items-center justify-center h-20">Coming soon</div>
      {/* <Adalee bodyText="Hi, I am Adalee" /> */}
    </MainLayout>
  );
};

export default Index;
