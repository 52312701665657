export const parseMultipleSelectObject = (values: any) => {
  try {
    if (Array.isArray(values)) return values;

    const formattedValues = values
      .replace(/^{/, "[")
      .replace(/}$/, "]")
      .replace(/{/g, "[")
      .replace(/}/g, "]");
    return JSON.parse(formattedValues);
  } catch (e) {
    console.error("Error parsing values:", e);
    return [];
  }
};
