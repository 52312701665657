import WebsiteCard from "../../../../components/websiteCard";
// import PaymentCard from "../../../../components/paymentCard";

import { useCampaign } from "../../../../contexts/campaign";

import { CampaignFieldData } from "../../../../interfaces";

import { IWebDetailsResponse } from "../../../../services/web-details";
import { useCampaignBudget } from "../../../../services/wizard/budget";
import { useKeywords } from "../../../../services/wizard/campaign";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

const Confirm = ({
  selectedSite,
  configs,
}: {
  selectedSite: IWebDetailsResponse | undefined;
  configs: CampaignFieldData;
}) => {
  const { campaignId } = useCampaign();

  const { data: campaignBudgetData } = useCampaignBudget(campaignId);
  const { data: keywordsData } = useKeywords(campaignId);

  const keywordsWithComma = keywordsData && keywordsData[0].keywords?.join(",");

  let searchAllocation, displayAllocation, socialAllocation, budget;

  if (campaignBudgetData) {
    searchAllocation = parseInt(campaignBudgetData.searchAllocation.toFixed(0));
    displayAllocation = parseInt(
      campaignBudgetData.displayAllocation.toFixed(0)
    );
    socialAllocation = parseInt(campaignBudgetData.socialAllocation.toFixed(0));
    budget = parseInt(campaignBudgetData.budget.toFixed(0));
  }

  return (
    <div>
      {selectedSite && <WebsiteCard site={selectedSite} />}
      <div className="bg-white rounded-lg pl-6 pt-3 pb-10 shadow-md">
        <h3 className="text-xl font-ManropeBold">
          Budget and Campaign Settings
        </h3>
        <div className="flex flex-col border-t border-gray-200 mx-3 mt-3 pt-2">
          <div className="py-1">
            <p className="font-ManropeRegular">
              <span className="font-ManropeSemiBold mr-1">Budget:</span>$
              {budget}
              <span className="ml-2 font-ManropeSemiBold "></span> (Allocation:
              Search ${searchAllocation}
              <span className="mx-2 font-ManropeSemiBold">|</span> Social $
              {socialAllocation}
              <span className="mx-2 font-ManropeSemiBold">|</span>
              Display ${displayAllocation})
            </p>
          </div>

          <div className="pt-2">
            <p className="font-ManropeSemiBold">
              Goals:{" "}
              <span className="font-ManropeRegular">
                {campaignBudgetData?.goal}
              </span>
            </p>
          </div>
          <div className="mt-2 grid grid-cols-[340px_340px] gap-x-4">
            {Object.entries(configs).map(([key, value]: [any, any]) => {
              return (
                <div key={key} className="flex flex-col items-start mb-3">
                  <div>
                    <p className="text-md  mb-1 font-ManropeSemiBold max-w-[250px]">
                      {key}
                    </p>
                  </div>

                  <div className="font-ManropeRegular">
                    <p>
                      {" "}
                      {typeof value === "object" && !Array.isArray(value)
                        ? parseDefaultVal(value.value)
                        : parseDefaultVal(value)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-2">
            <p className="font-ManropeSemiBold">
              Search Keywords:
              <span className="font-ManropeRegular">{keywordsWithComma}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg mt-5 pl-6 py-10 shadow-md">
        <p className="text-xl font-ManropeExtraBold">
          Your cost today: ${budget}
        </p>
      </div>
      {/* {budget && (
        <div className="mt-5">
          <PaymentCard price={budget} />
        </div>
      )} */}
    </div>
  );
};

export default Confirm;
