import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";

import { useStep } from "../../contexts/stepper";
import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useCampaign } from "../../contexts/campaign";

import Website from "./steps/website";
import Plan from "./steps/plan";
import Campaign from "./steps/campaign";
import Ads from "./steps/creative";
import Confirm from "./steps/confirm";

import NextButton from "../../components/button/nextButton";
import Button from "../../components/button";
import ActivityIndicator from "../../components/activitySpinner";
import Modal from "../../components/modal";
import RoundedButton from "../../components/roundedButton";

import { useWebsite } from "../../services/web-details";
import {
  useCampaignBudget,
  useCreateCampaignBudget,
} from "../../services/wizard/budget";
import {
  ICreateBannerBearImagesRequest,
  useBannerBearImages,
  useCreateBannerBearImages,
} from "../../services/wizard/bannerbear";
import {
  ICampaignFieldsResponse,
  useCampaignFields,
} from "../../services/industry-organization-campaign-fields";
import {
  useCampaignsLinkedToWebsite,
  useCreateCampaign,
} from "../../services/campaigns";

import { CampaignFieldData, IAdsFromSameAdType } from "../../interfaces";

import { parseMultipleSelectObject } from "../../utils/parseMultiSelectObject";

const Stepper: React.FC = () => {
  const { step, setStep } = useStep();
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { campaignId, setCampaignId } = useCampaign();

  const navigate = useNavigate();

  const [selectedListing, setSelectedListing] = useState<any>(undefined);

  const { data: selectedSite, isFetching: isSiteLoading } = useWebsite(
    selectedListing?.id
  );

  const [textArea] = useState<string | null>("Ad");
  const [, setAdvertiseFlowId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [modalData, setModalData] = useState<IAdsFromSameAdType[] | null>(null);
  const [campaignFieldVals, setCampaignFieldVals] = useState<CampaignFieldData>(
    {}
  );
  const [initialCampaignFieldVals, setInitialCampaignFieldVals] =
    useState<CampaignFieldData>({});
  const [
    openNewCampaignVerificationModal,
    setOpenNewCampaignVerificationModal,
  ] = useState<boolean>(false);
  const [multiSelectFieldIds, setMultiSelectFieldIds] = useState<
    {
      id: number;
      campaignFieldName: string;
      campaignFieldId: number;
    }[]
  >([]);

  const { data: campaignBudgetData } = useCampaignBudget(campaignId);
  const { mutateAsync: createBudgetData, isError: isCreateBudgetFailed } =
    useCreateCampaignBudget(selectedSite?.id, campaignId);
  const {
    mutateAsync: createBannerBearImages,
    isPending: isStillProducingAdImages,
    isError: isAdImagesFailed,
  } = useCreateBannerBearImages(campaignId);
  const {
    data: bannerbearImagesData,
    isPending: isAdImagesLoading,
    isFetching: isAdImageFetching,
  } = useBannerBearImages(campaignId);

  const { data: campaignFields } = useCampaignFields(
    selectedAdvertiser,
    campaignId
  );

  const {
    mutateAsync: createCampaign,
    isPending: isCreatingCampaignPending,
    isError: isCreatingCampaignError,
  } = useCreateCampaign();

  const { data: campaignsByWebsiteIdList } = useCampaignsLinkedToWebsite(
    selectedSite?.id
  );

  const hasNullDisplayAdImages = bannerbearImagesData?.displayAds.some(
    (adsObj) => adsObj.imageUrl === null
  );
  const hasNullSocialAdImages = bannerbearImagesData?.socialAds.some(
    (adsObj) => adsObj.imageUrl === null
  );
  const hasNullSearchAdImages = bannerbearImagesData?.searchAds.some(
    (adsObj) => adsObj.imageUrl === null
  );

  const hasNullBannerBearImages =
    hasNullDisplayAdImages || hasNullSocialAdImages || hasNullSearchAdImages;

  const refetchIfHasNullImages = () => {
    if (hasNullBannerBearImages) {
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
    }
  };

  const isAllConfigFieldsFilled = () => {
    return Object.keys(campaignFieldVals).every(
      (field: string) => campaignFieldVals[field]
    );
  };

  async function handleWebsiteNext() {
    if (
      selectedListing &&
      selectedSite?.isFieldsFilled &&
      selectedSite.numberOfAssets > 0
    ) {
      if (campaignsByWebsiteIdList?.length !== 0) {
        setOpenNewCampaignVerificationModal(true);
        return;
      }
      handleCreateNewCampaign();
    }
  }

  async function handleCreateNewCampaign() {
    if (
      selectedListing &&
      selectedSite?.isFieldsFilled &&
      selectedSite.numberOfAssets > 0
    ) {
      const campaignResponse = await createCampaign(selectedSite?.id);
      const { id } = campaignResponse;

      if (!id) {
        return;
      }

      setCampaignId(id);

      const reqBannerBearData: ICreateBannerBearImagesRequest = {
        websiteId: selectedSite.id,
        advertiserId: selectedSite.advertiserId,
        campaignId: id,
      };

      const reqBudgetData = { websiteId: selectedSite.id, campaignId: id };
      const res = await createBudgetData(reqBudgetData);
      if (!res.budget) {
        return;
      }
      await createBannerBearImages(reqBannerBearData);

      setStep(2);
    }
  }

  const handlePlanNext = async () => {
    refetchIfHasNullImages();

    if (selectedListing && campaignBudgetData) {
      setStep(3);
    }
  };

  const handleCampaignNext = async () => {
    refetchIfHasNullImages();

    if (isAllConfigFieldsFilled() === false || !textArea) {
      return;
    }

    if (bannerbearImagesData) {
      setStep(4);
    }
  };

  const handleAdsNext = () => {
    setStep(5);
  };

  const handleOpenModal = () => {
    // TODO: if ads are fetched then use them below. Otherwise put a loader.
    setIsModalOpen(true);
    setIsClosing(false);
  };

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 600);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    setStep(1);
    setSelectedListing(null);
    setAdvertiseFlowId("");
    //eslint-disable-next-line
  }, [selectedAdvertiser]);

  useEffect(() => {
    if (campaignFields) {
      const fetchedFieldValues = campaignFields.reduce((acc, field) => {
        const componentFields = (field: ICampaignFieldsResponse) => {
          return {
            id: field.id,
            isRequired: field.isRequired,
            campaignFieldId: field.campaignFieldId,
            value: field.defaultValue,
            label: field.defaultValue,
          };
        };

        if (field.type === "DATE") {
          acc[field.campaignFieldName] = field.defaultValue
            ? componentFields(field)
            : null;
        } else if (field.type === "DROPDOWN") {
          acc[field.campaignFieldName] = field.defaultValue
            ? componentFields(field)
            : null;
        } else if (field.type === "MULTISELECT") {
          setMultiSelectFieldIds((prev) => [
            ...prev,
            {
              id: field.id,
              campaignFieldId: field.campaignFieldId,
              campaignFieldName: field.campaignFieldName,
            },
          ]);
          const parsedValues = field.defaultValue
            ? parseMultipleSelectObject(field.defaultValue).map((val: any) => ({
                id: field.id,
                isRequired: field.isRequired,
                campaignFieldId: field.campaignFieldId,
                value: val,
                label: val,
              }))
            : [];
          acc[field.campaignFieldName] = parsedValues;
        } else {
          acc[field.campaignFieldName] = componentFields(field);
        }
        return acc;
      }, {} as CampaignFieldData);
      setCampaignFieldVals({ ...fetchedFieldValues });
      // setValue({ ...fetchedFieldValues });
      setInitialCampaignFieldVals({ ...fetchedFieldValues });
    }
  }, [campaignFields]);

  if (!selectedAdvertiser) {
    return (
      <div className="bg-white flex h-[9.2rem] items-start justify-start rounded-lg py-8 px-5 ">
        <div className="px-8 py-3 flex-grow-0  rounded-3xl  bg-[#F4F2EE] border border-[#F9F3F0]">
          <p className="text-base font-ManropeRegular ">
            It seems that you have not selected an advertiser to proceed
            further.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col mb-10">
          {isCreatingCampaignPending && <ActivityIndicator />}
          {step === 1 && (
            <>
              {openNewCampaignVerificationModal && (
                <Modal
                  setIsModalOpen={setOpenNewCampaignVerificationModal}
                  modalBodyContent={
                    <div className="flex flex-col max-w-lg py-1 px-2 my-2 mx-1">
                      <p className="font-ManropeSemiBold">
                        There is already a campaign with the same website. Are
                        you sure you want to create a new campaign ?
                      </p>
                      <div className="flex mt-8 justify-center">
                        <RoundedButton
                          text="yes"
                          className="py-2 px-6 ManropeRegular mr-2"
                          onClick={async () => {
                            handleCreateNewCampaign();
                            setOpenNewCampaignVerificationModal(false);
                          }}
                        />
                        <RoundedButton
                          text="no"
                          className="py-2 px-6 font-ManropeRegular bg-gray-300"
                          onClick={() =>
                            setOpenNewCampaignVerificationModal(false)
                          }
                        />
                      </div>
                    </div>
                  }
                />
              )}
              {(isAdImageFetching && isAdImagesLoading) ||
                (isStillProducingAdImages && <ActivityIndicator />)}
              <Website
                selectedListing={selectedListing}
                isSiteLoading={isSiteLoading}
                selectedSite={selectedSite}
                setSelectedListing={setSelectedListing}
                setAdvertiseFlowId={setAdvertiseFlowId}
              />
              <div className="flex mt-10 items-center">
                {
                  <div className="flex flex-col">
                    {isCreatingCampaignError && (
                      <div>
                        <p className="text-red-400 text-sm font-ManropeSemiBold">
                          Something went wrong with creating campaign!
                        </p>
                      </div>
                    )}
                    {isCreateBudgetFailed && (
                      <div className="mt-2">
                        <p className="text-red-400 text-sm font-ManropeSemiBold">
                          Something went wrong with creating budget!
                        </p>
                      </div>
                    )}
                    {isAdImagesFailed && (
                      <div className="mt-2">
                        <p className="text-red-400 text-sm font-ManropeSemiBold">
                          Something went wrong with creating ad images!
                        </p>
                      </div>
                    )}
                  </div>
                }
                <NextButton
                  isEnabled={
                    selectedListing &&
                    selectedSite?.isFieldsFilled &&
                    selectedSite.isEditable &&
                    selectedSite.numberOfAssets > 0
                  }
                  styling="ml-auto"
                  onClick={handleWebsiteNext}
                  isPending={isStillProducingAdImages}
                />
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <Plan
                campaignId={campaignId}
                selectedListing={selectedListing}
                selectedSite={selectedSite}
                campaignBudgetData={campaignBudgetData}
              />
              <div className="flex my-10">
                <Button
                  bgColor="bg-gray-400"
                  onClick={() => setStep(1)}
                  btnText="Back"
                />

                <NextButton
                  isEnabled={!!selectedListing || !!campaignBudgetData}
                  styling="ml-auto"
                  onClick={handlePlanNext}
                />
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <Campaign
                campaignFieldVals={campaignFieldVals}
                multiSelectFieldIds={multiSelectFieldIds}
                initialCampaignFieldVals={initialCampaignFieldVals}
                setInitialCampaignFieldVals={setInitialCampaignFieldVals}
                setCampaignFieldVals={setCampaignFieldVals}
                selectedSite={selectedSite}
              />
              <div className="flex my-12">
                <Button
                  bgColor="bg-gray-400"
                  onClick={() => setStep(2)}
                  btnText="Back"
                />
                <NextButton
                  isEnabled={!!isAllConfigFieldsFilled() && !!textArea}
                  styling="ml-auto"
                  onClick={handleCampaignNext}
                />
              </div>
            </>
          )}
          {step === 4 && (
            <>
              <Ads
                isModalOpen={isModalOpen}
                modalData={modalData}
                isClosing={isClosing}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                adsFromEach={bannerbearImagesData}
                setIsModalOpen={setIsModalOpen}
                setModalData={setModalData}
              />
              <div className="flex my-10">
                <Button
                  bgColor="bg-gray-400"
                  onClick={() => setStep(3)}
                  btnText="Back"
                />
                <NextButton
                  isEnabled={true}
                  styling="ml-auto"
                  onClick={handleAdsNext}
                />
              </div>
            </>
          )}

          {step === 5 && (
            <div className="flex flex-col ">
              <Confirm
                configs={campaignFieldVals}
                selectedSite={selectedSite}
              />
              <div className="flex my-10">
                <Button
                  bgColor="bg-gray-400"
                  onClick={() => setStep(4)}
                  btnText="Back"
                />
                <NextButton
                  btnText="Complete"
                  isEnabled={true}
                  styling="ml-auto"
                  onClick={async () => {
                    navigate("/campaigns");
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Stepper;

/* // if some of images or videos are null in array which mean not generated yet, trying to refetch
  useEffect(() => {
    if (bannerbearImagesData) {
      if (
        hasNullBannerBearImages ||
        bannerbearImagesData.displayAds.length === 0 // better off removing this line.
      ) {
        setIsRefetchTriggered(true);
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ["bannerbearImages", campaignId],
          });
          setRefetchTrigger((prev) => prev + 1);
        }, 3000);
      } else {
        setIsRefetchTriggered(false);
      }
    }
    //eslint-disable-next-line
  }, [bannerbearImagesData, campaignId, refetchTrigger]);
  */
