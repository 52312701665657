import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../../axios";
import { queryClient } from "../../../App";

export interface ICreateBudgetRequest {
  websiteId: number;
  campaignId: number;
}

export interface ICreateBudgetResponse {
  id: number;
  budgetRuleId: number;
  websiteId: number;
  goal: string;
  budget: number;
  displayAllocation: number;
  searchAllocation: number;
  socialAllocation: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUpdateBudgetRequest {
  id: number;
  budget: number;
  goal: string;
  searchAllocation: number;
  socialAllocation: number;
  displayAllocation: number;
}

/* Producing a budget for selected website by using website id */
export const createBudget = (data: ICreateBudgetRequest) => {
  return request({
    url: "budget",
    method: "post",
    data: data,
  });
};

export const useCreateCampaignBudget = (
  websiteId: number | undefined,
  campaignId: number | undefined
) => {
  return useMutation<ICreateBudgetResponse, Error, ICreateBudgetRequest>({
    mutationFn: createBudget,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["campaignBudget", campaignId],
      });
    },
  });
};

/* Getting campaing budget by using campaign id */
export const getBudget = (
  campaignId: number | undefined
): Promise<ICreateBudgetResponse> => {
  return request({
    url: `budget/campaign/${campaignId}`,
    method: "get",
  });
};

export const useCampaignBudget = (campaignId: number | undefined) => {
  return useQuery({
    queryKey: ["campaignBudget", campaignId],
    queryFn: () => getBudget(campaignId),
    enabled: !!campaignId,
  });
};

/* updating budget*/
export const updateBudget = (data: IUpdateBudgetRequest) => {
  return request({
    url: "budget",
    method: "patch",
    data: data,
  });
};

export const useUpdateCampaignBudget = (campaignId: number | undefined) => {
  return useMutation({
    mutationFn: updateBudget,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["campaignBudget", campaignId],
      });
      queryClient.invalidateQueries({
        queryKey: ["bannerbearImages", campaignId],
      });
    },
  });
};
