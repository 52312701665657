export function isOlderThanSpecifiedMinutes(updatedAt: Date | undefined) {
  if (!updatedAt) {
    return;
  }
  const currentTime = new Date();
  const updatedAtTime = new Date(updatedAt);

  const differenceInMilliseconds = Number(currentTime) - Number(updatedAtTime);

  const differenceInMinutes = differenceInMilliseconds / 1000 / 60;

  return differenceInMinutes > 2;
}
