import ContentContainer from "../../components/contentContainer";

import EditIcon from "../../assets/icons/external-link.png";

interface INotificationProps {
  className?: string;
  icon: string;
  boldText?: string;
  text?: string;
  buttonIcon?: string;
  buttonText?: string;
  onClick?: () => void;
}

const Notification: React.FC<INotificationProps> = ({
  className,
  icon,
  boldText,
  text,
  buttonIcon,
  buttonText,
  onClick,
}) => {
  const style = `pb-4 pt-6 ${className}`;
  return (
    <ContentContainer className={style}>
      <div className=" flex space-x-10 pl-4 items-center">
        <img src={icon} className=" h-10 w-10" alt="content-icon" />
        <div className=" flex flex-col">
          {boldText && <p className=" font-ManropeExtraBold">{boldText}</p>}
          <p>{text}</p>
        </div>
      </div>
      <hr className=" mt-5 mb-3" />
      <div className=" flex justify-end space-x-7  ">
        <div
          className="flex flex-row items-center space-x-1 cursor-pointer"
          onClick={onClick}
        >
          <img
            src={buttonIcon ?? EditIcon}
            className=" h-3 w-3"
            alt="sp-icon"
          />
          <span className=" text-sm">{buttonText}</span>
        </div>
        {/* <div className="flex flex-row items-center space-x-1">
          <img src={DismissBtnIcon} className=" h-3 w-3" alt="ds-icon" />
          <span className=" text-sm">DELETE</span>
        </div> */}
      </div>
    </ContentContainer>
  );
};

export default Notification;
