import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/auth";
import RoundedButton from "../../components/roundedButton";
import Input from "../../components/input/floatinLabelInput";

import KolayAdsIcon from "../../assets/icons/veloxi-navbar-logo.png";
import WarningIcon from "../../assets/icons/icon_alert-warning.png";

interface SignInFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [formData, setFormData] = useState<SignInFormData>({
    email: "",
    password: "",
  });

  const [focusedField, setFocusedField] = useState<string | null>(null);

  const {
    signIn,
    isError: isSignInError,
    errorMessage: signInErrorMessage,
  } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!formData.email || !formData.password) {
      return;
    }
    await signIn(formData.email, formData.password);
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="absolute border border-solid border-gray-200 rounded-lg  top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="w-[30.5rem] mx-auto pt-4 pb-12 px-12">
          <div className="w-full h-[4.5rem]  mb-6">
            <img
              src={KolayAdsIcon}
              className="w-full h-full object-contain "
              alt="kolay-ads.png"
            />
          </div>

          {isSignInError && (
            <div className="flex bg-[#fef7f7] border border-red-600 px-3 py-4 rounded-md">
              <div className="w-5 flex-shrink-0 h-5 mr-5">
                <img
                  className="w-full h-full"
                  src={WarningIcon}
                  alt="warning-text"
                />
              </div>

              <p className="font-ManropeSemiBold text-sm text-black">
                {signInErrorMessage}
              </p>
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-x-3 gap-y-3"
          >
            <Input
              id="email"
              name="email"
              label="Email"
              value={formData.email}
              onInputChange={handleChange}
              focusedField={focusedField}
              setFocusedField={setFocusedField}
            />
            <Input
              id="password"
              type="password"
              name="password"
              label="Password"
              value={formData.password}
              onInputChange={handleChange}
              focusedField={focusedField}
              setFocusedField={setFocusedField}
            />

            <div>
              <Link
                to="/reset-password"
                className={` text-[#5e88b6] underline cursor-pointer font-ManropeMedium text-sm text-right`}
              >
                Forgot your password?
              </Link>
            </div>
            <RoundedButton
              text="Next"
              type="submit"
              disabled={!formData.email || !formData.password}
              bgColor="bg-blue-800"
              className="py-3 px-4"
            />
            <div className="relative py-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-b border-gray-300"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-sm text-gray-500">or</span>
              </div>
            </div>

            <RoundedButton
              text="Sign Up"
              type="button"
              onClick={() => navigate("/signup")}
              bgColor="bg-white"
              className="py-3 px-4 border-2 border-[#292560] hover:border-blue-500 hover:border-2  hover:ring-2"
              textColor="text-[#0e086a]"
            />

            {/* {isSignInError && (
              <div className="mt-3">
                <p className="text-red-400  text-sm">{signInErrorMessage}</p>
              </div>
            )} */}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
