export type TCampaignListFilter = {
  key: number | string;
  text: "all" | "active" | "complete" | "cancelled";
};

export const campaignsListFilterData: TCampaignListFilter[] = [
  { key: "cF-1", text: "all" },
  { key: "cF-2", text: "active" },
  { key: "cF-3", text: "complete" },
  { key: "cF-4", text: "cancelled" },
];

export const categoryOrder: { [key: string]: number } = {
  GENERAL: -1,
  ADVANCE_TARGETING: 2,
  GEO_TARGETING: 0,
};

export const categoryTitle: { [key: string]: string } = {
  GENERAL: "General Campaign Info",
  ADVANCE_TARGETING: "Additional Ad Targeting",
  GEO_TARGETING: "Where Your Ads Will Appear",
};

export const categoryTooltipTexts: { [key: string]: string } = {
  GENERAL:
    "Typical ad campaigns usually takes about three months for ads to mature, and it could take another four to 12 months to see real success. This isn’t set in stone, but it gives the ad platforms time to gather the data needed to serve your ads to the right audience.",
  ADVANCE_TARGETING:
    "These settings allow you to target your ad based on their demographics. In addition Interest targeting allows you to match to audiences based on the user's browsing behavior. An in-market audience is likely to be in-market for a particular product or service.",
  GEO_TARGETING:
    "These settings allows you to deliver ads to people based on their geographic location. This strategy helps ensure your ads reach the right audience in the right place, making your campaigns more relevant and effective.",
};
