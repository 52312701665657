import { Link } from "react-router-dom";

interface IButton {
  bgColor?: string;
  textColor?: string;
  btnText: string;
  fontFamily?: string;
  link?: string;
  onClick?: () => void;
  paddingValues?: string;
  fontSize?: string;
  marginValues?: string;
  isScaledOnHover?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button = ({ link, type = "button", ...rest }: IButton) => {
  return link ? (
    <Link to={link}>
      <DefaultButton type={type} {...rest} />
    </Link>
  ) : (
    <DefaultButton type={type} {...rest} />
  );
};

const DefaultButton = ({
  btnText,
  bgColor,
  textColor,
  fontFamily,
  onClick,
  paddingValues,
  fontSize,
  marginValues,
  isScaledOnHover,
  type = "button",
}: IButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${bgColor ? bgColor : "bg-[#426B1F] "} ${
        textColor ? textColor : "text-white"
      }
${fontFamily ? fontFamily : " font-interSemiBold"}
${isScaledOnHover && "hover:scale-95 transition-transform ease-linear"}
${paddingValues ? paddingValues : "px-11 py-3"}
${fontSize ? fontSize : "text-base"}
${marginValues}
max-w-fit rounded-3xl cursor-pointer   `}
    >
      <p className="font-ManropeSemiBold"> {btnText}</p>
    </button>
  );
};

export default Button;
