import { ReactComponent as EditSection } from "../../assets/icons/edit-icon-section.svg";

const EditSectionButton = ({
  onClick,
  isHidden,
}: {
  onClick?: () => void;
  isHidden?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${
        isHidden && "hidden"
      } px-3 py-3  text-black hover:text-blue-600 hover:bg-[#eef1fe] bg-transparent transition-colors ease-in box-border  rounded-lg `}
    >
      <EditSection className="w-6 h-6" />
    </button>
  );
};

export default EditSectionButton;
