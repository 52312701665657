import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import { useStep } from "../../contexts/stepper";
import { useAuth } from "../../contexts/auth";
import { NotifierProvider } from "../../contexts/notifier";

import Navbar from "../../components/navBar";
import SidebarLogo from "../../components/sideBarLogo";
import SidebarStep from "../../components/sidebarStep";

import { sidebarLinks } from "../../constants/sidebarData";

interface IMainLayout {
  children: React.ReactNode;
  showSidebarLogo?: boolean;
  showSidebar?: boolean;
  showWizard?: boolean;
}

const Wizard = () => {
  const { step } = useStep();
  const stepTitles = ["Website", "Plan", "Campaign", "Creative", "Confirm"];

  return (
    <div className=" w-[11.75rem] ">
      {stepTitles.map((title, index) => (
        <SidebarStep key={title} index={index} title={title} currentStep={step} totalSteps={5} />
      ))}
    </div>
  );
};

const MainLayout: FC<IMainLayout> = ({ children, showSidebarLogo, showSidebar, showWizard }) => {
  const { user } = useAuth();

  const isEmailVerified = React.useMemo(() => user?.emailVerified, [user]);
  const contentStyle = `flex flex-1 mt-14 ${isEmailVerified ? "pt-0" : "pt-4"}`;

  return (
    <div className="flex flex-col min-h-screen bg-[#f4f2ee]">
      <Navbar isEmailVerified={isEmailVerified} />
      <div className={contentStyle}>
        <div className="flex-1 bg-[#f4f2ee]"></div>
        <div className="flex max-w-[80rem] w-full sm:flex-row flex-col">
          <div className="lg:w-[18.75rem] sm:mr-8 mr-4 bg-[#f4f2ee]  pl-4 xl:pl-0">
            <div className=" w-full sm:w-[300px] z-20 pb-8 sm:pb-0">
              {showSidebarLogo && <SidebarLogo />}
              <div
                className={`${
                  showSidebar || showWizard ? "bg-white" : " hidden overflow-hidden"
                } flex lg:w-[300px] items-center mt-8 justify-center py-8  rounded-lg`}
              >
                {showSidebar && (
                  <div className=" sm:w-[11.75rem] flex flex-row sm:flex-col sm:space-x-0 space-x-5">
                    {sidebarLinks.map((link) => (
                      <NavLink
                        key={link.id}
                        to={link.link}
                        target={link.link.includes("http") ? "_blank" : ""}
                        className={({ isActive }) => ` flex sm:my-8 ${isActive ? `font-ManropeExtraBold` : ""}`}
                      >
                        {({ isActive }) => (
                          <div className=" flex items-center space-x-2">
                            <img className="w-7 h-7" src={link.icon} alt={`${link.text}.png`} />
                            <p className={`text-lg ${isActive ? "font-ManropeBold" : "font-ManropeMedium"}  `}>
                              {link.text}
                            </p>
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </div>
                )}
                {showWizard && <Wizard />}
              </div>
            </div>
          </div>
          <div className="lg:w-[59.25rem] pt-8 sm:pl-5 bg-[#f4f2ee] pr-4 xl:pr-0">
            <NotifierProvider>{children}</NotifierProvider>
          </div>
        </div>
        <div className="flex-1 bg-[#f4f2ee]"></div>
      </div>
    </div>
  );
};

export default MainLayout;
