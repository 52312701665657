import { useNavigate } from "react-router-dom";

import Icon1 from "../../assets/icons/product-training-icon.png";
import Icon2 from "../../assets/icons/business-icon.png";

import Notification from "../../components/notification";

const Home = () => {
  const navigateTo = useNavigate();

  return (
    <>
      <Notification
        className=" mb-4"
        icon={Icon1}
        boldText="Watch a Product Training Video"
        buttonText="GO TO SUPPORT"
        onClick={() =>
          window.open("https://veloxiai.freshdesk.com/support/home", "_blank")
        }
      />
      <Notification
        className=" my-4"
        icon={Icon2}
        boldText="Set Up a Website"
        buttonText="GO TO WEBSITES"
        onClick={() => navigateTo("/websites")}
      />
      {/* <Notification
         className=" my-4"
         icon={Icon1}
         boldText="Set Up your Credit Card for billing"
         buttonText="GO TO USER PROFILE"
         onClick={() => navigateTo("/profile")}
       /> */}
      <Notification
        className=" my-4"
        icon={Icon2}
        boldText="Complete your Advertiser Profile"
        buttonText="GO TO ADVERTISERS"
        onClick={() => navigateTo("/advertiser")}
      />
    </>
  );
};

export default Home;
