import React, { useState, useContext, ReactNode } from "react";

interface ICampaign {
  campaignId: number | undefined;
  setCampaignId: (campaignId: number | undefined) => void;
}

interface CampaignProps {
  children: ReactNode;
}

const UseCampaignContext = React.createContext({} as ICampaign);

export const CampaignProvider: React.FC<CampaignProps> = ({ children }) => {
  const [campaignId, setCampaignId] = useState<number | undefined>(undefined);

  return (
    <UseCampaignContext.Provider value={{ campaignId, setCampaignId }}>
      {children}
    </UseCampaignContext.Provider>
  );
};

export const useCampaign = (): ICampaign => {
  const context = useContext(UseCampaignContext);
  if (context === undefined) {
    throw new Error("useCampaign must be used within a UseCampaignProvider");
  }
  return context;
};
