import { IAllCampaignsDataResponse } from "../../services/campaigns";

import { capitalizeFirstWord } from "../../utils/capitalizeFirstWord";

// import EditIcon from "../../assets/icons/edit-icon.png";
// import PauseIcon from "../../assets/icons/pause-icon.png";
import AssetIcon from "../../assets/icons/photo-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.png";

const WebsiteCard = ({
  campaign,
  onView,
  onDelete,
}: // onPause,
{
  campaign: IAllCampaignsDataResponse;
  onView?: (id: number) => any;
  onDelete?: (id: number) => any;
  // onPause?: (id: number) => any;
}) => {
  return (
    <div className="bg-white shadow-md rounded-lg px-6 py-8 mb-5">
      <div className="flex">
        <div className="mr-6 ">
          {campaign.image && (
            <img
              className="w-[11.4rem] h-[8.2rem] object-fit rounded-lg"
              src={campaign.image}
              alt="row.png"
            />
          )}
        </div>

        <div className="flex flex-col flex-1">
          <div className="flex">
            <p
              className={`
                text-black
               text-[1.375rem]  font-ManropeBold`}
            >
              {campaign.name}
            </p>
          </div>

          <div className="py-4 flex justify-between pl-4 xs:pr-4 lg:pr-16 my-2 border-y border-[#F9F3F0]">
            <p
              className={`
                text-black
               text-base font-ManropeRegular truncate max-w-[16rem]`}
            >
              •{" "}
              <span className="ml-2.5">
                Status: {capitalizeFirstWord(campaign.status)}
              </span>
            </p>

            <p className="text-base font-ManropeRegular">
              • <span className="ml-2.5">Goal: {campaign.goal}</span>
            </p>

            <p className="text-base text-black font-ManropeRegular">
              • <span className="ml-2.5">Budget: ${campaign.budget}</span>
            </p>
          </div>

          <div className="flex ml-auto space-x-12 mt-3  pr-2">
            {onView && (
              <div
                onClick={() => {
                  onView(campaign.id);
                }}
                className="flex cursor-pointer items-center"
              >
                <img
                  src={AssetIcon}
                  className="w-[0.95rem]  h-4"
                  alt="edit-icon.png"
                />
                <p
                  className={`text-black
                  uppercase text-sm font-ManropeMedium ml-2`}
                >
                  View
                </p>
              </div>
            )}
            {/* {onPause && (
              <div className="flex cursor-pointer items-center">
                <img
                  src={PauseIcon}
                  className={` w-[0.95rem]  h-4`}
                  alt="pause-icon.png"
                />
                <p className="uppercase text-sm font-ManropeMedium ml-2">
                  Pause
                </p>
              </div>
            )} */}
            {onDelete && (
              <div
                onClick={() => {
                  onDelete(campaign.id);
                }}
                className="flex cursor-pointer items-center"
              >
                <img
                  src={DeleteIcon}
                  className={` w-[0.95rem]  h-4`}
                  alt="delete-icon.png"
                />
                <p className={` uppercase text-sm font-ManropeMedium ml-2`}>
                  Delete
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteCard;
