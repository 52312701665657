export const evaluatePasswordStrength = (pw: string) => {
  const checkResult = {
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  };

  if (pw.length > 8) checkResult["length"] = true;
  if (/[a-z]/.test(pw)) checkResult["lowercase"] = true;
  if (/[A-Z]/.test(pw)) checkResult["uppercase"] = true;
  if (/\d/.test(pw)) checkResult["number"] = true;
  if (/[^A-Za-z0-9]/.test(pw)) checkResult["specialChar"] = true;

  return checkResult;
};
