import React from "react";
import { useLocation } from "react-router-dom";

interface NavigationListenerProps {
  clearAuthStates: () => void;
}

/**
 * `NavigationListener` listens for route changes using the `useLocation` hook and executes the `clearAuthStates` function to reset authentication-related state upon navigation. It's a non-visual component intended for side effects in response to navigation events, ensuring the application's state remains consistent as users move between routes. Place within `BrowserRouter` to access the router context.
 */

const NavigationListener: React.FC<NavigationListenerProps> = ({ clearAuthStates }) => {
  const location = useLocation();

  React.useEffect(() => {
    clearAuthStates();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [location]);

  return null;
};

export default NavigationListener;
