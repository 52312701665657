import { queryClient } from "../../../App";
import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../../axios";

export interface IGetKeywords {
  websiteId: number;
  keywords: Array<string>;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGetKeywordsResponse extends Array<IGetKeywords> {}

export interface IUpdateKeywordsRequest {
  keywords: Array<string>;
}

/* Getting sites keywords */
export const getKeywords = (
  campaignId: number | undefined
): Promise<IGetKeywordsResponse> => {
  return request({
    url: `google-ads-api/campaign/${campaignId}`,
    method: "get",
  });
};

export const useKeywords = (campaignId: number | undefined) => {
  return useQuery({
    queryKey: ["campaignKeywords", campaignId],
    queryFn: () => getKeywords(campaignId),
    enabled: !!campaignId,
  });
};

/* Updating sites keywords */
const updateKeywords = (
  campaignId: number | undefined,
  data: IUpdateKeywordsRequest
): Promise<IGetKeywordsResponse> => {
  return request({
    url: `google-ads-api/campaign/${campaignId}`,
    method: "patch",
    data: data,
  });
};

export const useUpdateKeywords = (campaignId: number | undefined) => {
  return useMutation({
    mutationFn: (data: IUpdateKeywordsRequest) =>
      updateKeywords(campaignId, data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["campaignKeywords", campaignId],
      });
    },
  });
};
