import EditIcon from "../../assets/icons/edit-icon.png";
import DeleteIcon from "../../assets/icons/delete-icon.png";

import { IAdvertisersData } from "../../interfaces";
import { useAdvertiserFields } from "../../services/advertiser/advertiserCustomFields";
import { useAdvertiser } from "../../services/advertiser";

const AdvertiserCard = ({
  advertiser,
  onEdit,
  onDelete,
}: {
  advertiser: IAdvertisersData;
  onEdit: (id: number) => any;
  onDelete: (id: number) => any;
}) => {
  const { data: advertiserData } = useAdvertiser(advertiser.id);
  const { data: industryFields } = useAdvertiserFields(advertiser.id);

  return (
    <div
      key={advertiser.id}
      className="bg-white shadow-md  rounded-lg px-6 py-8 mb-5"
    >
      <div className="flex">
        <div className="mr-6">
          <img
            className="w-[9.5rem] h-[8.5rem] object-contain"
            src={advertiser.photoUrl}
            alt={`${advertiser.displayName}.png`}
          />
        </div>

        <div className="flex flex-col flex-1">
          <p className="text-[#5972A5] text-[1.375rem] font-ManropeBold">
            {advertiser.displayName}
          </p>
          <div className="py-4 flex space-x-6 my-2 border-y border-[#F9F3F0]">
            <p className="text-base font-ManropeRegular truncate max-w-[16rem]">
              {industryFields &&
                advertiserData &&
                (advertiserData.isFieldsFilled
                  ? "• All items completed"
                  : `• 4 of ${industryFields.length + 4} items are complete`)}
            </p>
          </div>

          <div className="flex ml-auto space-x-20 mt-3 ">
            <div
              onClick={() => onEdit(advertiser.id)}
              className="flex cursor-pointer items-center"
            >
              <img
                src={EditIcon}
                className="w-[0.841rem]  h-4"
                alt="edit-icon.png"
              />
              <p className="uppercase text-sm font-ManropeMedium ml-2 ">Edit</p>
            </div>
            <div
              onClick={() => onDelete(advertiser.id)}
              className="flex cursor-pointer items-center"
            >
              <img
                src={DeleteIcon}
                className="w-[0.841rem]  h-4"
                alt="delete-icon.png"
              />
              <p className="uppercase text-sm font-ManropeMedium ml-2 ">
                Delete
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserCard;
