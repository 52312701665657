import { formatDateWithTime } from "./formatDateWithTime";

export function parseDefaultVal(defaultVal: any): string[] {
  const isIsoDate = (str: string) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return isoDatePattern.test(str);
  };

  if (Array.isArray(defaultVal)) {
    return defaultVal.map((item, index) => {
      const isLastItem = index === defaultVal.length - 1;
      return isLastItem ? `${item.value}` : `${item.value},`;
    });
  } else if (typeof defaultVal === "string") {
    if (defaultVal.startsWith("{") && defaultVal.endsWith("}")) {
      const cleanedInput = defaultVal.slice(1, -1).replace(/"/g, "");
      return cleanedInput.split('","').map((value) => value.trim());
    } else if (isIsoDate(defaultVal)) {
      return [formatDateWithTime(defaultVal)];
    } else {
      return [defaultVal];
    }
  } else if (defaultVal instanceof Date) {
    return [defaultVal.toISOString()];
  } else {
    return [String(defaultVal)];
  }
}
