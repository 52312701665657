import React, { forwardRef } from "react";

// Define a type for the props
interface IModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalBodyContent: React.ReactNode;
  onCloseModal?: () => void;
}

const Modal = forwardRef<HTMLDivElement, IModalProps>(
  ({ setIsModalOpen, modalBodyContent, onCloseModal }, ref) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div ref={ref} className="bg-white flex rounded-lg shadow-lg">
          <div className="relative flex flex-col w-full px-4">
            <button
              className="absolute top-0 right-0 w-[2.5rem] h-[2rem] text-[#466197] hover:text-gray-800"
              onClick={() => {
                onCloseModal && onCloseModal();
                setIsModalOpen(false);
              }}
            >
              <p className="text-2xl">X</p>
            </button>
            <div className="pl-5 flex pr-2 font-interSemiBold text-lg mt-8 py-5">
              {modalBodyContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Modal;
