import React, { useState, useContext, ReactNode, useEffect } from "react";
// import { IAdvertisersData } from "../../interfaces";

interface ISelectedAdvertiser {
  selectedAdvertiser: number | null;
  setSelectedAdvertiser: (selectedAdvertiser: number | null) => void;
}

interface SelectedAdvertiserProps {
  children: ReactNode;
}

const SelectedAdvertiserContext = React.createContext(
  {} as ISelectedAdvertiser
);

export const SelectedAdvertiserProvider: React.FC<SelectedAdvertiserProps> = ({
  children,
}) => {
  const [selectedAdvertiser, setSelectedAdvertiser] = useState<number | null>(
    null
  );

  useEffect(() => {
    const savedAdvertiser = localStorage.getItem("advertiser");
    if (savedAdvertiser) {
      setSelectedAdvertiser(JSON.parse(savedAdvertiser));
    }
  }, []);

  useEffect(() => {
    if (selectedAdvertiser) {
      localStorage.setItem("advertiser", JSON.stringify(selectedAdvertiser));
    } else {
      localStorage.removeItem("advertiser");
    }
  }, [selectedAdvertiser]);

  return (
    <SelectedAdvertiserContext.Provider
      value={{ selectedAdvertiser, setSelectedAdvertiser }}
    >
      {children}
    </SelectedAdvertiserContext.Provider>
  );
};

export const useSelectedAdvertiser = (): ISelectedAdvertiser => {
  const context = useContext(SelectedAdvertiserContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedAdvertiser must be used within a SelectedAdvertiserProvider"
    );
  }
  return context;
};
