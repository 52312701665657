import React, { useRef, useState } from "react";
import AddPhotoSvg from "../../assets/icons/add_a_photo_outlined.svg";
import { ImageUploadProps } from "../../interfaces";
import { useUploadPhoto } from "../../services/user";
import { allowedExtensions } from "../../constants/allowedImageExtensions";
import { formatFileName } from "../../utils/formatUploadedFileName";

const ImageUpload: React.FC<ImageUploadProps> = ({
  setPhotoUrl,
  setValue,
  photoUrl,
  register,
  inputName,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const { mutateAsync: uploadPhotoToGCP, isPending: isUploadingPending } =
    useUploadPhoto();

  const fileRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const selectedFile = formatFileName(file);

      const photoData = new FormData();
      photoData.append("file", selectedFile);

      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        try {
          const result = await uploadPhotoToGCP(photoData);
          const updatedLink = result.url;
          // setPhotoUrl(updatedLink);
          setValue && setValue("photoUrl", updatedLink);
        } catch (error) {
          console.error("Error in handleImageChange:", error);
        }
      } else {
        alert(
          "Please select a valid image file (jpeg, jpg,svg, png, avif, gif, bmp,webp)."
        );
      }
    }
  };

  return (
    <div
      className="relative w-32 h-32 mb-2 cursor-pointer"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isUploadingPending && (
        <p className="text-sm z-10 font-ManropeBold bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center px-2">
          Loading...
        </p>
      )}

      <img
        className="w-full h-full object-contain rounded-full"
        src={photoUrl}
        alt={`advertiser.png`}
      />
      {isHovering && (
        <div className="absolute z-50 rounded-full inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="relative w-10 h-10">
            <img
              src={AddPhotoSvg}
              className="w-10 h-10 object-cover rounded-full cursor-pointer"
              alt="upload-new.svg"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
      )}
      {register && inputName ? (
        <input
          type="file"
          id="image-upload"
          className=" w-full h-full hidden  mt-2 cursor-pointer"
          {...register(inputName)}
          onChange={handleImageChange}
          ref={fileRef}
        />
      ) : (
        <input
          ref={fileRef}
          type="file"
          id="image-upload"
          className=" w-full h-full hidden  mt-2 cursor-pointer"
          onChange={handleImageChange}
        />
      )}
    </div>
  );
};

export default ImageUpload;
