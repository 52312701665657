interface INextButton {
  isEnabled: boolean;
  linkHref?: string;
  onClick: any;
  styling?: string;
  isPending?: boolean;
  btnText?: string;
}

const NextButton = ({
  isEnabled,
  linkHref,
  onClick,
  styling,
  isPending,
  btnText,
}: INextButton) => {
  return (
    <div
      onClick={onClick}
      className={`${styling} ${
        isEnabled
          ? "cursor-pointer bg-[#5972A5]"
          : "cursor-not-allowed bg-[#EBEBE4]"
      }  justify-self-end self-end  max-w-fit rounded-3xl border border-[#F1EBDF]  text-white px-11 py-3`}
    >
      <>{isPending ? "Processing..." : btnText ? btnText : "Next"}</>
    </div>
  );
};

export default NextButton;
