import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "../../components/loader";
import Input from "../../components/input";
import ContentContainer from "../../components/contentContainer";
import ImageUpload from "../../components/profileEditImage";

import { SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "libphonenumber-js";

import { useAdvertiser, useUpdateAdvertiser } from "../../services/advertiser";
import { useUserIndustry } from "../../services/industry";
import {
  useAdvertiserFields,
  useCustomFieldsValues,
  useUpdateCustomFields,
} from "../../services/advertiser/advertiserCustomFields";

import {
  IAdvertiserUpdateCustomFieldsRequest,
  IUpdateAdvertiserDataRequest,
} from "../../interfaces";
import RoundedButton from "../../components/roundedButton";

const SingleAdvertiser = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const idToNumber = id ? parseInt(id, 10) : null;

  const {
    register,
    setValue,
    handleSubmit: handleSubmitBaseFields,
    formState: { errors: errorsBaseFields },
    watch,
  } = useForm<IUpdateAdvertiserDataRequest>();
  const editFormData = watch();

  const [, setHasEditFormError] = useState<boolean>(false);
  const [toastShown, setToastShown] = useState<boolean>(false);

  const {
    register: registerCustomFields,
    setValue: setValueCustomFields,
    handleSubmit: handleSubmitCustomFields,
    formState: { errors: errorsCustomFields },
    watch: watchCustomFieldsForm,
  } = useForm();
  const customFieldsFormData = watchCustomFieldsForm();

  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();
  const { data: advertiserData, isPending: isLoadingAdvertiser } =
    useAdvertiser(idToNumber);
  const { data: industry } = useUserIndustry(
    advertiserData?.industryId,
    advertiserData?.id
  );
  const { data: industryFields } = useAdvertiserFields(advertiserData?.id);
  const {
    mutateAsync: updateAdvertiser,
    isPending: isUpdatingAdvertiser,
    isError: isUpdatingAdvertiserError,
    isSuccess: isBaseAdvertiserInfoUpdated,
  } = useUpdateAdvertiser(advertiserData?.id);

  const {
    mutateAsync: updateCustomFieldValues,
    isPending: isUpdatingCustomFields,
    isSuccess: isCustomFieldsUpdated,
  } = useUpdateCustomFields(advertiserData?.id);
  const { data: customFieldsValuesData } = useCustomFieldsValues(
    advertiserData?.id
  );

  const submitHandlerBaseFields = useRef<() => void>();
  const submitHandlerCustomFields = useRef<() => void>();

  const onSubmitBaseFields: SubmitHandler<
    IUpdateAdvertiserDataRequest
  > = async (data) => {
    const args = {
      advertiserId: advertiserData?.id,
      data: { ...data },
    };
    if (args.data.phoneNumber && !isValidPhoneNumber(args.data.phoneNumber)) {
      return;
    }

    await updateAdvertiser(args);
  };

  const onSubmitCustomFields: SubmitHandler<any> = async (data) => {
    let reqData: IAdvertiserUpdateCustomFieldsRequest = {
      advertiserId: advertiserData?.id,
      advertiserInformation: [],
    };

    const dataFormatted: Array<{
      id?: number;
      customFieldId: number;
      value: string;
      source: "INDUSTRY";
    }> = Object.entries(data).map(([key, value]) => ({
      customFieldId: parseInt(key),
      value: String(value),
      source: "INDUSTRY",
    }));

    if (customFieldsValuesData.length === 0) {
      reqData.advertiserInformation = [...dataFormatted];
    } else {
      const customFieldsMap = new Map<number, number>(
        customFieldsValuesData.map((cf: any) => [cf.customFieldId, cf.id])
      );

      //eslint-disable-next-line
      dataFormatted.map((pd) => {
        const customFieldMatchId: number | undefined = customFieldsMap.get(
          pd.customFieldId
        );
        if (customFieldMatchId) {
          reqData.advertiserInformation.push({
            ...pd,
            id: customFieldMatchId,
          });
        }
      });
    }
    await updateCustomFieldValues(reqData);
  };

  submitHandlerBaseFields.current = () =>
    handleSubmitBaseFields(onSubmitBaseFields)();
  submitHandlerCustomFields.current = () =>
    handleSubmitCustomFields(onSubmitCustomFields)();

  const onSubmitBothForms = () => {
    const hasEmptyValues = Object.values(customFieldsFormData).some(
      (d) => d === ""
    );

    if (hasEmptyValues || customFieldsValuesData.length === 0) {
      submitHandlerCustomFields.current?.();
      return;
    }

    if (
      !editFormData.displayName ||
      !editFormData.email ||
      !editFormData.phoneNumber
    ) {
      setHasEditFormError(true);
      submitHandlerBaseFields.current?.();
      return;
    }

    submitHandlerBaseFields.current?.();
    submitHandlerCustomFields.current?.();
  };

  function isSubsetKey(key: any): key is keyof IUpdateAdvertiserDataRequest {
    return [
      "displayName",
      "phoneNumber",
      "email",
      "industryId",
      "photoUrl",
    ].includes(key);
  }

  useEffect(() => {
    if (advertiserData) {
      //eslint-disable-next-line
      Object.entries(advertiserData).map(([key, value]) => {
        if (isSubsetKey(key)) {
          setValue(key, value);
        }
      });
    }
    //eslint-disable-next-line
  }, [advertiserData]);

  useEffect(() => {
    if (advertiserData?.id === selectedAdvertiser && advertiserData) {
      setSelectedAdvertiser(advertiserData.id);
    }
    //eslint-disable-next-line
  }, [advertiserData]);

  useEffect(() => {
    //eslint-disable-next-line
    customFieldsValuesData?.map((customField: any) => {
      //eslint-disable-next-line
      industryFields?.map((field) => {
        if (customField.customFieldId === field.customFieldId) {
          setValueCustomFields(String(field.customFieldId), customField.value);
        }
      });
    });
    //eslint-disable-next-line
  }, [customFieldsValuesData, industryFields]);

  useEffect(() => {
    if ((isBaseAdvertiserInfoUpdated || isCustomFieldsUpdated) && !toastShown) {
      toast.success("Successfully saved!");
      setToastShown(true);
    }
    // eslint-disable-next-line
  }, [isCustomFieldsUpdated]);

  useEffect(() => {
    if (isBaseAdvertiserInfoUpdated || isCustomFieldsUpdated) {
      setToastShown(false);
    }
  }, [isBaseAdvertiserInfoUpdated, isCustomFieldsUpdated]);

  return (
    <div className="mb-5">
      {isLoadingAdvertiser && (
        <div className="relative ml-2 mt-2">
          <Loader />
        </div>
      )}
      {advertiserData && (
        <>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            hideProgressBar={false}
            theme="light"
            transition={Bounce}
          />
          <ContentContainer>
            <div className=" flex flex-1 flex-col p-8 ">
              <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
                <p className=" text-xl font-ManropeBold text-[#0e086a]">
                  Edit Advertiser Profile
                </p>
                <form autoComplete="off">
                  <ImageUpload
                    register={register}
                    inputName="photoUrl"
                    photoUrl={editFormData.photoUrl}
                    setValue={setValue}
                  />
                  <div className="grid flex-1 xs:gap-x-0 sm:gap-x-3 md:gap-x-6 xs:grid-cols-1 sm:grid-cols-1  gap-y-4">
                    <div className="flex flex-col">
                      <Input
                        label="Name"
                        register={register}
                        name="displayName"
                        requiredMessage=" required"
                        validation={errorsBaseFields.displayName?.message}
                        validationClassName="text-red-400 text-sm"
                        className="outline-blue-200  text-sm text-[#434343] font-ManropeRegular "
                        labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                        type="text"
                        reactHooksFormEnabled={true}
                        isRequiredField={true}
                      />
                    </div>

                    <Input
                      label="Email"
                      register={register}
                      pattern={{
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Entered value does not match email format",
                      }}
                      name="email"
                      requiredMessage="required"
                      validation={errorsBaseFields.email?.message}
                      validationClassName="text-red-400 text-sm"
                      className="outline-blue-200  text-sm text-[#434343] font-ManropeRegular "
                      labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                      type="text"
                      reactHooksFormEnabled={true}
                      isRequiredField={true}
                    />
                    <Input
                      label="Phone Number"
                      register={register}
                      name="phoneNumber"
                      requiredMessage=" required"
                      validation={errorsBaseFields.phoneNumber?.message}
                      validationClassName="text-red-400 text-sm"
                      className="outline-blue-200  text-sm text-[#434343] font-ManropeRegular "
                      labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                      type="text"
                      reactHooksFormEnabled={true}
                      isRequiredField={true}
                    />
                    {industry && (
                      <div className="flex">
                        <h3 className="font-ManropeSemiBold text-[#0e086a] ">
                          Industry Information:
                        </h3>
                        <div>
                          <p className="text-[#0e086a] font-ManropeMedium ml-1.5">
                            {industry.name}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {isUpdatingAdvertiserError && (
                    <p className="mt-5 pl-2 font-ManropeRegular text-sm text-red-500">
                      Failed to update fields.Please try again!
                    </p>
                  )}
                </form>
              </div>

              <div className="flex flex-col">
                {industryFields && industryFields.length !== 0 && (
                  <form autoComplete="off">
                    <p className=" text-xl font-ManropeBold text-[#0e086a] mb-5 ">
                      Edit Industry Fields
                    </p>
                    <div className="grid items-start xs:gap-x-0 sm:gap-x-3 md:gap-x-6 xs:grid-cols-1 sm:grid-cols-1 grid-flow-row  gap-y-4">
                      {industryFields.map((fields) => {
                        if (
                          fields.customFieldName
                            .toLowerCase()
                            .includes("describe") ||
                          fields.customFieldName
                            .toLowerCase()
                            .includes("overview")
                        ) {
                          return (
                            <div
                              key={fields.customFieldId}
                              className="flex flex-col order-1"
                            >
                              <label className="text-[#434343] mb-1 font-ManropeSemiBold pl-2">
                                {fields.customFieldName}
                              </label>
                              <div className="flex">
                                <textarea
                                  className={`outline-blue-200 xs:w-[18.75rem] sm:w-[28.125rem] text-sm text-[#434343] font-ManropeRegular  border-[1px] ${
                                    errorsCustomFields[
                                      String(fields.customFieldId)
                                    ]?.message && "border-red-400"
                                  } h-[8rem]   resize-none rounded py-1 px-3`}
                                  {...registerCustomFields(
                                    String(fields.customFieldId),
                                    { required: "required" }
                                  )}
                                />
                                {errorsCustomFields[
                                  String(fields.customFieldId)
                                ]?.message && (
                                  <p className="font-ManropeBold ml-3 text-red-400 text-sm">
                                    required
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }

                        return (
                          <Input
                            key={fields.customFieldId}
                            name={String(fields.customFieldId)}
                            requiredMessage={` required`}
                            validation={
                              errorsCustomFields[String(fields.customFieldId)]
                                ?.message
                            }
                            validationClassName="text-red-400 text-sm"
                            label={fields.customFieldName}
                            register={registerCustomFields}
                            labelClassName="font-ManropeSemiBold  text-[#434343]"
                            className="outline-blue-200 text-sm text-[#434343]  font-ManropeRegular"
                            type="text"
                            reactHooksFormEnabled={true}
                            isRequiredField={true}
                          />
                        );
                      })}
                    </div>
                  </form>
                )}
                <div className="flex mt-5">
                  <RoundedButton
                    text={"Cancel"}
                    onClick={() => navigate(-1)}
                    bgColor="bg-gray-400"
                    type="button"
                    className=" mr-auto cursor-pointer w-32 h-10"
                  />
                  <RoundedButton
                    text={
                      isUpdatingAdvertiser || isUpdatingCustomFields
                        ? "Saving..."
                        : "Save"
                    }
                    onClick={onSubmitBothForms}
                    className="flex  cursor-pointer rounded-3xl border w-32 h-10"
                  />
                </div>
              </div>
            </div>
          </ContentContainer>
        </>
      )}
    </div>
  );
};

export default SingleAdvertiser;
