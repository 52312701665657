import { UseFormRegister } from "react-hook-form";

interface InputProps {
  className?: string;
  type: string;
  name: string;
  label: string;
  value?: any;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  reactHooksFormEnabled?: boolean;
  register?: UseFormRegister<any>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  requiredMessage?: string;
  validation?: any;
  placeholder?: string;
  labelClassName?: string;
  validationClassName?: string;
  isRequiredField?: boolean;
  customWidth?: string;
}

const Input: React.FC<InputProps> = ({
  className,
  type,
  name,
  label,
  value,
  onChange,
  disabled,
  reactHooksFormEnabled,
  register,
  requiredMessage,
  pattern,
  validation,
  defaultValue,
  placeholder,
  labelClassName,
  validationClassName,
  customWidth,
}) => {
  return (
    <div className="flex flex-col">
      <label
        className={`${
          labelClassName ? labelClassName : "text-[#6B7276]"
        }  mb-1 pl-2`}
      >
        {label}
        {/* {isRequiredField && <span className="required-field ml-1"></span>} */}
      </label>
      <div className="flex items-center">
        {reactHooksFormEnabled && register ? (
          <input
            type={type}
            placeholder={placeholder}
            className={`${className} border-[1px] xs:w-[18.75rem] ${
              validation && "border-red-400"
            } sm:w-[28.125rem] rounded py-2 px-3`}
            {...register(name, { required: requiredMessage, pattern: pattern })}
            disabled={disabled}
          />
        ) : (
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            className={`${className} border-[1px] ${
              validation && "border-red-400"
            } ${
              customWidth ? customWidth : "xs:w-[18.75rem] sm:w-[28.125rem]"
            } rounded py-2 px-3`}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {validation && (
          <p
            className={`${
              validationClassName ? validationClassName : "mt-3 text-sm"
            }  font-ManropeBold ml-3`}
          >
            {validation}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
