import Wizard from "../../containers/wizard";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import MainLayout from "../../layouts/main";

const Index = () => {
  const { selectedAdvertiser } = useSelectedAdvertiser();

  return (
    <MainLayout
      showWizard={selectedAdvertiser ? true : undefined}
      showSidebar={!selectedAdvertiser}
      showSidebarLogo
    >
      <Wizard />
    </MainLayout>
  );
};

export default Index;
