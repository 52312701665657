import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import {
  IAdvertiserCustomFields,
  IAdvertiserUpdateCustomFieldsRequest,
} from "../../interfaces";
import { queryClient } from "../../App";

const getFields = (
  advertiserId: number | undefined
): Promise<IAdvertiserCustomFields[]> => {
  return request({
    url: `advertiser-custom-field-information/fields/${advertiserId}`,
    method: "get",
  });
};

export const useAdvertiserFields = (advertiserId: number | undefined) => {
  return useQuery({
    queryKey: ["advertiserIndustryFields", advertiserId],
    queryFn: () => getFields(advertiserId),
    enabled: !!advertiserId,
  });
};

/*  GET custom fields values */
const getCustomFieldsValues = (advertiserId: number | undefined) => {
  return request({
    url: `advertiser-custom-field-information/advertiser/${advertiserId}`,
    method: "get",
  });
};

export const useCustomFieldsValues = (advertiserId: number | undefined) => {
  return useQuery({
    queryKey: ["customFieldValues", advertiserId],
    queryFn: () => getCustomFieldsValues(advertiserId),
    enabled: !!advertiserId,
  });
};

/*  POST/UPDATE custom fields values */
const updateCustomFields = (data: IAdvertiserUpdateCustomFieldsRequest) => {
  return request({
    url: "/advertiser-custom-field-information",
    method: "post",
    data: data,
  });
};

export const useUpdateCustomFields = (advertiserId: number | undefined) => {
  return useMutation({
    mutationFn: updateCustomFields,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["advertiser", advertiserId],
      });
      queryClient.invalidateQueries({
        queryKey: ["customFieldValues", advertiserId],
      });
    },
  });
};
