import React from "react";

import Masonry from "react-masonry-css";

import { Modal } from "../../../modal";

import ImageModal from "../../../../components/modal/imageModal";
import Tooltip from "../../../../components/tooltip";

import { IAdsFromSameAdType } from "../../../../interfaces";
import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";

interface IAds {
  isModalOpen: boolean;
  modalData: IAdsFromSameAdType[] | null;
  isClosing: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  adsFromEach: IBannerBearImagesResponse | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<
    React.SetStateAction<IAdsFromSameAdType[] | null>
  >;
}

const Ads: React.FC<IAds> = ({
  isModalOpen,
  modalData,
  isClosing,
  handleOpenModal,
  handleCloseModal,
  adsFromEach,
  setIsModalOpen,
  setModalData,
}) => {
  return (
    <>
      {isModalOpen && (
        <Modal
          modalData={modalData}
          isClosing={isClosing}
          isOpen={isModalOpen}
          handleOpen={handleOpenModal}
          handleClose={handleCloseModal}
        />
      )}

      <section id="ads-display" className="bg-white rounded-lg">
        <div className="flex items-center bg-[#f7f9fa]">
          <div className="flex items-center  w-full pb-4 pt-2 pl-4 rounded-lg border-b border-gray-200">
            <h3 className=" text-[2rem] uppercase font-ManropeExtraBold mr-2">
              Display
            </h3>
            <Tooltip bodyText="These are a sample of display ads generated for you. " />
          </div>
        </div>
        <Masonry
          breakpointCols={{
            default: 3,
            1150: 3,
            1100: 2,
            700: 1,
          }}
          className="my-masonry-display-grid"
          columnClassName="my-masonry-display-grid_column"
        >
          {adsFromEach &&
            adsFromEach.displayAds.length !== 0 &&
            adsFromEach.displayAds.map((ad) => (
              <ImageModal
                key={ad.id}
                src={ad.imageUrl ?? ""}
                alt="processing..."
              />
            ))}
        </Masonry>
      </section>

      <section id="ads-social" className="bg-white rounded-lg mt-10">
        <div className="flex items-center bg-[#f7f9fa]">
          <div className="flex items-center  w-full pb-4 pt-2 pl-4 rounded-lg border-b border-gray-200">
            <h3 className=" text-[2rem] uppercase font-ManropeExtraBold mr-2">
              Social
            </h3>
            <Tooltip bodyText="These are a sample of social ads generated for you. " />
          </div>
        </div>
        <Masonry
          breakpointCols={{
            default: 3,
            1150: 3,
            700: 1,
          }}
          className="my-masonry-display-grid"
          columnClassName="my-masonry-display-grid_column"
        >
          {adsFromEach &&
            adsFromEach.socialAds.length !== 0 &&
            adsFromEach.socialAds.map((ad) => (
              // <div className="rounded-lg overflow-hidden shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] bg-white">
              <ImageModal
                key={ad.id}
                src={ad.imageUrl ?? ""}
                imgClassName=" overflow-hidden shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] bg-white"
                alt="processing..."
              />
              // </div>
            ))}
          {/* <div className="my-4 drop-shadow ml-3"> */}
          {/* <FacebookAd
              caption="This is a caption"
              ctaText="Learn More"
              description="This is a description"
              headline="short headline for ad"
              logoUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABoUlEQVR4Ad1VLWyDQBjFK7zCbKEtTTDLzAReXasn6tXMkrlittKZJoCn3uAVXmGWLWAuofXIzd14WXJZsnI/rJzolzwF7+fu3oGlMscp8Q/uoqBXxLdMz8Fdrjtz9gMSGDOG2dFdUBgPDcB2XsDSOWXxdKVMog6xm8lyx001A3DjxCs6MEArAF+1dgAYu05nmHPjIQFgpBcAxo7dGa1ZMm9hOH4AufH4AVgyISgYNzEV4E/BTAb4fLylENSHG5wlwNfTja55jpsBXdMBig581eYCxDMqNx4jQOy1LJ09cBFjAWCceCG+B+CaDZDOMnnBxgnACzbKNNeENC5ZnUJ7fwfjS5/Nx6oXYWkraji9Gi8VEZO3VWtFFTsJCKgMTPo0oC+cqKICcm6pTFRnAo1Stn37XjLw/OYL+a+1L+ADmbQDEgGKM+7hBngu5G/fJR0Iqc17IESd4axhitA4Hjmnoqo3IQTh7FAtMXZBupX6oLrfA4cfhT7UeyNt9H9DgA+dwYPkQ49jU5XqK1crJlVeNd4f8V+xx+p+GyIcv5r4ZyjON7JGfXfiNjZTAAAAAElFTkSuQmCC"
              message="This mension is on sale now!"
              pageName="display name"
              carousel={[
                "https://placehold.co/600x400",
                "https://cdn.britannica.com/61/93061-050-99147DCE/Statue-of-Liberty-Island-New-York-Bay.jpg",
                "https://dol.ny.gov/sites/g/files/oee1171/files/media/2021/03/hero-newyorkcity.jpg",
              ]}
            /> */}
          {/* </div> */}
        </Masonry>
      </section>

      <section id="ads-search" className="bg-white rounded-lg mt-10">
        <div className="flex items-center border-b border-gray-200 bg-[#f7f9fa]">
          <div className="flex items-center  w-full pb-4 pt-2 pl-4 rounded-lg border-b border-gray-200">
            <h3 className=" text-[2rem] tracking-wide   uppercase font-ManropeExtraBold">
              {/* Edit Description and Headlines */}
              search
            </h3>
            <Tooltip bodyText="This is a preview of a search ad generated from the information provided." />
          </div>
          {/* <div className="ml-auto mr-4">
            <EditSectionButton />
          </div> */}
        </div>

        <div className="flex my-4 mx-2">
          {adsFromEach &&
            adsFromEach.searchAds.length !== 0 &&
            adsFromEach.searchAds.map((ad) => (
              <ImageModal
                key={ad.id}
                imgClassName="rounded-lg overflow-hidden shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] bg-white"
                src={ad.imageUrl ?? ""}
                alt="processing..."
              />
            ))}
        </div>
      </section>

      {/* 
      <section id="ads-video" className=" bg-white rounded-lg mt-10">
        <div className="flex items-center bg-[#f7f9fa]">
          <div>
            <h3 className=" text-[2rem] pb-4 pt-2 pl-4 rounded-lg border-b border-gray-200 uppercase font-ManropeExtraBold">
              Videos
            </h3>
          </div>
          <div className="ml-auto mr-4">
            <EditSectionButton />
          </div>
        </div>
        {videoAds.map((video: IAdsFromSameAdType) => (
          <div
            key={video.id}
            className="columns-2 pl-3 pt-4 gap-8 pb-6"

          >
            <video
              className="w-full aspect-video"
              controls
            >
              <source src={video.createdAdUrl ?? ""} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </section> */}
    </>
  );
};

export default Ads;

// GOOGLE SEARCH AD EDITING PART
// {
/* <aside className="border mr-20 border-solid border-gray-300 rounded-md max-w-[32%] py-7 px-3">
            {(
              Object.keys(searchAdFields) as Array<keyof GoogleSearchAdProps>
            ).map((field) => {
              if (typeof field === "object") return null;
              if (field === "tags") return null;
              if (field === "customField") return null;
              return (
                <input
                  key={field}
                  type="text"
                  className="border mb-3 w-full border-solid border-gray-200 py-1 px-3 focus:outline-none"
                  name={field}
                  placeholder={googleSearchFieldTitles[field]}
                  onChange={handleSearchAdFields}
                  value={searchAdFields[field] ?? ""}
                />
              );
            })}

            <Button
              marginValues="ml-auto mt-5"
              onClick={() => {}}
              paddingValues="py-1 px-8"
              isScaledOnHover={true}
              btnText="Save"
            />
          </aside> */
// }
// {
/* <div className="border border-solid drop-shadow-md">
            <GoogleSearchAd {...searchAdFields} />
          </div> */
// }
