import React, { useEffect, useMemo } from "react";

import { useAuth } from "../../../contexts/auth";
import { useNotifier } from "../../../contexts/notifier";

import { useForm } from "react-hook-form";

import ContentContainer from "../../../components/contentContainer";
import Input from "../../../components/input/inputWithToggle";
import RoundedButton from "../../../components/roundedButton";

import AlertIcon from "../../../assets/icons/icon_alert-warning-yellow.png";
import SuccessIcon from "../../../assets/icons/check.png";

import { ProfileContent } from "../../../interfaces";

import { evaluatePasswordStrength } from "../../../utils/passwordStrength";

interface ChangePasswordProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
  };
  onClickGoBack: (content: ProfileContent) => void;
}

type TUserState = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePassword: React.FC<ChangePasswordProps> = ({
  data,
  onClickGoBack,
}) => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<TUserState>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const userData = watch();

  const { showNotifier, hideNotifier } = useNotifier();
  const {
    updateUserPassword,
    isSuccess: isUpdateUserPasswordSuccess,
    isLoading: isUpdateUserPasswordLoading,
    isError: isUpdateUserPasswordError,
    errorMessage: updateUserPasswordErrorMessage,
    clearAuthStates,
  } = useAuth();

  useEffect(() => {
    hideNotifier();
    if (isUpdateUserPasswordError) {
      const errorMessage =
        updateUserPasswordErrorMessage?.split(": ")[1].split("(")[0] ??
        "An error occurred while updating password.";
      showNotifier({
        message: errorMessage,
        icon: AlertIcon,
      });
    }
    if (isUpdateUserPasswordSuccess) {
      showNotifier({
        message: "Password updated successfully",
        icon: SuccessIcon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserPasswordSuccess, isUpdateUserPasswordError]);

  const passwordMatchMsg = useMemo(
    // calculates if newPassword and confirmNewPassword are matching, if not, it returns a message
    // if confirmNewPassword is empty, it returns undefined so that the validation message is not displayed
    () =>
      !userData.confirmNewPassword
        ? undefined
        : userData.newPassword === userData.confirmNewPassword
        ? undefined
        : "Passwords are not matching",
    [userData.newPassword, userData.confirmNewPassword]
  );

  const handleUpdateUserPassword = () => {
    if (Object.values(passwordStrength).includes(false)) {
      showNotifier({
        message: "New Password does not meet the requirements",
        icon: AlertIcon,
      });
    } else {
      updateUserPassword(
        data.email,
        userData.currentPassword,
        userData.newPassword
      );
    }
  };

  const handleClickGoBack = () => {
    onClickGoBack("displayProfile");
    // we need to clearAuthStates manually here as there is no route change happening between displayProfile and editProfile interfaces
    hideNotifier();
    clearAuthStates();
  };

  const passwordStrength = useMemo(
    () => evaluatePasswordStrength(userData.newPassword),
    [userData.newPassword]
  );

  function onSubmit() {
    handleUpdateUserPassword();
  }

  return (
    <ContentContainer>
      <div className=" flex flex-1 flex-col p-8 ">
        <div className=" flex flex-1 flex-col space-y-7 justify-between mb-12 items-center sm:items-stretch">
          <h1 className=" text-xl font-ManropeBold text-[#0e086a] mt-1 ">
            Change Password
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-1 flex-col w-[600px]">
              <Input
                name="currentPassword"
                type="password"
                label="Current Password"
                register={register}
                requiredMessage="Current Password is required"
                validation={errors.currentPassword?.message}
                validationClassName="text-red-400 -mt-2 mb-3 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`
                  ${errors.currentPassword?.message && "border-red-400"}
                  outline-blue-200  font-ManropeRegular mb-3 text-sm text-[#434343]
                  `}
              />
              <Input
                type="password"
                name="newPassword"
                label="New Password"
                register={register}
                requiredMessage="New Password is required"
                validation={errors.newPassword?.message}
                validationClassName="text-red-400 -mt-2 mb-3 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`${
                  errors.newPassword?.message && "border-red-400"
                } outline-blue-200  font-ManropeRegular mb-3 text-sm text-[#434343]`}
              />

              <div className="flex flex-row space-x-4 mb-6">
                <div className="flex flex-col">
                  <PasswordStrengthItem
                    isChecked={passwordStrength.lowercase}
                    message="One lowercase character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.uppercase}
                    message="One uppercase character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.number}
                    message="One number"
                  />
                </div>

                <div className="flex flex-col space-y-2">
                  <PasswordStrengthItem
                    isChecked={passwordStrength.specialChar}
                    message="One special character"
                  />
                  <PasswordStrengthItem
                    isChecked={passwordStrength.length}
                    message="Minimum 8 characters"
                  />
                </div>
              </div>

              <Input
                type="password"
                name="confirmNewPassword"
                label="Confirm New Password"
                register={register}
                requiredMessage="Confirm New Password is required"
                validation={
                  errors.confirmNewPassword?.message || passwordMatchMsg
                }
                validationClassName="text-red-400 -mt-2 mb-3 text-sm font-ManropeRegular ml-2"
                reactHooksFormEnabled={true}
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className={`
                  ${
                    (errors.confirmNewPassword?.message || passwordMatchMsg) &&
                    "border-red-400"
                  }
                  outline-blue-200  font-ManropeRegular mb-3 text-sm text-[#434343]
                  `}
              />
            </div>

            <div className=" flex justify-between pt-12">
              <RoundedButton
                text="BACK"
                className=" w-32 h-10 bg-[#9BA3B0]"
                onClick={handleClickGoBack}
              />
              <RoundedButton
                className=" w-32 h-10"
                type="submit"
                text={isUpdateUserPasswordLoading ? "Loading..." : "SAVE"}
              />
            </div>
          </form>
        </div>
      </div>
    </ContentContainer>
  );
};

export default ChangePassword;

export const PasswordStrengthItem: React.FC<{
  isChecked: boolean;
  message: string;
  width?: string;
  height?: string;
  fontSize?: string;
}> = ({ isChecked, message, width = "1rem", height = "1rem" }) => {
  return (
    <div className="flex items-center space-x-3">
      <div
        style={{
          width: `${width}`,
          height: `${height}`,
        }}
        className={`rounded-full ${
          isChecked ? "bg-[#52BAD6]" : "bg-[#E0E0E0]"
        }`}
      ></div>
      <p className="font-ManropeRegular text-sm leading-6">{message}</p>
    </div>
  );
};
