import { useRef, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import { useAuth } from "../../contexts/auth";
import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";

import useClickOutside from "../../hooks/useClickOutside";

import { useGetSelf } from "../../services/user";

import SettingsIcon from "../../assets/icons/gear-icon.svg";
import VeloxiLogo from "../../assets/icons/veloxi-logo-no-bg.png";

import { topNavLinks } from "../../constants/navbarData";

interface INavBarProps {
  isEmailVerified?: boolean;
  customNavLinkContainer?: React.ReactNode;
  isDefaultNavLinksEnabled?: boolean;
  isProfileNavEnabled?: boolean;
}

const Navbar: React.FC<INavBarProps> = ({
  isEmailVerified,
  customNavLinkContainer,
  isDefaultNavLinksEnabled = true,
  isProfileNavEnabled = true,
}) => {
  const navigate = useNavigate();

  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);

  const { isPending, isSuccess } = useGetSelf();

  const { signOut: signMeOut } = useAuth();
  const { setSelectedAdvertiser } = useSelectedAdvertiser();

  // const { data: advertisers } = useAllAdvertisers();

  const signOut = async () => {
    setSelectedAdvertiser(null);
    signMeOut();
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    setIsDropDownVisible(false);
  });

  const AppNameLogo = () => {
    const onClickLogo = () => navigate("/");
    return (
      <div className=" flex items-center cursor-pointer" onClick={onClickLogo}>
        <img
          src={VeloxiLogo}
          alt="veloxi-ai-logo.png"
          className="w-28 h-20 object-contain"
        />
      </div>
    );
  };

  const Links = () => (
    <div className="flex ">
      {topNavLinks.map((link, index) => (
        <NavLink
          key={link.id}
          to={link.link}
          target={link.link.includes("http") ? "_blank" : ""}
          className={({ isActive }) =>
            ` flex items-center ${
              index < 4 ? "mr-10 sm:mr-12 " : "mr-3"
            }  text-sm uppercase ${
              isActive
                ? "font-ManropeBold border-b-2 border-b-white"
                : "font-ManropeMedium "
            }`
          }
        >
          {({ isActive }) => (
            <div className=" flex items-center space-x-1">
              <img
                className={`w-5 h-5 ${link.text ? "mr-1" : "mr-0"}`}
                // src={isActive ? link.activeIcon : link.icon}
                src={link.icon}
                alt={`${link.text}.png`}
              />
              <p className="hidden text-white md:flex">{link.text}</p>
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );

  const ProfileNav = () => (
    <div className="flex items-center" ref={dropdownRef}>
      {isPending && (
        <div className=" animate-pulse">
          <span className="w-12 h-12 block bg-gray-200 rounded-full "></span>
        </div>
      )}
      {isSuccess && (
        <div
          onClick={() => setIsDropDownVisible(!isDropDownVisible)}
          className="relative flex cursor-pointer items-center text-sm font-ManropeMedium "
        >
          <img src={SettingsIcon} alt="settings.png" className=" w-5 h-5" />
          {isDropDownVisible && (
            <div className=" absolute top-[60px] xs:right-[30px] md:right-[0px] z-50 w-[190px] border border-solid border-gray-300  bg-white  py-3">
              <h3 className="font-ManropeBold px-2">Account</h3>
              <div className="pl-3 pr-2">
                <Link to={"/profile"}>
                  <p className="text-sm  font-ManropeRegular mt-2">
                    User Profile
                  </p>
                </Link>
                <Link to={"/advertiser"}>
                  <p className="text-sm font-ManropeRegular mt-2">
                    Advertisers
                  </p>
                </Link>
                <Link to={"/notifications"}>
                  <p className="text-sm font-ManropeRegular mt-2">
                    Notifications
                  </p>
                </Link>
                <Link
                  to={"https://veloxiai.freshdesk.com/support/home"}
                  target="_blank"
                >
                  <p className="text-sm font-ManropeRegular mt-2">Help</p>
                </Link>
                <hr className="mt-5" />

                <p
                  onClick={signOut}
                  className="text-sm font-ManropeRegular cursor-pointer mt-1"
                >
                  Sign Out
                </p>
              </div>
              {/* <hr className="mt-2" /> */}

              {/* <div className="flex flex-col mt-3">
                <h3 className="font-ManropeBold mb-2 px-2">Advertiser Profiles</h3>
                {advertisers?.map((advertiser) => (
                  <div className="flex group items-center cursor-pointer py-1 mb-1.5 last:mb-0 pl-3 pr-2 hover:bg-blue-100 ">
                    <div>
                      <img
                        className="w-6 h-6 rounded-full  object-contain"
                        src={advertiser.photoUrl}
                        alt={`${advertiser}.png`}
                      />
                    </div>
                    <div className="ml-2 ">
                      <p className="font-ManropeRegular truncate max-w-[4.4rem] text-xs ">{advertiser.displayName}</p>
                    </div>
                    <div
                      onClick={() => {
                        setSelectedAdvertiser(advertiser.id);
                      }}
                      className="ml-2 bg-blue-400 hidden transition-all ease-linear  group-hover:block py-0.5 px-1.5 rounded"
                    >
                      <p className="text-xs font-ManropeBold text-white ">switch</p>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const NavRightSide = () => (
    <div className="flex">
      {customNavLinkContainer ? (
        customNavLinkContainer
      ) : isDefaultNavLinksEnabled ? (
        <Links />
      ) : null}
      {isProfileNavEnabled ? <ProfileNav /> : null}
    </div>
  );

  const style = `flex justify-center w-full bg-[#0e086a] h-14 px-4 fixed z-50 ${
    isEmailVerified ? "mt-0" : "mt-8"
  }`;

  return (
    <div className={style}>
      <div className="flex justify-between xl:w-[1280px] lg:w-[1208px] md:w-[920px] sm:w-[850px] w-[730px] space-x-12">
        <AppNameLogo />
        <NavRightSide />
      </div>
    </div>
  );
};

export default Navbar;
