import { ICampaignFieldsResponse } from "../../../../services/industry-organization-campaign-fields";

export const categorizeFields = (
  fields: ICampaignFieldsResponse[] | undefined
): { [key: string]: ICampaignFieldsResponse[] } => {
  if (!fields) return {};

  return fields.reduce(
    (
      acc: { [key: string]: ICampaignFieldsResponse[] },
      field: ICampaignFieldsResponse
    ) => {
      if (!acc[field.categoryType]) {
        acc[field.categoryType] = [];
      }
      acc[field.categoryType].push(field);
      return acc;
    },
    {}
  );
};
