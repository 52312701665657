import React from "react";

interface RoundedButtonProps {
  className?: string;
  onClick?: () => void;
  text: string;
  disabled?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  bgColor?: string;
  textColor?: string;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({
  className,
  onClick,
  text,
  disabled = false,
  type,
  bgColor = "bg-[#0e086a]",
  textColor = "text-white",
}) => {
  const style = `flex  ${
    disabled ? "cursor-not-allowed bg-[#EBEBE4]" : `cursor-pointer ${bgColor} `
  } rounded-3xl border items-center justify-center ${className}`;
  const handleOnClick = disabled ? undefined : onClick;
  return (
    <button className={style} onClick={handleOnClick} type={type}>
      <p className={`${textColor} text-sm  font-ManropeExtraBold`}>{text}</p>
    </button>
  );
};

export default RoundedButton;
