import MainLayout from "../../../layouts/main";

import AddWebsite from "../../../containers/websites/addWebsite";

const Index = () => {
  return (
    <MainLayout showSidebarLogo={true}>
      <AddWebsite />
    </MainLayout>
  );
};

export default Index;
