import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAddSite } from "../../contexts/addWebsite";

import { SubmitHandler, useForm } from "react-hook-form";

import SearchBar from "../../components/urlBar";
import { Selection } from "../../components/select";
import Input from "../../components/input";
import RoundedButton from "../../components/roundedButton";
import ContentContainer from "../../components/contentContainer";

import { useWebsiteTypes } from "../../services/website-types";
import { IWebDetailsRequest } from "../../services/web-details";
import { useAdvertiser } from "../../services/advertiser";

interface TransformedItem {
  label: string;
  value: number;
}

export default function AddWebsite() {
  const navigate = useNavigate();

  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { addNewSite } = useAddSite();

  const { data: selectedAdvertiserData } = useAdvertiser(selectedAdvertiser);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    shortName: string;
    urlBarValue: string;
  }>();

  const formState = watch();

  const [websiteTypeId, setWebsiteTypeId] = useState<number | null>(null);
  const [websiteTypeErrMsg, setWebsiteTypeErrMsg] = useState<
    string | undefined
  >(undefined);

  const { data: websiteTypes, isPending: isWebsiteTypesLoading } =
    useWebsiteTypes(selectedAdvertiserData?.industryId, selectedAdvertiser);

  const isAllFieldsFilled =
    formState.urlBarValue !== "" &&
    websiteTypeId !== null &&
    formState.shortName !== "";

  const isSelectedAdvertiserFieldsFilled =
    selectedAdvertiserData?.isFieldsFilled;

  const websiteTypesData: TransformedItem[] = websiteTypes?.map(
    (item: any) => ({
      label: item.name,
      value: item.id,
    })
  );

  const handleSave = async (formData: any) => {
    if (selectedAdvertiser && isAllFieldsFilled) {
      const data: IWebDetailsRequest = {
        url: formData.urlBarValue,
        shortName: formData.shortName,
        websiteTypeId,
        advertiserId: selectedAdvertiser,
      };

      addNewSite(data);
      navigate("/websites");
    }
  };

  const onSubmit: SubmitHandler<any> = async (formData) => {
    await handleSave(formData);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    if (!websiteTypeId) {
      setWebsiteTypeErrMsg("required");
    }
    handleSubmit(onSubmit)(e);
  };

  const handleEditAdvertiser = () => {
    navigate(`/advertiser/${selectedAdvertiser}`);
  };

  if (selectedAdvertiserData && !isSelectedAdvertiserFieldsFilled) {
    return (
      <>
        <div className="bg-white flex h-[9.2rem] items-start justify-start rounded-lg py-8 px-7 ">
          <div className="px-8 py-3 flex-grow-0  rounded-3xl  bg-[#F4F2EE] border border-[#F9F3F0]">
            <p className="text-base font-ManropeRegular ">
              Some fields in the selected advertiser's info are missing. It
              might be a good idea to double-check and update that to start
              adding your websites.
            </p>
          </div>
        </div>

        <div className="max-w-fit mx-auto">
          <RoundedButton
            className="px-5 mt-10 py-2"
            onClick={handleEditAdvertiser}
            text="Edit Advertiser Information"
          />
        </div>
      </>
    );
  }

  return (
    <ContentContainer>
      {!selectedAdvertiser ? (
        <div className="bg-white flex h-[9.2rem] items-start justify-start rounded-lg py-8 px-5 ">
          <div className="px-8 py-3 flex-grow-0  rounded-3xl  bg-[#F4F2EE] border border-[#F9F3F0]">
            <p className="text-base font-ManropeRegular ">
              It seems that you have not selected an advertiser to proceed
              further.
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col ">
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <div className="flex items-center mb-6">
              <h1 className=" text-xl font-ManropeBold text-[#0e086a]">
                Add New Website
              </h1>
            </div>

            <div className="flex  flex-col gap-x-8 gap-y-2">
              <SearchBar
                label="Website URL"
                name="urlBarValue"
                register={register}
                pattern={{
                  message:
                    "Make sure url is in correct format and starts with https",
                  value: /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
                }}
                requiredMessage="required"
                reactHooksFormEnabled={true}
                urlErrMesg={errors.urlBarValue?.message}
              />
              <div className="flex  items-center">
                <div className="xs:w-[18.75rem] sm:w-[28.125rem] mb-4">
                  <label
                    className="text-[#434343] pl-2 font-ManropeSemiBold mb-1"
                    htmlFor="industryId"
                  >
                    Type of website
                  </label>
                  <Selection
                    isPending={isWebsiteTypesLoading}
                    data={websiteTypesData}
                    placeholder="Select type of website"
                    onChange={(selectedOption: any, actionMeta: any) => {
                      setWebsiteTypeId(selectedOption.value);
                      setWebsiteTypeErrMsg(undefined);
                    }}
                    closeMenuOnSelect={true}
                    isMulti={undefined}
                    enableDefaultValue={false}
                    customStyle={{
                      selectContainer: {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: `${
                          websiteTypeErrMsg ? "#f87171" : "#E5EBEE"
                        }`,
                        borderRadius: "0.25rem",
                        width: "100%",
                        fontSize: 14,
                        marginTop: "4px",
                      },
                      valueContainer: {
                        paddingInline: "0.75rem",
                        paddingBlock: "0",
                      },
                      color: "#434343",
                      optionisSelectedBgColor: "transparent",
                      optionisFocusedBgColor: "#335498",
                      optionisFocusedTextColor: "white",
                      singleValStyles: {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                </div>
                {websiteTypeErrMsg && (
                  <p className="ml-3 mt-5 text-sm font-ManropeBold text-red-400">
                    {websiteTypeErrMsg}
                  </p>
                )}
              </div>

              <Input
                label="Name the website"
                name="shortName"
                placeholder="Give a website name for future reference"
                register={register}
                reactHooksFormEnabled={true}
                requiredMessage="required"
                validation={errors.shortName?.message}
                validationClassName="text-red-400 text-sm"
                labelClassName="font-ManropeSemiBold text-base text-[#434343]"
                className="outline-blue-200  font-ManropeRegular mb-4 text-sm text-[#434343]"
                type="text"
              />
            </div>
            <div className="ml-auto mt-5 flex ">
              <RoundedButton
                onClick={() => navigate(-1)}
                bgColor="bg-gray-400"
                className="cursor-pointer w-32 h-10 mr-auto "
                text="Cancel"
                type="button"
              />
              <RoundedButton
                className="cursor-pointer w-32 h-10 "
                text="Save"
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </ContentContainer>
  );
}
