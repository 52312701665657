import { allowedExtensions } from "../constants/allowedImageExtensions";

export const handleFileType = async (
  formData: FormData,
  setFile?: (file: FormData) => void,
  key?: string
): Promise<boolean> => {
  const fileObject = key && formData.get(key);
  if (fileObject instanceof File) {
    const fileExtension = fileObject.name.split(".").pop()?.toLowerCase();
    if (fileExtension && allowedExtensions.includes(fileExtension)) {
      try {
        setFile && setFile(formData);
        return true;
      } catch (error) {
        console.error("Error in handleFileType:", error);
      }
    }
  }
  return false;
};
