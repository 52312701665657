export const flags = {
  AD: require("./ad.png"),
  AE: require("./ae.png"),
  AF: require("./af.png"),
  AG: require("./ag.png"),
  AI: require("./ai.png"),
  AL: require("./al.png"),
  AM: require("./am.png"),
  AO: require("./ao.png"),
  AR: require("./ar.png"),
  AS: require("./as.png"),
  AT: require("./at.png"),
  AU: require("./au.png"),
  AW: require("./aw.png"),
  AX: require("./ax.png"),
  AZ: require("./az.png"),
  BA: require("./ba.png"),
  BB: require("./bb.png"),
  BD: require("./bd.png"),
  BE: require("./be.png"),
  BF: require("./bf.png"),
  BG: require("./bg.png"),
  BH: require("./bh.png"),
  BI: require("./bi.png"),
  BJ: require("./bj.png"),
  BL: require("./bl.png"),
  BM: require("./bm.png"),
  BN: require("./bn.png"),
  BO: require("./bo.png"),
  BQ: require("./bq.png"),
  BR: require("./br.png"),
  BS: require("./bs.png"),
  BT: require("./bt.png"),
  BW: require("./bw.png"),
  BY: require("./by.png"),
  BZ: require("./bz.png"),
  CA: require("./ca.png"),
  CC: require("./cc.png"),
  CD: require("./cd.png"),
  CF: require("./cf.png"),
  CG: require("./cg.png"),
  CH: require("./ch.png"),
  CI: require("./ci.png"),
  CK: require("./ck.png"),
  CL: require("./cl.png"),
  CM: require("./cm.png"),
  CN: require("./cn.png"),
  CO: require("./co.png"),
  CR: require("./cr.png"),
  CU: require("./cu.png"),
  CV: require("./cv.png"),
  CW: require("./cw.png"),
  CX: require("./cx.png"),
  CY: require("./cy.png"),
  CZ: require("./cz.png"),
  DE: require("./de.png"),
  DJ: require("./dj.png"),
  DK: require("./dk.png"),
  DM: require("./dm.png"),
  DO: require("./do.png"),
  DZ: require("./dz.png"),
  EC: require("./ec.png"),
  EE: require("./ee.png"),
  EG: require("./eg.png"),
  EH: require("./eh.png"),
  ER: require("./er.png"),
  ES: require("./es.png"),
  ET: require("./et.png"),
  FI: require("./fi.png"),
  FJ: require("./fj.png"),
  FK: require("./fk.png"),
  FM: require("./fm.png"),
  FO: require("./fo.png"),
  FR: require("./fr.png"),
  GA: require("./ga.png"),
  GB: require("./gb.png"),
  GD: require("./gd.png"),
  GE: require("./ge.png"),
  GF: require("./gf.png"),
  GG: require("./gg.png"),
  GH: require("./gh.png"),
  GI: require("./gi.png"),
  GL: require("./gl.png"),
  GM: require("./gm.png"),
  GN: require("./gn.png"),
  GP: require("./gp.png"),
  GQ: require("./gq.png"),
  GR: require("./gr.png"),
  GT: require("./gt.png"),
  GU: require("./gu.png"),
  GW: require("./gw.png"),
  GY: require("./gy.png"),
  HK: require("./hk.png"),
  HN: require("./hn.png"),
  HR: require("./hr.png"),
  HT: require("./ht.png"),
  HU: require("./hu.png"),
  ID: require("./id.png"),
  IE: require("./ie.png"),
  IL: require("./il.png"),
  IM: require("./im.png"),
  IN: require("./in.png"),
  IO: require("./io.png"),
  IQ: require("./iq.png"),
  IR: require("./ir.png"),
  IS: require("./is.png"),
  IT: require("./it.png"),
  JE: require("./je.png"),
  JM: require("./jm.png"),
  JO: require("./jo.png"),
  JP: require("./jp.png"),
  KE: require("./ke.png"),
  KG: require("./kg.png"),
  KH: require("./kh.png"),
  KI: require("./ki.png"),
  XK: require("./xk.png"),
  KM: require("./km.png"),
  KN: require("./kn.png"),
  KP: require("./kp.png"),
  KR: require("./kr.png"),
  KS: require("./ks.png"),
  KW: require("./kw.png"),
  KY: require("./ky.png"),
  KZ: require("./kz.png"),
  LA: require("./la.png"),
  LB: require("./lb.png"),
  LC: require("./lc.png"),
  LI: require("./li.png"),
  LK: require("./lk.png"),
  LR: require("./lr.png"),
  LS: require("./ls.png"),
  LT: require("./lt.png"),
  LU: require("./lu.png"),
  LV: require("./lv.png"),
  LY: require("./ly.png"),
  MA: require("./ma.png"),
  MC: require("./mc.png"),
  MD: require("./md.png"),
  ME: require("./me.png"),
  MF: require("./mf.png"),
  MG: require("./mg.png"),
  MH: require("./mh.png"),
  MK: require("./mk.png"),
  ML: require("./ml.png"),
  MM: require("./mm.png"),
  MN: require("./mn.png"),
  MO: require("./mo.png"),
  MP: require("./mp.png"),
  MQ: require("./mq.png"),
  MR: require("./mr.png"),
  MS: require("./ms.png"),
  MT: require("./mt.png"),
  MU: require("./mu.png"),
  MV: require("./mv.png"),
  MW: require("./mw.png"),
  MX: require("./mx.png"),
  MY: require("./my.png"),
  MZ: require("./mz.png"),
  NA: require("./na.png"),
  NC: require("./nc.png"),
  NE: require("./ne.png"),
  NF: require("./nf.png"),
  NG: require("./ng.png"),
  NI: require("./ni.png"),
  NL: require("./nl.png"),
  NO: require("./no.png"),
  NP: require("./np.png"),
  NR: require("./nr.png"),
  NU: require("./nu.png"),
  NZ: require("./nz.png"),
  OM: require("./om.png"),
  PA: require("./pa.png"),
  PE: require("./pe.png"),
  PF: require("./pf.png"),
  PG: require("./pg.png"),
  PH: require("./ph.png"),
  PK: require("./pk.png"),
  PL: require("./pl.png"),
  PM: require("./pm.png"),
  PR: require("./pr.png"),
  PS: require("./ps.png"),
  PT: require("./pt.png"),
  PW: require("./pw.png"),
  PY: require("./py.png"),
  QA: require("./qa.png"),
  RE: require("./re.png"),
  RO: require("./ro.png"),
  RS: require("./rs.png"),
  RU: require("./ru.png"),
  RW: require("./rw.png"),
  SA: require("./sa.png"),
  SB: require("./sb.png"),
  SC: require("./sc.png"),
  SD: require("./sd.png"),
  SE: require("./se.png"),
  SG: require("./sg.png"),
  SH: require("./sh.png"),
  SI: require("./si.png"),
  SJ: require("./sj.png"),
  SK: require("./sk.png"),
  SL: require("./sl.png"),
  SM: require("./sm.png"),
  SN: require("./sn.png"),
  SO: require("./so.png"),
  SR: require("./sr.png"),
  SS: require("./ss.png"),
  ST: require("./st.png"),
  SV: require("./sv.png"),
  SX: require("./sx.png"),
  SY: require("./sy.png"),
  SZ: require("./sz.png"),
  TC: require("./tc.png"),
  TD: require("./td.png"),
  TG: require("./tg.png"),
  TH: require("./th.png"),
  TJ: require("./tj.png"),
  TK: require("./tk.png"),
  TL: require("./tl.png"),
  TM: require("./tm.png"),
  TN: require("./tn.png"),
  TO: require("./to.png"),
  TR: require("./tr.png"),
  TT: require("./tt.png"),
  TV: require("./tv.png"),
  TW: require("./tw.png"),
  TZ: require("./tz.png"),
  UA: require("./ua.png"),
  UG: require("./ug.png"),
  US: require("./us.png"),
  UY: require("./uy.png"),
  UZ: require("./uz.png"),
  VA: require("./va.png"),
  VC: require("./vc.png"),
  VE: require("./ve.png"),
  VG: require("./vg.png"),
  VI: require("./vi.png"),
  VN: require("./vn.png"),
  VU: require("./vu.png"),
  WF: require("./wf.png"),
  WS: require("./ws.png"),
  YE: require("./ye.png"),
  YT: require("./yt.png"),
  ZA: require("./za.png"),
  ZM: require("./zm.png"),
  ZW: require("./zw.png"),
} as const;
