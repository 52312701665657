import MainLayout from "../../layouts/main";

import Home from "../../containers/home";

const Index = () => {
  return (
    <MainLayout showSidebarLogo>
      <Home />
    </MainLayout>
  );
};

export default Index;
