// ImageModal.tsx

import React, { useState } from "react";

interface ImageModalProps {
  src: string;
  alt?: string;
  imgClassName?: string;
  divContainerClassName?: string;
}

const ImageModal: React.FC<ImageModalProps> = ({
  src,
  alt,
  imgClassName,
  divContainerClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${divContainerClassName}`}>
      <img
        src={src}
        alt={alt}
        className={`${imgClassName} cursor-pointer `}
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[999] flex justify-center items-center"
          onClick={() => setIsOpen(false)}
        >
          <div className="bg-white p-4" onClick={(e) => e.stopPropagation()}>
            <img src={src} alt={alt} className="max-w-screen-lg max-h-[85vh]" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
