import HelpIcon from "../assets/icons/question-icon-gray.svg";
import ListIcon from "../assets/icons/list.png";
import UserIcon from "../assets/icons/user.png";
import BellIcon from "../assets/icons/bell.png";

export const sidebarLinks = [
  {
    id: "sn-1",
    icon: UserIcon,
    text: "USER PROFILE",
    link: "/profile",
    subNav: null,
  },
  {
    id: "sn-2",
    icon: ListIcon,
    text: "ADVERTISERS",
    link: "/advertiser",
    subNav: null,
  },
  {
    id: "sn-3",
    icon: BellIcon,
    text: "NOTIFICATIONS",
    link: "/notifications",
    subNav: null,
  },
  {
    id: "sn-4",
    icon: HelpIcon,
    text: "HELP",
    link: "https://veloxiai.freshdesk.com/support/home",
    subNav: null,
  },
];
