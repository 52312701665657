import { queryClient } from "../../App";
import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { ICampaignFieldsResponse } from "../industry-organization-campaign-fields";
import { ICreateBudgetResponse } from "../wizard/budget";
import { IBannerBearImagesResponse } from "../wizard/bannerbear";

export interface ICreateCampaignResponse {
  websiteId: string;
  name: string;
  status: "active" | "complete" | "cancelled";
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAllCampaignsDataResponse {
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  goal: string;
  budget: number;
  image: string;
}

type TCampaignKeywords = {
  campaignId: number;
  keywords: string[];
  createdAt: Date;
  updatedAt: Date;
};

export interface ISingleCampaignDetailsResponse {
  id: number;
  name: string;
  websiteId: number;
  status: "all" | "active" | "complete" | "cancelled";
  createdAt: Date;
  updatedAt: Date;
  campaignFieldValues: ICampaignFieldsResponse[];
  campaignKeywords: TCampaignKeywords[];
  campaignBudget: ICreateBudgetResponse;
  campaignImages: IBannerBearImagesResponse;
  websiteUrl: string;
}

/* GET single campaign*/

async function getCampaignById(
  campaignId: number | null
): Promise<ISingleCampaignDetailsResponse> {
  return request({
    url: `campaigns/campaign/${campaignId}`,
    method: "get",
  });
}

export function useSingleCampaignDetails(
  campaignId: number | null,
  advertiserId: number | null
) {
  return useQuery({
    queryKey: ["campaign", advertiserId, campaignId],
    queryFn: () => getCampaignById(campaignId),
    enabled: !!campaignId || !!advertiserId,
  });
}
/* ADD a new campaign */
const createCampaign = (websiteId: number) => {
  return request({
    url: `campaigns/create/${websiteId}`,
    method: "post",
  });
};
export const useCreateCampaign = () =>
  useMutation<ICreateCampaignResponse, Error, any>({
    mutationFn: createCampaign,
  });

/* UPDATE campaign field values */
function updateCampaignFieldsValues({
  data,
}: {
  data: {
    id: number;
    campaignFieldId: number;
    value: any;
  }[];
}) {
  return request({
    method: "patch",
    url: "campaign-field-values",
    data,
  });
}

export function useUpdateCampaignFieldsValues({
  advertiserId,
  campaignId,
}: {
  advertiserId: number | null;
  campaignId: number | undefined;
}) {
  return useMutation<
    any,
    Error,
    {
      data: {
        id: number;
        campaignFieldId: number;
        value: any;
      }[];
    }
  >({
    mutationFn: updateCampaignFieldsValues,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["campaignFields", advertiserId, campaignId],
      });
    },
  });
}

/* GET all campaigns tied to an advertiser */
function getAllCampaigns(
  advertiserId: number | null
): Promise<IAllCampaignsDataResponse[]> {
  return request({
    method: "get",
    url: `campaigns/${advertiserId}`,
  });
}

export function useAllCampaigns(advertiserId: number | null) {
  return useQuery({
    queryKey: ["campaigns", advertiserId],
    queryFn: () => getAllCampaigns(advertiserId),
    enabled: !!advertiserId,
  });
}

/* Getting all campaigns associated with a website */
function getCampaignsByWebsiteId(
  websiteId: number | undefined
): Promise<IAllCampaignsDataResponse[]> {
  return request({
    method: "get",
    url: `campaigns/website/${websiteId}`,
  });
}

export function useCampaignsLinkedToWebsite(websiteId: number | undefined) {
  return useQuery({
    queryKey: ["campaigns", "website", websiteId],
    queryFn: () => getCampaignsByWebsiteId(websiteId),
    enabled: !!websiteId,
  });
}

/* Delete a campaign*/

function removeCampaign(campaignId: number | undefined) {
  return request({
    method: "delete",
    url: `campaigns/${campaignId}`,
  });
}

export function useRemoveCampaign(advertiserId: number | null) {
  return useMutation({
    mutationFn: removeCampaign,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["campaigns", advertiserId],
      });
      queryClient.invalidateQueries({
        queryKey: ["campaigns", "website", advertiserId],
      });
    },
  });
}
