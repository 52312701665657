import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAdvertiser, useAllAdvertisers } from "../../services/advertiser";

import { Selection } from "../../components/select";

const SidebarLogo = () => {
  const navigateTo = useNavigate();

  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();

  const { data: advertisersData, isPending: isAdvertisersPending } =
    useAllAdvertisers();
  const {
    data: selectedAdvertiserData,
    isPending: isSelectedAdvertiserDataPending,
    isSuccess: isSelectedAdvertiserDataSuccess,
  } = useAdvertiser(selectedAdvertiser);

  useEffect(() => {
    if (
      selectedAdvertiser &&
      !selectedAdvertiserData &&
      !isSelectedAdvertiserDataPending
    ) {
      localStorage.removeItem("advertiser");
      setSelectedAdvertiser(null);
    }

    // if advertisers are present but no advertiser is selected, select the first advertiser by default
    if (advertisersData?.length !== 0 && !selectedAdvertiser) {
      setSelectedAdvertiser(advertisersData?.[0].id ?? null);
    }

    //eslint-disable-next-line
  }, [selectedAdvertiserData, advertisersData, selectedAdvertiser]);

  const AdvertiserSelector = () => {
    const selectionData = advertisersData?.map((item: any) => ({
      id: item.id,
      value: item.displayName,
      label: item.displayName,
    }));
    return (
      <Selection
        placeholder={
          selectedAdvertiserData?.displayName
            ? selectedAdvertiserData.displayName
            : isSelectedAdvertiserDataPending
            ? ""
            : "Switch Advertiser"
        }
        data={selectionData}
        value={selectedAdvertiserData?.displayName}
        isPending={isAdvertisersPending}
        closeMenuOnSelect={true}
        isMulti={undefined}
        customStyle={{
          selectContainer: {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#E5EBEE",
            borderRadius: "0.25rem",
            width: "17.2rem",
            fontSize: 14,
          },
          valueContainer: {
            paddingInline: "0.75rem",
            paddingBlock: "0",
          },
          color: "#d3d3d3",
          optionisSelectedBgColor: "transparent",
          optionisFocusedBgColor: "#335498",
          optionisFocusedTextColor: "white",
          singleValStyles: {
            backgroundColor: "transparent",
          },
        }}
        onChange={(selectedOption) => setSelectedAdvertiser(selectedOption.id)}
      />
    );
  };

  const NoAdvertisersMsg = () => (
    <p className=" font-ManropeSemiBold text-center">
      No available advertiser. Please visit{" "}
      <span
        className=" cursor-pointer font-ManropeExtraBold text-[#5972A5]"
        onClick={() => navigateTo("/advertiser")}
      >
        Advertisers
      </span>{" "}
      page and create one.
    </p>
  );

  const LogoSkeleton = () => (
    <div className="flex justify-center items-center">
      <div className=" animate-pulse h-[5.5rem]  w-[5.5rem] bg-gray-200  rounded-full  "></div>
    </div>
  );

  const AdvertiserLogo = () => (
    <div className=" w-full flex items-center justify-center">
      {isSelectedAdvertiserDataSuccess && selectedAdvertiser ? (
        <img
          src={selectedAdvertiserData?.photoUrl}
          className=" object-contain h-[7rem] w-[9rem]"
          alt={`${selectedAdvertiserData.displayName}.png`}
        />
      ) : (
        <LogoSkeleton />
      )}
    </div>
  );

  return (
    <div className="bg-white flex sm:w-[300px] w-full items-center mt-8 justify-center py-3.5 rounded-lg flex-col space-y-4">
      {advertisersData?.length === 0 ? (
        <NoAdvertisersMsg />
      ) : (
        <>
          <AdvertiserLogo />
          <AdvertiserSelector />
        </>
      )}
    </div>
  );
};

export default SidebarLogo;
