import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "../../../layouts/main";

import FileUpload from "../../../components/imageUpload";
import RoundedButton from "../../../components/roundedButton";
import ContentContainer from "../../../components/contentContainer";

import { handleFileType } from "../../../utils/handleFileType";

import { useUploadPhoto } from "../../../services/user";
import { useAddAsset } from "../../../services/website-assets";
import { formatFileName } from "../../../utils/formatUploadedFileName";

const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const idToNumber = id ? parseInt(id) : null;

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<FormData[]>([]);
  const [filesForPreview, setFilesForPreview] = useState<File[]>([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]);

  const {
    mutateAsync: uploadPhotoToGCP,
    isError: isPhotoUploadError,
    isPending: isPhotoUploadPending,
  } = useUploadPhoto();

  const {
    isPending: isAddingNewAssetPending,
    mutateAsync: addNewAsset,
    isError: isAddingNewAssetError,
    // isSuccess: isAddingNewAssetSuccess,
  } = useAddAsset(idToNumber);

  const processFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const fileTypeIsValid = await handleFileType(formData, undefined, "file");
    if (fileTypeIsValid) {
      setFiles((prev) => [...prev, formData]);
      setFilesForPreview((prev) => [...prev, file]);
      return;
    }
    alert(
      "Please select a valid image file (jpeg, jpg, svg, png, avif, gif, bmp, webp)."
    );
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFilesForPreview = Array.from(e.dataTransfer.files).map(
        formatFileName
      );
      for (const file of newFilesForPreview) {
        await processFile(file);
      }
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFilesForPreview = Array.from(e.target.files).map(formatFileName);
      for (const file of newFilesForPreview) {
        await processFile(file);
      }
    }
  };

  const handleRemoveFiles = (fileName: string | undefined) => {
    if (fileName) {
      setFiles((prev) => {
        const newData = prev.filter((formData) => {
          const file = formData.get("file") as File;
          return file.name !== fileName;
        });
        return newData;
      });

      setFilesForPreview((prev) => {
        const newData = prev.filter((file) => file.name !== fileName);
        return newData;
      });

      setImagePreviewUrls((prev) => {
        const newData = prev.filter((_, i) => {
          const correspondingFile = filesForPreview[i];
          return correspondingFile.name !== fileName;
        });
        return newData;
      });
    } else {
      setFiles([]);
      setFilesForPreview([]);
      setImagePreviewUrls([]);
    }
  };

  const handleSavePhoto = async () => {
    if (files.length > 0 && filesForPreview.length > 0 && id) {
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileForPreview = filesForPreview[i];
          const result = await uploadPhotoToGCP(file);
          if (result) {
            const requestData = {
              websiteId: parseInt(id),
              name: fileForPreview.name,
              imageUrl: result.url,
              isExcluded: false,
            };
            await addNewAsset(requestData);
          }
        }
        navigate(-1);
        handleRemoveFiles(undefined);
      } catch (error) {
        console.error("Uploding new asset failed:", error);
      }
    }
  };

  useEffect(() => {
    if (filesForPreview.length > 0) {
      const previewUrls = filesForPreview.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviewUrls(previewUrls);
    }
  }, [filesForPreview]);

  return (
    <MainLayout showSidebar>
      <ContentContainer>
        <div className="p-8 ">
          <h3 className="text-xl font-ManropeBold text-[#0e086a] mb-5">
            Add New Asset
          </h3>
          <FileUpload
            handleDrop={handleDrop}
            mainText="Click to upload or drag and drop images"
            subText="SVG, PNG, JPG, or GIF..."
            handleChange={handleFileChange}
            renderedOnFileSelection={
              <div className="flex flex-col h-full justify-center">
                {filesForPreview.length > 0 && (
                  <div className="flex flex-wrap">
                    {filesForPreview.map((fileForPreview, index) => (
                      <div
                        key={index}
                        className="flex relative px-6 items-center border-gray-300 py-2 rounded-md border-solid border m-2"
                      >
                        <div className="w-[100px] h-[80px] flex items-center relative">
                          {imagePreviewUrls[index] && (
                            <img
                              src={imagePreviewUrls[index]}
                              alt="Uploaded"
                              className="w-full h-full rounded-sm"
                            />
                          )}
                        </div>
                        <p className="ml-6 text-sm">{fileForPreview?.name}</p>
                        <div
                          onClick={() =>
                            handleRemoveFiles(fileForPreview?.name)
                          }
                          className="absolute -right-1 -top-3 cursor-pointer"
                        >
                          <p className="text-red-500 text-xl">X</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {isPhotoUploadError && (
                  <p className="mt-4 text-red-400 font-interRegular">
                    Failed to upload the photo!
                  </p>
                )}
                {isAddingNewAssetError && (
                  <p className="font-ManropeBold text-sm mt-4 text-red-400">
                    Failed to add new asset!
                  </p>
                )}
              </div>
            }
            file={files}
            dragging={dragging}
            setDragging={setDragging}
          />

          <div className="flex">
            <RoundedButton
              onClick={() => {
                navigate(-1);
                handleRemoveFiles(undefined);
              }}
              text={"Back"}
              bgColor="bg-gray-400"
              className="cursor-pointer mt-5 mr-auto  w-32  h-10"
            />

            {filesForPreview.length > 0 && (
              <RoundedButton
                onClick={handleSavePhoto}
                text={
                  isAddingNewAssetPending || isPhotoUploadPending
                    ? "Loading..."
                    : "Save"
                }
                className="cursor-pointer  mt-5 w-32 h-10"
              />
            )}
          </div>
        </div>
      </ContentContainer>
    </MainLayout>
  );
};

export default Index;
