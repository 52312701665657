import { useRef, useState } from "react";

import useClickOutside from "../../hooks/useClickOutside";

import InfoButton from "../../assets/icons/question-mark-button.svg";

const Index: React.FC<{ bodyText: string }> = ({
  bodyText,
}: {
  bodyText: string;
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const spanRef = useRef<HTMLDivElement>(null);

  useClickOutside(spanRef, () => {
    setShowTooltip(false);
  });

  return (
    <span ref={spanRef} className="relative ml-2 mt-0.5 ">
      <img
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        src={InfoButton}
        className="w-5 h-5 cursor-pointer"
        alt="info.svg"
      />
      {showTooltip && (
        <div
          id="tooltip-info"
          className="absolute mt-1.5 z-10 w-[350px] bg-black text-gray-100 bg-opacity-90  -left-1 top-8 p-2 rounded shadow-md text-sm"
        >
          <div className="absolute -top-[9px] font-ManropeSemiBold left-2 w-0 h-0 border-l-[9px] border-l-transparent border-r-[9px] border-r-transparent border-b-[9px] border-b-black"></div>
          {bodyText}
        </div>
      )}
    </span>
  );
};

export default Index;
